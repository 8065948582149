import { createSlice } from "@reduxjs/toolkit";
import {
  add_Expense_Boolean,
  add_Expense_Validation,
  add_Fixed_Expense_Boolean,
  add_Fixed_Expense_Validation,
  add_Sub_Income_Boolean,
  validateCompanydoc,
  validateExpense,
} from "./helper";
import {
  CompanyDoc,
  ExpenseType,
  IncomeType,
  initialStateType,
} from "./company.type";
import { get, merge, set } from "lodash";

const initExpenseType: ExpenseType = {
  type: "add",
  name: "",
  fleet: false,
  branch: false,
  general: false,
  variance: "V",
  sub: "",
  isVendorOtpService: false,
  is_route: "",
  duration: false,
  monthly_duration: false,
  company: "",
  category: undefined,
  voucher_required: false,
  bill_required: false,
  verification_required: false,
  transfer_required: false,
  vendor_required: false,
  noncash_only: false,
  branchWise: false,
  repeatable: false,
  // department: "",
  businessType: "",
  overHead: "",
  asset_wise: false,
  asset_type: false,
  addLogs: false,
  id : "",
  overhead : null
};

const initIncomeType: IncomeType = {
  name: "",
  fleet: false,
  branch: false,
  general: false,
  sub: "",
  is_route: false,
  duration: false,
  company: "",
  type: "add",
  monthly_duration: false,
  voucher_required: false,
  bill_required: false,
  verification_required: false,
  transfer_required: false,
  vendor_required: false,
  noncash_only: false,
  businessType: "",
  overHead: "",
};

export const initCompanyDoc: CompanyDoc = {
  isNew: true,
  active: false,
  twoFactorAuthentication: false,
  isDocVerified: false,
  individual: false,
  bodyType: "",
  fleetType: "",
  assetSubType: "",
  regNumber: "",
  _id: "",
  companyName: "",
  kind: "",
  userDocVerified : false,
  displayName: "",
  shortCode: "",
  url: "",
  email: "",
  contactPerson: {
    contact: "",
    name: {
      fName: "",
      lName: "",
    },
    password: "",
    email: "",
  },
  address: {
    l1: "",
    l2: "",
    city: "",
    pincode: "",
  },
  bankAccount: "",
  branchName: "",
  shortName: "",
  ifscCode: "",
  // licenseImage: "",
  // licenseExpiry: date,
  // panNumber: "",
  // panCardImage: "",
  // companyLogo: "",
  errors: {},
  modelNumber: "",
  capacity: "",
  volume: {
    l: "",
    b: "",
    h: "",
  },
  distanceCovered: "",
  fuelType: "",
  ownerShip: "",
  idealFuel: "",
  insuranceDate: new Date(),
  fitnessDate: new Date(),
  permitDate: new Date(),
  emiDate: new Date(),
  allowedOFDCities: [],
  overhead: "",
  businessType: "",
  fleetId: "",
  noOfSeats: "",
  //changes for attachements
  // bannerImage: "",
  userProfileImage: "",
  userProfile: {
    verified: false,
  },
  licence: {},
  licenceImage: "",
  aadharCardImage: "",
  aadharCard: {},
  banner: {},
  bannerImage: "",
  panCard: {},
  panCardImage: "",
  companyLogo: {},
  companyLogoImage: "",
  contactVerified: false,
  agreement: {},
  agreementImage: "",
  userAttendance: false,
  branchTiming: false,
  cameraStatus: false,
  phoneStatus: false,
  customerVerification: false,
  allDocketVerification: false,
  withoutEWayBillDocketVerification: false,
  photoVerification: false,
  otpVerification: false,
  receiverAllDocketVerification: false,
  receiverPhotoVerification: false,
  receiverOtpVerification: false,
  pendingDelivery: false,
  verifiedPackages: false,
  myDuty: false,
  creditCustomer: false,
  pendingVoucher: false,
  userPendingMemo: false,
  userPendingReport: false,
  branchPendingMemo: false,
  branchPendingReport: false,
  mandatoryExpVerification: false,
  // interCompanyCashReceiver: false,
  // intraCompanyCashReceiver: false,
  // interCompanyCashUser: [],
  // intraCompanyCashUser: [],

  cancelBooking: false,
  loading: false,
  unloading: false,
  backToHub: false,
  userVerification: false,
  fleetDocVerification: false,
  qrPrinter: false,
  //changes for Dqr
  deliveryWithQr: false,
  rcBook: {},
  allIndiaPermit: {},
  insurance: {},
  rcBookImage: "",
  allIndiaPermitImage: "",
  insuranceImage: "",
  businessSubType: "",
  //changes
  settledBranch: "",
  companyType: "",
  settleCompany: "",
  primaryCompany: "",
  messages: {
    branchTimingAlert: false,
  },
  themePreference: {
    clrPrimaryHue: 204,
    clrSecondaryHue: 172,
  },
  autoEwayBillPartBVerification: false,
  eWaybillValidityCheck: false,
  cashDiscount: false,
  cashDemurrageDiscount: false
};

const initialState: initialStateType = {
  expense_type: initExpenseType,
  assetTypes: [],
  expense_type_erorrs: { ...add_Expense_Boolean(true) },
  expense_type_touched: { ...add_Expense_Boolean(true) },
  fetched_fixed_expensesById: [],
  fixed_expenses: {
    assetType: {
      _id: "",
    },
    fetched_expense_types: [],
    fetched_entity_types: [],
    post_fixed_expense: {
      type: "add",
      entity: "",
      assetType: "",
      entity_id: "",
      ref: undefined,
      duration: 1,
      amt: "",
      company: "",
      salary_days: null,
      extra_salary_days: null,
      id : "",
      name : "",
    },
    fixed_expense_type_erorrs: { ...add_Fixed_Expense_Boolean(true) },
    fixed_expense_type_touched: { ...add_Fixed_Expense_Boolean(true) },
  },
  income_type: initIncomeType,
  income_type_errors: { ...add_Expense_Boolean(true) },
  income_type_touched: { ...add_Expense_Boolean(false) },
  fetched_sub_income_types: [],
  sub_income_type: {
    type: "add",
    name: "",
    ref: "",
  },
  sub_income_errors: { ...add_Sub_Income_Boolean(true) },
  sub_income_touched: { ...add_Sub_Income_Boolean(false) },
  companyList: [],
  company_doc: initCompanyDoc,
  _id : "",
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    submitIncomeTypeSuccess: (state) => {
      let payload: IncomeType = {
        name: "",
        fleet: false,
        branch: false,
        general: false,
        sub: "",
        is_route: false,
        duration: false,
        company: "",
        type: "add",
        monthly_duration: false,
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        businessType: "",
        overHead: "",
      };
      state.income_type = payload;
    },
    changeAddIncomeTypes: (state, action) => {
      let { what, val } = action.payload;
      set(state, ["income_type", ...what], val);
      set(
        state,
        ["income_type_errors", ...what],
        validateExpense(get(state, what), val)
      );
      set(state, ["income_type_touched", ...what], true);
      if (what[0] === "fleet" || what[0] === "branch") {
        set(state, ["income_type", "general"], false);
      }
      if (what[0] === "general") {
        set(state, ["income_type", "fleet"], false);
        set(state, ["income_type", "branch"], false);
      }
    },
    getCompanyReqSuccess: (state, action) => {
      merge(state, action.payload);
    },
    changeAddExpenseType: (state, action) => {
      let { what, val } = action.payload;
      set(state, ["expense_type", ...what], val);
      set(
        state,
        ["expense_type_erorrs", ...what],
        validateExpense(get(add_Expense_Validation, what), val)
      );
      set(state, ["expense_type_touched", ...what], true);
    },
    setAssetTypes: (state, action) => {
      let asset = action.payload;
      state.assetTypes = asset;
    },
    submitExpenseSuccess: (state) => {
      let data: any = {
        type: "add",
        name: "",
        fleet: false,
        branch: false,
        general: false,
        variance: "V",
        sub: "",
        // is_route: "",
        duration: false,
        company: "",
        verification_required: false,
        bill_required: false,
        voucher_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        // department: "",
        businessType: "",
        overHead: "",
        asset_wise: false,
        asset_type: false,
        addLogs: false,
      };
      state.expense_type = data;
    },
    editExpenseSuccess: (state) => {
      let data: any = {
        type: "add",
        name: "",
        fleet: false,
        branch: false,
        general: false,
        variance: "V",
        sub: "",
        is_route: "",
        duration: false,
        company: "",
        verification_required: false,
        bill_required: false,
        vendor_required: false,
        noncash_only: false,
        voucher_required: false,
        transfer_required: false,
        // department: "",
        businessType: "",
        overHead: "",
        asset_wise: false,
        asset_type: false,
        addLogs: false,
      };
      state.expense_type = data;
    },
    submitFixedExpenseSuccess: (state) => {
      let payload: any = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: null,
        extra_salary_days: null,
      };
      state.fixed_expenses.post_fixed_expense = payload;
    },
    getExpenseTypes: (state, action) => {
      state.fixed_expenses.fetched_expense_types = action.payload;
    },
    getFixedExpenseById: (state, action) => {
      state.fetched_fixed_expensesById = action.payload.fixedExpenses;
    },
    deleteFixedExpenseTypeData: (state) => {
      let payload: any = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: null,
        extra_salary_days: null,
      };
      state.fixed_expenses.post_fixed_expense = payload;
    },
    changeFixedExpenseData: (state, action) => {
      let { what, val } = action.payload;
      set(state, ["fixed_expenses", "post_fixed_expense", ...what], val);
      set(
        state,
        ["fixed_expenses", "fixed_expense_type_erorrs", ...what],
        validateExpense(get(add_Fixed_Expense_Validation, what), val)
      );
      set(
        state,
        ["fixed_expenses", "fixed_expense_type_touched", ...what],
        true
      );
    },
    changeEntityId: (state, action) => {
      state.fixed_expenses.fetched_entity_types = action.payload;
    },
    getVehicleIdData: (state, action) => {
      state.fixed_expenses.assetType = action.payload;
    },
    setIncomeTypeForEdit: (state, action) => {
      // let payload = action.payload;
      // payload["type"] = "edit";
      // state.income_type.type = "edit";
      state.income_type = {
        ...state.income_type,
        ...action.payload,
        type : "edit"
      }
    },
    changeAddIncomeType: (state, action) => {
      let { what, val } = action.payload;
      set(state, ["income_type", ...what], val);
      set(
        state,
        ["income_type_errors", ...what],
        validateExpense(get(add_Expense_Validation, what), val)
      );
      set(state, ["income_type_touched", ...what], true);
      if (what[0] === "fleet" || what[0] === "branch") {
        set(state, ["income_type", "general"], false);
      }
      if (what[0] === "general") {
        set(state, ["income_type", "fleet"], false);
        set(state, ["income_type", "branch"], false);
      }
    },
    resetIncomeType: (state) => {
      let income_type_errors = { ...add_Expense_Boolean(true) };
      state.income_type = initIncomeType;
      state.income_type_errors = income_type_errors;
    },
    editIncomeTypeSuccess: (state) => {
      let payload: any = {
        name: "",
        fleet: false,
        branch: false,
        general: false,
        sub: "",
        is_route: false,
        duration: false,
        company: "",
        type: "add",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        businessType: "",
        overhead: "",
      };
      state.income_type = payload;
    },
    getPostedSubTypes: (state, action) => {
      state.fetched_sub_income_types = action.payload;
    },
    incomeTypeDeleted: (state) => {
      let payload: any = {
        name: "",
        fleet: false,
        branch: false,
        general: false,
        sub: "",
        is_route: false,
        duration: false,
        company: "",
        type: "add",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        transfer_required: false,
        vendor_required: false,
        noncash_only: false,
        businessType: "",
        overhead: "",
      };
      state.income_type = payload;
    },
    removeIncomeType: (state, action) => {
      let id = action.payload;
      set(
        state,
        ["fetchedInputTypes"],
        get(state, ["fetchedInputTypes"]).filter(
          (i: { id: any }) => i.id !== id
        )
      );
    },
    resetSubIncomeType: (state) => {
      let payload: any = {
        type: "add",
        name: "",
        ref: "",
      };
      let sub_income_errors = { ...add_Sub_Income_Boolean(true) };
      state.sub_income_type = payload;
      state.sub_income_errors = sub_income_errors;
    },
    setExpenseTypeData: (state, action) => {
      let data = action.payload;
      data["type"] = "edit";
      state.expense_type = data;
    },
    resetExpenseType: (state) => {
      let data: any = { ...initExpenseType };

      let expense_type_erorrs: any = { ...add_Expense_Boolean(true) };
      state.expense_type = data;
      state.expense_type_erorrs = expense_type_erorrs;
    },
    deleteExpenseTypeData: (state) => {
      let data: any = {
        type: "add",
        name: "",
        fleet: false,
        branch: false,
        general: false,
        variance: "V",
        sub: "",
        is_route: "",
        duration: false,
        company: "",
        voucher_required: false,
        bill_required: false,
        verification_required: false,
        vendor_required: false,
        noncash_only: false,
        transfer_required: false,
        // department: "",
        businessType: "",
        overHead: "",
        asset_wise: false,
        asset_type: false,
        addLogs: false,
      };
      state.expense_type = data;
    },
    resetFixedExpense: (state) => {
      let payload: any = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: null,
        extra_salary_days: null,
      };
      let fixed_expense_type_erorrs = { ...add_Fixed_Expense_Boolean(true) };
      state.fixed_expenses.post_fixed_expense = payload;
      state.fixed_expenses.fixed_expense_type_erorrs =
        fixed_expense_type_erorrs;
    },
    getCompanyListData: (state, action) => {
      state.companyList = action.payload;
    },
    setCompanyError: (state, action) => {
      set(
        state,
        ["company_doc", "errors", ...action.payload],
        validateCompanydoc(action.payload, "")
      );
    },
    changeCompanyDocData: (state, action) => {
      set(state, ["company_doc", ...action.payload.what], action.payload.value);
      set(
        state,
        ["company_doc", "errors", ...action.payload.what],
        validateCompanydoc(action.payload.what, action.payload.value)
      );
    },
    setCompanyDoc: (state, action) => {
      state.company_doc = {
        ...action.payload,
        errors: {},
      };
    },
    clearCompanyDoc: (state, action) => {
      state.company_doc = { ...initCompanyDoc, individual: action.payload };
    },
    setFixedExpenseTypeData: (state, action) => {
      let data = action.payload;
      // delete data.ref
      data["type"] = "edit";
      // delete data.name
      const resetPayload = {
        type: "add",
        entity: "",
        entity_id: "",
        ref: "",
        duration: 1,
        amt: "",
        company: "",
        salary_days: 0,
        extra_salary_days: 0
      };
      state.fixed_expenses.post_fixed_expense = {
        ...resetPayload,
        ...data
      };
    }
  },
});

export const {
  submitIncomeTypeSuccess,
  changeAddIncomeTypes,
  getCompanyReqSuccess,
  changeAddExpenseType,
  setAssetTypes,
  submitExpenseSuccess,
  editExpenseSuccess,
  submitFixedExpenseSuccess,
  getExpenseTypes,
  getFixedExpenseById,
  deleteFixedExpenseTypeData,
  changeFixedExpenseData,
  changeEntityId,
  getVehicleIdData,
  setIncomeTypeForEdit,
  changeAddIncomeType,
  resetIncomeType,
  editIncomeTypeSuccess,
  getPostedSubTypes,
  incomeTypeDeleted,
  removeIncomeType,
  resetSubIncomeType,
  setExpenseTypeData,
  resetExpenseType,
  deleteExpenseTypeData,
  resetFixedExpense,
  getCompanyListData,
  setCompanyError,
  changeCompanyDocData,
  setCompanyDoc,
  clearCompanyDoc,
  setFixedExpenseTypeData
} = companySlice.actions;

export default companySlice.reducer;
