import { ServiceMaker } from ".";

/**
 * @typedef {Object} suggestReceiverPayloadType
 * @property {string} senderContact - contact number of sender
 * @property {string} destination - pincode or city Id or branch Id of the destination
 * /
/**
 * @param { suggestReceiverPayloadType } suggestReceiverPayload - suggest receiver payload
 */
 export const suggestReceiver = (suggestReceiverPayload:{
    senderContact: any,
    destination?: string;
    originBranch?: string;
    destBranch?: string;
  }) => ServiceMaker('suggest/receiver','POST',suggestReceiverPayload)
 

/**
 * @typedef { Object} suggestPackingPayloadType
 * @property { string } senderContact - contact number of sender
 * @property { string} receiverContact - contact number of receiver
 * @property {string} destination - pincode or city Id or branch Id of the destination
 */
/**
 * 
 * @param {suggestPackingPayloadType} suggestPackingPayload 
 */
export const suggestPacking = (suggestPackingPayload:{
    senderContact: any,
    receiverContact: any,
    // destination?: string,
    originBranch?:any,
    destBranch?:any,
  // }) => ServiceMaker('suggest/packages','POST',suggestPackingPayload)
  }) => ServiceMaker('suggest/packages/originAndDestWise','POST',suggestPackingPayload)