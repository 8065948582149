import { Action } from "redux";

const globalInitialState = {
  loading: false,
};

export const globalReducer = (state = globalInitialState, action: Action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return {
        ...state,
        loading: true,
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
