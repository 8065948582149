import * as Yup from "yup";

export const Customer_boolean = (val: boolean) => ({
    name: val,
    contact: val,
    gst: val,
    address: {
      l1: val,
      l2: val,
      city: val,
      pincode: val,
    },
    codeGenerateOTP: val,
    customerVerifyOTP: val
  });
  
  export const validationSchema = {
    name: Yup.string()
      .required("Name is required")
      .min(3, "Too Short")
      .max(30, "Too long"),
    contact: Yup.string()
      .required("Contact is required")
      .min(10, "Contact must be 10 digits")
      .max(10, "Contact must be 10 digits"),
    // gst: Yup.string().required(""),
    address: {
      l1: Yup.string()
        .required("Address line 1 is required")
        .min(3, "Too short")
        .max(65, "Too long"),
      l2: Yup.string()
        .required("Address line 2 is required")
        .min(3, "Too short")
        .max(65, "Too long"),
      city: Yup.string().required("City is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .min(6, "Invalid pincode")
        .max(6, "Invalid pincode"),
    },
    codeGenerateOTP: Yup.string()
      .required("otp is required")
      .min(10, "OTP must be 6 Digits")
      .max(10, "OTP must be 6 Digits"),
    customerVerifyOTP: Yup.string()
    .required("otp is required")
    .min(10, "OTP must be 6 Digits")
    .max(10, "OTP must be 6 Digits"),
    referenceUser: Yup.string()
      .nullable()
      .min(24)
      .max(24),
    // connected:Yup.string().min(2).max()
  };
  
  export const validate = (schema: any, obj: any) => {
    try {
      schema.validateSync(obj);
      return false;
    } catch (err: any) {
      return err.message;
    }
  };