import React, { Fragment } from "react";
import "./index.css";
import { printReactComponent } from "../../../../../utils/printReactComponent";
import { QRCodeSVG } from "qrcode.react";
import QrGenerator from "../../../../../Components/QR/QrGenerator";
import BuiltyPrint from "../../BuiltyPrint";
import { MapMarkerAltIcon, MapPinIcon } from "../../../../../newSrc/constants/icons";

export const builtyCSS: any = `

.hide_print{
  color: transparent;
}
// html,body{
// // position: relative; 
// width: 100%;
// }
div,p{
  margin: 0px;
}
#builty_print {
margin: 0cm;
padding: 0cm;
// border: 1px solid black;
// width: 20.5cm;
// height: 11.5cm;
}
.step3{
  margin: $marginTopcm $marginRightcm $marginBottomcm $marginLeftcm;
  page-break-inside: avoid;
  // width: 20.5cm;
  // height: 29.5cm;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.part0 {
  height: 2.4cm;
}
.part1Flex{
display: flex;
}

.part1{
  height: 1.2cm;

}
.part1-label{
  width: 1cm;
}
.part1-value {
  width: 4.4cm;
  padding: 0.2cm 0cm 0cm 0.2cm;
  margin-top: -0.7cm;
}
.part1-label2{
  width: 1.8cm;
}
.part1-value2{
  width: 3.6cm;
margin-top: -0.4cm;
}
.part1_sub{
  height: 0.55cm;
  width: 4.1cm;
}
.part1_sub_label {
  width:1.5cm
}
.part1_sub_value{
  width: 2.9cm;
margin: -0.35cm 0px 0px 0.5cm;
}


.part2 {
  height: 2.4cm;
  margin: 0cm 0cm 0cm 0.3cm;
}
.part2_sub {
  height: 0.6cm;
}
.part2_label {
  width: 1.9cm;
}
.part2_value {
  width: 8.4cm;
  padding: 0.1cm 0cm 0cm 0.2cm;
margin-top: -0.3cm;
}
.goods_value{
  width: 3.6cm;
  padding: 0.1cm 0cm 0cm 0.2cm;
margin-top: -0.4cm;
}
.invoice_label{
  width: 1.2cm;
}
.invoice_value{
  width: 3.6cm;
  padding: 0.1cm 0cm 0cm 0.2cm;
margin-top: -0.4cm;
}

.packagesCss{
height: 1.3cm;
margin-left: 7px;
margin-top: 10px;
}

.part3 {
  margin-top: 1cm; 
}
.gap_part3 {
  margin-top: 0.1cm
  }
.bookedby {
  width: 7cm;
  margin: 0px;
  height: 0.6cm;
}
.part3_label {
  width: 1.9cm;
  height: 0.6cm;
}
.part3_label_small {
  width: 1cm;
  height: 0.6cm;
}
.text-bold{
font-weight: bold
}
.part3_value {
  width: 10.8cm;
  height: 0.6cm;
}
.part3_label_blank {
  width: 2cm;
  height: 1.2cm;
  margin-top: -0.45cm;
}                
.part3_value_blank {
  width: 10.8cm;
  height: 1.2cm;
  margin-top: -0.45cm;
}
.part3_label_blank>p {
  width: 2cm;
  margin: 0px;
  height: 0.4cm;
  padding-left: 0.4cm;
}
.part3_value_blank>p {
  width: 10.8cm;
  margin: 0px;
  height: 0.4cm;
}


.address {
  width: 12.2cm;
  margin-top: -0.8cm;
  margin-left: 4px;
  padding-left: 0.3cm;
  height: 1.8cm;
  display: flex;
  align-items: center;
}

.deliveryCss{
height: 1.8cm;
align-items: center;
}


.printPurpose {
  width: 2.6 cm;
  text-align: center;
  self-align: center;
}

.rate-labels{
margin-top: -30px;
  margin-right: 0px;
}

.rate-values{
  text-align: right;
  margin-top: -30px;
  margin-left: 5px;
}

.paymentMode{
  margin-top: 70px;
}

.total_label{
  margin-top: 3px;
padding-top: 3px;
}

.total_border{
  border-bottom: 1px solid black;
}

.qr{
  margin-left: 0px;
margin-top: 2px
}

.qrTrack{
  margin-top: 3px;
  font-size: 10px;
}

.v-text-verify{
  writing-mode: vertical-rl;
  margin-top: -35px;
  margin-left: 25px
  height: 200px
}
`;

export const BuiltyPureComponent = (props: any) => {
  const { docket } = props;
  const {
    concernedBuilty,
    printPurpose,
    printTrackingNum,
    printTackingAndDocket,
    forGatePass,
  } = props;

  const myEncode = (str: string) => {
    const encodedChars = [];
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      let newCharCode;
      if (charCode >= 97 && charCode <= 122) {
        newCharCode = (charCode - 97 + 4) % 26 + 97;
      } else if (charCode >= 65 && charCode <= 90) {
        newCharCode = (charCode - 65 + 4) % 26 + 65;
      } else if (charCode >= 48 && charCode <= 57) {
        newCharCode = (charCode - 48 + 4) % 10 + 48;
      } else {
        newCharCode = btoa(String.fromCharCode(charCode)).charCodeAt(0);
      }
      encodedChars.push(String.fromCharCode(newCharCode));
    }

    return btoa(encodedChars.join(""));
  };

  const replaceChar = (origString: any, replaceChar: any, index: any) => {
    let firstPart = origString.substr(0, index);
    let lastPart = origString.substr(index + 6);

    let newString = firstPart + replaceChar + lastPart;
    return newString;
  };

  const builtyDate = new Date(docket.createdAt).toLocaleDateString("en-IN");
  const builtyTime = new Date(docket.createdAt).toLocaleTimeString("en-IN");

  return (
    <div className="step3" id="step3">
      <div className="part0"></div>
      <div className="part1 part1Flex">
        <div className="flex">
          <div className="part1-label hide_print">From:</div>
          <div style={{ fontWeight: "bold" }} className="part1-value">
            {(docket.originBranch ? docket.originBranch.branchName : "") + ","}
            <br></br>
            {docket.originBranch.address.city.name}
          </div>
        </div>
        <div className="flex">
          <div className="part1-label hide_print">To:</div>
          <div style={{ fontWeight: "bold" }} className="part1-value">
            {(docket.destBranch
              ? docket.destBranch.branchName
              : docket.destBranch && docket.destBranch.address
                ? docket.destBranch.address.pincode
                : "") + ","}
            <br></br>
            {docket.destBranch.address.city.name}
          </div>
        </div>
        <div className="flex">
          <div className="part1-label2 hide_print">LR:</div>
          <div className="part1-value2">
            <div style={{ fontWeight: "bold" }}>
              {printTrackingNum && docket.trackingNumber ? (
                <Fragment>{docket.trackingNumber}</Fragment>
              ) : (
                <Fragment>{docket.docketNumber} </Fragment>
              )}
            </div>
            <div style={{ fontSize: "12px" }}>
              {docket.eWayBills?.length > 1 ?
                docket.eWayBills?.map((s: any, index: any) => {
                  return (<span key={index}>
                    {s}{index !== docket.eWayBills.length - 1 ? ', ' : ''}
                  </span>)
                }
                )
                : docket.eWayBill ? docket.eWayBill : ""}
            </div>
            <div style={{ fontSize: "12px" }}>
              {printTackingAndDocket ? (
                printTrackingNum && docket.trackingNumber ? (
                  <Fragment>{docket.docketNumber} </Fragment>
                ) : (
                  <Fragment>{docket.trackingNumber}</Fragment>
                )
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex-column">
          <div className="part1_sub flex">
            <div className="part1_sub_label hide_print">Date:</div>
            <div className="part1_sub_value">{builtyDate}</div>
          </div>
          <div className="part1_sub flex">
            <div className="part1_sub_label hide_print">Truck No.</div>
            <div className="part1_sub_value">{builtyTime}</div>
          </div>
        </div>
      </div>
      <div className="part2 flex-column">
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">Consignor</p>
          </div>
          <div
            style={{ fontWeight: "bold", fontSize: "12px" }}
            className="part2_value"
          >
            {docket.senderName}
          </div>
          <div className="part2_label">
            <p className="hide_print">Consignee</p>
          </div>
          <div
            style={{ fontWeight: "bold", fontSize: "12px" }}
            className="part2_value"
          >
            {docket.receiverName}
          </div>
        </div>
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">Mobile</p>
          </div>
          <div style={{ fontWeight: "bold" }} className="part2_value">
            {replaceChar(docket.sender.contact.toString(), "******", 2)}
          </div>
          <div className="part2_label">
            <p className="hide_print">Mobile</p>
          </div>
          <div style={{ fontWeight: "bold" }} className="part2_value">
            {replaceChar(docket.receiver.contact.toString(), "******", 2)}
          </div>
        </div>
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">GSTIN</p>
          </div>
          <div className="part2_value">{docket.senderGst || ""}</div>
          <div className="part2_label">
            <p className="hide_print">GSTIN</p>
          </div>
          <div className="part2_value">{docket.receiverGst || ""}</div>
        </div>
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">D.Value Rs.</p>
          </div>
          <div className="goods_value">
            <p>{
              docket.ewayGoods?.length > 1 ?
                docket.ewayGoods?.map((s: any, index: any) => {
                  return (<span key={index}>
                    {s}{index !== docket.ewayGoods.length - 1 ? ', ' : ''}
                  </span>)
                }
                ) :
                docket.goods
            }</p>
          </div>
          <div className="invoice_label">
            <p className="hide_print">Inv.No</p>
          </div>
          <div className="invoice_value">
            <p>
              <span style={{ fontWeight: 800 }}>{docket.purpose}</span>{" "}
              <span>{
                docket.ewayBillNo?.length > 1 ?
                  docket.ewayBillNo?.map((s: any, index: any) => {
                    return (<span key={index}>
                      {s}{index !== docket.ewayBillNo.length - 1 ? ', ' : ''}
                    </span>)
                  }
                  ) :
                  docket.billNo
              }</span>
            </p>
          </div>
          <div className="part2_label">
            <p></p>
          </div>
          <div className="part2_value">
            <b>{concernedBuilty ? concernedBuilty : null}</b>
          </div>
        </div>
      </div>
      <div className="part3 flex-column gap_part3">
        <div className="flex">
          <div className="part3_label">
            <p className="hide_print">Packages</p>
          </div>
          <div className="part3_value">
            <p className="hide_print">description</p>
          </div>
          <div className="part3_label">
            <p className="hide_print">weight</p>
          </div>
        </div>
        {/* Package Description */}
        <div className="flex packagesCss">
          <div className="part3_label_blank">
            {docket.packages
              ? docket.packages.map((p: any, j: number) => {
                return (
                  <p
                    className="packageQtys"
                    style={{ fontSize: "11px" }}
                    key={j}
                  >
                    {p.qty}
                  </p>
                );
              })
              : null}
          </div>
          <div className="part3_value_blank">
            {docket.packages
              ? docket.packages.map((p: any, j: number) => {
                return (
                  <p
                    className="packageDescriptions"
                    style={{ fontSize: "11px" }}
                    key={j}
                  >
                    {p.materialType.name}, {p.packingType.name},
                  </p>
                );
              })
              : null}
          </div>
          <div>
            <div className="flex-column">
              <div className="part3_label">
                <p>
                  {docket.packages
                    ? Math.round(
                      docket.packages.reduce(
                        (pv: number, cv: any) => pv + cv.qty * cv.weight,
                        0
                      )
                    )
                    : null}{" "}
                </p>
              </div>
              <div className="part3_label">
                <p className="hide_print"></p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex-column rate-labels">
              <div className="part3_label text-bold">
                <p>FREIGHT</p>
              </div>
              <div className="part3_label text-bold">
                <p>BILTY</p>
              </div>
              {/* <div className="part3_label text-bold">
                <p> H.C.</p>
              </div> */}
            </div>
            {Number(docket.discount) > 0 && (
              <div className="part3_label text-bold">
                {/* {forGatePass ? (
              <p>Delivery Charge </p>
            ) : ( */}
                <p>DISCOUNT</p>
                {/* )} */}
              </div>
            )}

            {Number(docket.deliveryCharge) > 0 && (
              <>
                <div className="part3_label text-bold">
                  <p>
                    BRANCH
                    <br /> DELIVERY
                  </p>
                </div>
                <br />
              </>
            )}
            {Number(docket.paidDemurrage) > 0 && (
              <div className="part3_label text-bold">
                <p>DEMRG</p>
              </div>
            )}
            {(Number(docket.BTOCharge) > 0 ||
              Number(docket.BTHCharge) > 0 ||
              Number(docket.NDCharge) > 0) && (
                <div className="part3_label text-bold">
                  <p>OTHER</p>
                </div>
              )}
            <div className="total_border"></div>
            <div className="part3_label text-bold">
              <p className=" total_label">TOTAL</p>
            </div>
          </div>
          <div>
            <div className="flex-column rate-values">
              <div className="part3_label_small text-bold">
                {/* {!forGatePass && ( */}
                <p>
                  {docket.packages.reduce(
                    (pv: number, cv: any) =>
                      pv +
                      (cv.rate === "Per package"
                        ? cv.qty * cv.amount +
                        (cv.intermediateCharge ? cv.intermediateCharge : 0)
                        : cv.rate === "Per Kg"
                          ? (cv.intermediateCharge ? cv.intermediateCharge : 0) +
                          cv.qty * cv.amount * cv.weight
                          : (cv.intermediateCharge ? cv.intermediateCharge : 0) +
                          cv.amount),
                    0
                  ) +
                    (docket.handling || 0) +
                    (docket.pickupCharge ? "," + docket.pickupCharge : "") +
                    (docket.doorDelCharges
                      ? docket.pickupCharge
                        ? "," + docket.doorDelCharges
                        : "," + 0 + "," + docket.doorDelCharges
                      : "")}{" "}
                </p>
                {/* )} */}
              </div>
              <div className="part3_label_small text-bold">
                <p>{docket.bilty}</p>
              </div>
              {/* <div className="part3_label text-bold">
                <p>
                  {(docket.handling || 0) +
                    (docket.pickupCharge ? "," + docket.pickupCharge : "") +
                    (docket.doorDelCharges
                      ? docket.pickupCharge
                        ? "," + docket.doorDelCharges
                        : "," + 0 + "," + docket.doorDelCharges
                      : "")}
                </p>
              </div> */}
              {Number(docket.discount) > 0 && (
                <div className="part3_label_small text-bold">
                  <p>{docket.discount}</p>
                </div>
              )}
              {Number(docket.deliveryCharge) > 0 && (
                <>
                  <div className="part3_label_small text-bold">
                    <p>{docket.deliveryCharge}</p>
                  </div>
                  <br />
                </>
              )}

              {Number(docket.paidDemurrage) > 0 && (
                <div className="part3_label_small text-bold">
                  <p>{docket.paidDemurrage}</p>
                </div>
              )}

              {(Number(docket.BTOCharge) > 0 ||
                Number(docket.BTHCharge) > 0 ||
                Number(docket.NDCharge) > 0) && (
                  <div className="part3_label_small text-bold">
                    <p>
                      {(docket.BTOCharge || 0) +
                        (docket.BTHCharge || 0) +
                        (docket.NDCharge || 0)}
                    </p>
                  </div>
                )}
              <div style={{ width: "1cm" }} className="total_border"></div>

              <div className="part3_label_small text-bold ">
                <p style={{ fontWeight: "bold" }} className="total_label">
                  {docket.settledAmount ||
                    docket.collectedGross ||
                    docket.gross +
                    (docket.BTOCharge || 0) +
                    (docket.BTHCharge || 0) +
                    (docket.NDCharge || 0)}
                </p>
              </div>
              {/* <div className="part3_label text-bold">
                <p> H.C.</p>
              </div> */}
            </div>
          </div>
          <div>
            {
              <div className="flex-column">
                {forGatePass && (
                  <p className="v-text-verify">
                    {``}
                    Identity Verified By OTP On {docket.deliveredTo}
                    {`${docket.deliveredToName
                      ? `, Name: ${docket.deliveredToName}`
                      : ""
                      }`}
                    {`, ${docket.deliveryRegNumber
                      ? `, Reg: ${docket.deliveryRegNumber}`
                      : ""
                      }`}
                    ,
                  </p>
                )}
              </div>
            }
          </div>
          <div>
            <div className="flex-column">
              <p className="v-text-verify">
                {``} Don't pay any extra amount
              </p>
            </div>
          </div>
        </div>

        {/* Booked By */}
        <div className="flex">
          <div className="part3_label">
            <p className="hide_print">bookedBy</p>
          </div>
          <div className="part3_value">
            <p>{""}</p>
          </div>
          <div className="part3_label">
            <p className="hide_print">RATE</p>
          </div>
        </div>


        <div className="flex deliveryCss">
          <div className="address">
            {`${docket?.destCompanyName} - ${docket.destBranch.address.l1} ${docket.destBranch.address.l2} ${docket.destBranch.address.pincode
              }  ${docket.destBranch.address.city.name} ${docket.destBranch
                ? docket.destBranch.pContact
                  ? "- " + docket.destBranch.pContact
                  : ""
                : ""
              } ${docket.fedexDetails && docket.fedexDetails.trackingDetails
                ? "FEDEX tracking id: " +
                docket.fedexDetails.trackingDetails.trackingNumber
                : ""
              }`}
          </div>

          <div className="flex-column qr">
            <QrGenerator
              value={`https://taptap.in/strack/${myEncode(
                docket.trackingNumber
              )}`}
              size={60}
            />
            <div className="part3_label qrTrack">
              <p>SCAN TO TRACK</p>
            </div>
          </div>
          <div data-testid="charges_labels_column" className="flex-column">
            <div className="part3_label">
              <p className="hide_print"></p>
            </div>
            <div className="part3_label">
              <p className="hide_print"></p>
            </div>
            <div className="part3_label">
              <p className="hide_print"></p>
            </div>
            <div className="part3_label">
              <p className="hide_print"></p>
            </div>
            <div className="part3_label">
              <p className="hide_print"></p>
            </div>
            <div className="part3_label">
              <p className="hide_print"></p>
            </div>
          </div>
          <div
            data-testid="charges_labels_column"
            className="flex-column gap_part3  "
          >
            <div className="part3_label text-bold paymentMode">
              <p> {docket.paymentMode.toUpperCase()} </p>
            </div>
          </div>
          <div className="printPurpose">
            <p>{printPurpose}</p>
          </div>
        </div>
        {/* )} */}
        {docket.calculatedGstAmt && (
          <div
            style={{
              fontWeight: 450,
              fontSize: "18px",
              marginTop: "-30px",
              marginLeft: "380px",
            }}
          >
            <p>GST Amount : {docket.calculatedGstAmt}</p>
          </div>
        )}
        {docket.gstAmount > 0 && (
          <div
            style={{
              fontWeight: 450,
              fontSize: "18px",
              marginTop: "-30px",
              marginLeft: "380px",
            }}
          >
            <p>GST Amount : {docket.gstAmount}</p>
          </div>
        )}
        {
          //docket.gst
          //   ? docket.purpose === "C"
          //     ?
          // ((docket.paymentMode === "paid" || docket.paymentMode === "noncash") &&
          //   docket.senderGst) ||
          //   (docket.paymentMode === "topay" && docket.receiverGst) ||
          //   (docket.paymentMode === "credit" &&
          //     docket.creditCustomer &&
          //     docket.creditCustomer.gst && (
          //       <div
          //       style={{
          //         fontWeight: 450,
          //         fontSize: "18px",
          //         marginTop: "-30px",
          //         marginLeft: "380px",
          //       }}
          //     >
          //       <p>GST Amount : {docket.gstAmount}</p>
          //     </div>
          //     ))
          //   : ""
          // : ""
        }

        {docket.delSpecific === true ? <div>Specific Delivery: YES</div> : null}
        {docket.remarks && <div>{docket.remarks}</div>}
      </div>
    </div>
  );
};

export const printBuilty = (
  docket: any,
  printTwoCopies: boolean,
  printTrackingNum?: boolean,
  printTackingAndDocket?: boolean,
  forGatePass?: boolean,
  opBranch?: any
) => {

  let css = builtyCSS.replace("$marginTop", opBranch.biltyPrintConfig.margin.top).replace("$marginRight", opBranch.biltyPrintConfig.margin.right).replace("$marginBottom", opBranch.biltyPrintConfig.margin.bottom).replace("$marginLeft", opBranch.biltyPrintConfig.margin.left)

  if (printTwoCopies === true) {
    printReactComponent(
      (props: any) => {
        return (
          <Fragment>
            <BuiltyPureComponent
              {...props}
              concernedBuilty="Consignor Copy"
              printPurpose="BR"
              printTrackingNum={true}
            />
            <BuiltyPureComponent
              {...props}
              concernedBuilty="Consignee Copy"
              printPurpose="BR"
            />
          </Fragment>
        );
      },
      { docket },
      css
    );
  } else {
    printReactComponent(
      (props: any) => {
        return (
          <BuiltyPureComponent {...props} />
        )
      },
      {
        docket,
        concernedBuilty: "",
        printPurpose: "",
        printTrackingNum,
        printTackingAndDocket,
        forGatePass,
        opBranch
      },
      css
    );
  }
};

export const qrCssDocket = `
@page { size: 4in 2in;  margin: 0mm;  }
@page {
  size: 4in 2in;
  margin: 0mm;
}

@media print {
  body { 
    width: 4in;
    height: 2in;
  }
}

* {
  -webkit-print-color-adjust: exact !important;   / Chrome, Safari 6 – 15.3, Edge /
  color-adjust: exact !important;                 / Firefox 48 – 96 /
  print-color-adjust: exact !important;           / Firefox 97+, Safari 15.4+ /
}


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  width: 4in;
  height: 2in;
  letter-spacing: 0.6px;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  width:4in;
  height: 2in;
}

.boundary{
  width:3.8in;
  height: 1.8in;
}

.top_container{
  width: 3.8in;
  height: 1.5in;
  display: flex;
  align-items: center;
}

.qr_container{
  height: 1.5in;
  width: 1.5in;
}

.inner_container{
  height: 1.5in;
  width: 2.3in;
}

.data_container{
  width: 2.3in;
  height: 0.75in;
  display: flex;
  justify-content: center;
}

.second_qr_container{
  width: 2.3in;
  height: 0.75in;
  display: flex;
  justify-content: space-around;
}

.qtyContainer{
  width: 1.65in;
  height: 0.75in;
}

.secondQr{
  height: 0.75in;
  width: 0.6in;
}

.dest{
  font-size: 24px;
  letter-spacing: 4px;
}
`;

export const qrCssFleet = `
@page { size: 4in 2in;  margin: 0mm;  }
@page {
  size: 4in 2in;
  margin: 0mm;
}

@media print {
  body {
    width: 4in;
    height: 2in;
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  width: 4in;
  height: 1.8in;
  letter-spacing: 0.6px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 5.5px;
  font-size: 35px;
  font-weight: 100;
  border-bottom: 1px solid black;
}

.container {
  display: flex;
  height: 2in;
  width: 4in;
  / background-color: wheat; /
}
.qr_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 45%;
  / background-color: pink; /
}
.qr_box {
  width: 90%;
  height: 81%;
  / background-color: red; /
}

.data_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  / width: 55%; /
  height: 100%;
  / background-color: lightsalmon; /
}

.table_container {
  width: 90%;
}
`;

export const QRDocketPureComponent = (props: any) => {
  const { docket } = props;
  const { orderNumber, totalQty, orderIndex, dimension, weight, unit } = props;

  const qrDate = new Date(docket.createdAt).toLocaleDateString("en-IN");
  const qrTime = new Date(docket.createdAt).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' });

  let shortName = docket.originBranch && docket.originBranch.shortName;
  let docketSub =
    docket.docketNumber &&
    docket.docketNumber.substring(
      docket.docketNumber.length - 5,
      docket.docketNumber.length
    );

  return (
    <div className="" style={{
      // height: "100%",
      // width: "100%",
      pageBreakBefore: "always",
      padding: "8px",
    }}>
      <div style={{
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center"
        width: "4.5in",
        height: "1.9in"
      }}>
        <div className="" style={{ display: "flex", flexDirection: "column", gap: "8px", height: "100%", width: "100%" }}>
          <div className="" style={{ display: "flex", gap: "8px" }}>
            <div className="" style={{ flexGrow: "0.5" }}>
              <QRCodeSVG
                style={{ width: "100%", height: "100%" }}
                value={`${docket.docketNumber}~${orderNumber}`}
              />
            </div>
            <div className="" style={{ display: "flex", flexDirection: "column", gap: "2px", textAlign: "center" }}>
              <span style={{ fontSize: "20px", fontWeight: "bolder", letterSpacing: "5px" }}>KABRA EXPRESS</span>
              <span style={{ fontSize: "16px", fontWeight: "normal", letterSpacing: "3px" }}><MapPinIcon
                aria-hidden="true"
              />
                {docket.originBranch &&
                  docket.originBranch.branchName &&
                  docket.originBranch.branchName.substring(0, 14)}</span>
              <span style={{ fontWeight: "bold" }}>
                {qrDate} - {qrTime}
                <span style={{ backgroundColor: "black", color: "white", padding: "2px", marginLeft: "2px", borderRadius: "4px", fontWeight: "bold" }}>
                  {`${weight.toFixed(0)} KG (${dimension.l}x${dimension.b}x${dimension.h} ${unit})`}
                </span>
              </span>
              <div style={{ display: "flex", gap: "2px", marginTop: "8px" }}>
                <div style={{ display: "flex", flexDirection: "column", fontSize: "24px" }}>
                  <span style={{ fontWeight: "bolder" }}>{shortName.substring(0, 3)}-{docketSub}</span>
                  <span style={{ backgroundColor: "black", color: "white", padding: "4px", borderRadius: "4px", fontWeight: "bolder" }}>{orderIndex}
                    /{totalQty}</span>
                </div>
                <div className="" style={{ width: "1in", height: "0.85in" }}>
                  <QRCodeSVG
                    style={{ width: "100%", height: "100%" }}
                    value={`${docket.docketNumber}~${orderNumber}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "20px", fontWeight: "900", letterSpacing: "3px", textAlign: "left" }}>
            <MapMarkerAltIcon />
            {docket.actDestBranch &&
              docket.actDestBranch.branchName &&
              docket.actDestBranch.branchName.substring(0, 20)}</div>
        </div>
      </div>
    </div>
  );
};

export const printQRDocket = (docket: any, packages: any) => {
  printReactComponent(
    (props: any) => {
      return (
        <Fragment>
          {packages &&
            packages.length > 0 &&
            packages.sort((a: any, b: any) => a.orderIndex - b.orderIndex).map((x: any, i: any) => {
              return (
                <QRDocketPureComponent
                  {...props}
                  orderNumber={x.order}
                  dimension={x.dimension}
                  weight={x.weight}
                  unit={x.unit}
                  orderIndex={x.orderIndex}
                  qty={i + 1}
                  totalQty={docket?.packages?.reduce((acc: any, curr: any) => acc + curr.qty, 0) || 0}
                />
              );
            })}
        </Fragment>
      );
    },
    { docket, packages },
    [qrCssDocket]
  );
};
export const BuiltyPureComponent2 = (props: any) => {
  const {
    shipmentNumber,
    createdAt,
    lastCustomer,
    lastPlace,
    firstPlace,
    eWayBills,
    firstCustomer,
    goods,
    // senderGst,
    // receiverGst,
    packages,
    // purpose,
    // deliveredTo,
    // deliveredToName,
    // deliveryRegNumber,
    // settledAmount,
    gross,
    advanceAmount,
    actualBookingType,
    paymentMode,
    user
  } = props;

  const myEncode = (str: string) => {
    const encodedChars = [];
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      let newCharCode;
      if (charCode >= 97 && charCode <= 122) {
        newCharCode = (charCode - 97 + 4) % 26 + 97;
      } else if (charCode >= 65 && charCode <= 90) {
        newCharCode = (charCode - 65 + 4) % 26 + 65;
      } else if (charCode >= 48 && charCode <= 57) {
        newCharCode = (charCode - 48 + 4) % 10 + 48;
      } else {
        newCharCode = btoa(String.fromCharCode(charCode)).charCodeAt(0);
      }
      encodedChars.push(String.fromCharCode(newCharCode));
    }

    return btoa(encodedChars.join(""));
  };

  const replaceChar = (origString: any, replaceChar: any, index: any) => {
    let firstPart = origString.substr(0, index);
    let lastPart = origString.substr(index + 6);

    let newString = firstPart + replaceChar + lastPart;
    return newString;
  };

  const builtyDate = new Date(createdAt).toLocaleDateString("en-IN");
  const builtyTime = new Date(createdAt).toLocaleTimeString("en-IN");

  return (
    <div className="step3" id="step3">
      <div className="part0"></div>
      {/* Part 1 */}
      <div className="part1 part1Flex">
        {/* From */}
        <div className="flex">
          <div className="part1-label hide_print">From:</div>
          <div style={{ fontWeight: "bold" }} className="part1-value">
            {((firstPlace?.city && firstPlace?.area) ? `${firstPlace?.area?.name}, ${firstPlace.city.name}` : "") + ","}
            <br></br>
            {/* {docket.originBranch.address.city.name} */}
          </div>
        </div>
        {/* To */}
        <div className="flex">
          <div className="part1-label hide_print">To:</div>
          <div style={{ fontWeight: "bold" }} className="part1-value">
          {((lastPlace?.city && lastPlace?.area) ? `${lastPlace?.area?.name}, ${lastPlace.city.name}` : "") + ","}
            <br></br>
            {/* {docket.destBranch.address.city.name} */}
          </div>
        </div>
        {/* LR No: */}
        <div className="flex">
          <div className="part1-label2 hide_print">LR:</div>
          <div className="part1-value2">
            <div style={{ fontWeight: "bold" }}>
              {shipmentNumber}
            </div>
            <div style={{ fontSize: "12px" }}>
              {eWayBills?.length > 1 ?
                eWayBills?.map((s: any, index: any) => {
                  return (<span key={index}>
                    {s}{index !== eWayBills.length - 1 ? ', ' : ''}
                  </span>)
                }
                )
                : eWayBills ? eWayBills : ""}
            </div>
            {/* <div style={{ fontSize: "12px" }}>
              {shipmentNumber ? shipmentNumber : null}
            </div> */}
          </div>
        </div>
        {/* Date & Truck No: */}
        <div className="flex-column">
          <div className="part1_sub flex">
            <div className="part1_sub_label hide_print">Date:</div>
            <div className="part1_sub_value">{builtyDate}</div>
          </div>
          <div className="part1_sub flex">
            <div className="part1_sub_label hide_print">Truck No.</div>
            <div className="part1_sub_value">{builtyTime}</div>
          </div>
        </div>
      </div>
      {/* Part 2 */}
      <div className="part2 flex-column">
        {/* Consignor & Consignee */}
        <div className="part2_sub flex">
          {/* Consignor */}
          <div className="part2_label">
            <p className="hide_print">Consignor</p>
          </div>
          <div
            style={{ fontWeight: "bold", fontSize: "12px" }}
            className="part2_value"
          >
            {firstCustomer?.name}
          </div>
          {/* Consignee */}
          <div className="part2_label">
            <p className="hide_print">Consignee</p>
          </div>
          <div
            style={{ fontWeight: "bold", fontSize: "12px" }}
            className="part2_value"
          >
            {lastCustomer?.name}
          </div>
        </div>
        {/* Mobile No */}
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">Mobile</p>
          </div>
          <div style={{ fontWeight: "bold" }} className="part2_value">
            {replaceChar(firstCustomer?.contact.toString(), "******", 2)}
          </div>
          <div className="part2_label">
            <p className="hide_print">Mobile</p>
          </div>
          <div style={{ fontWeight: "bold" }} className="part2_value">
            {replaceChar(lastCustomer?.contact.toString(), "******", 2)}
          </div>
        </div>
        {/* GST IN */}
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">GSTIN</p>
          </div>
          {/* // TODO: ADD GST Information */}
          <div className="part2_value">{""}</div>
          <div className="part2_label">
            <p className="hide_print">GSTIN</p>
          </div>
          <div className="part2_value">{""}</div>
        </div>
        {/* D.Vales | Goods Value */}
        <div className="part2_sub flex">
          <div className="part2_label">
            <p className="hide_print">D.Value Rs.</p>
          </div>
          <div className="goods_value">
            <p>{
              goods || 0
            }</p>
          </div>
          <div className="invoice_label">
            <p className="hide_print">Inv.No</p>
          </div>
          <div className="invoice_value">
            <p>
              {/* // TODO: Add Invoice No */}
              {/* <span style={{ fontWeight: 800 }}>{docket.purpose}</span>{" "} */}
              {/* <span>{
                docket.ewayBillNo?.length > 1 ?
                  docket.ewayBillNo?.map((s: any, index: any) => {
                    return (<span key={index}>
                      {s}{index !== docket.ewayBillNo.length - 1 ? ', ' : ''}
                    </span>)
                  }
                  ) :
                  docket.billNo
              }</span> */}
              {""}
            </p>
          </div>
          <div className="part2_label">
            <p></p>
          </div>
          <div className="part2_value">
            {/* <b>{concernedBuilty ? concernedBuilty : null}</b> */}
          </div>
        </div>
      </div>
      {/* Part 3 */}
      <div className="part3 flex-column gap_part3">
        {/* Packages | Description */}
        <div className="flex">
          <div className="part3_label">
            <p className="hide_print">Packages</p>
          </div>
          <div className="part3_value">
            <p className="hide_print">description</p>
          </div>
          <div className="part3_label">
            <p className="hide_print">weight</p>
          </div>
        </div>
        {/* Package Description */}
        <div className="flex packagesCss">
          <div className="part3_label_blank">
            {packages && packages.length > 0
              ? packages.map((p: any, j: number) => {
                return (
                  <p
                    className="packageQtys"
                    style={{ fontSize: "11px" }}
                    key={j}
                  >
                    {p.qty}
                  </p>
                );
              })
              : null}
          </div>
          <div className="part3_value_blank">
            {packages && packages.length > 0
              ? packages.map((p: any, j: number) => {
                return (
                  <p
                    className="packageDescriptions"
                    style={{ fontSize: "11px" }}
                    key={j}
                  >
                    {p.materialType.name},
                  </p>
                );
              })
              : null}
          </div>
          <div>
            <div className="flex-column">
              <div className="part3_label">
                <p>
                  {packages && packages.length > 0
                    ? Math.round(
                      packages.reduce(
                        (pv: number, cv: any) => pv + cv.qty * cv.weight,
                        0
                      )
                    )
                    : null}{" "}
                </p>
              </div>
              <div className="part3_label">
                <p className="hide_print"></p>
              </div>
            </div>
          </div>
          {actualBookingType === "Full Load" ? <>
            <div>
              <div className="flex-column rate-labels">
                <div className="part3_label text-bold">
                  <p>Total Amt</p>
                </div>
                <div className="part3_label text-bold">
                  <p>Adv Amt</p>
                </div>
                {/* <div className="part3_label text-bold">
                <p> H.C.</p>
              </div> */}
              </div>
              <div className="total_border"></div>
              <div className="part3_label text-bold">
                <p className=" total_label">TOTAL</p>
              </div>
              <div className="part3_label text-bold">
                <p className=" total_label">Type</p>
              </div>
            </div>
            <div>
              <div className="flex-column rate-values">
                <div className="part3_label_small text-bold">
                  {/* {!forGatePass && ( */}
                  <p>
                    {gross}
                  </p>
                  {/* )} */}
                </div>
                <div className="part3_label_small text-bold">
                  <p>{advanceAmount}</p>
                </div>
                {/* <div className="part3_label text-bold">
                <p>
                  {(docket.handling || 0) +
                    (docket.pickupCharge ? "," + docket.pickupCharge : "") +
                    (docket.doorDelCharges
                      ? docket.pickupCharge
                        ? "," + docket.doorDelCharges
                        : "," + 0 + "," + docket.doorDelCharges
                      : "")}
                </p>
              </div> */}
                <div style={{ width: "1cm" }} className="total_border"></div>

                <div className="part3_label_small text-bold ">
                  <p style={{ fontWeight: "bold" }} className="total_label">
                    {gross - advanceAmount}
                  </p>
                </div>
                <div className="part3_label_small text-bold ">
                  <p style={{ fontWeight: "bold" }} className="total_label">
                    {actualBookingType}
                  </p>
                </div>
                {/* <div className="part3_label text-bold">
                <p> H.C.</p>
              </div> */}
              </div>
            </div></> : <>
            <div>
              <div className="flex-column rate-labels">
                <div className="part3_label text-bold">
                  <p>Total Amt</p>
                </div>
                <div className="part3_label text-bold">
                  <p>Payment</p>
                </div>
                <div className="part3_label text-bold">
                  <p>Type</p>
                </div>
                {/* <div className="part3_label text-bold">
                <p> H.C.</p>
              </div> */}
              </div>
            </div>
            <div>
              <div className="flex-column rate-values">
                <div className="part3_label_small text-bold">
                  {/* {!forGatePass && ( */}
                  <p>
                    {gross}
                  </p>
                  {/* )} */}
                </div>
                <div className="part3_label_small text-bold">
                  <p>{paymentMode?.toUpperCase()}</p>
                </div>
                <div className="part3_label_small text-bold">
                  <p>{actualBookingType}</p>
                </div>
                {/* <div className="part3_label text-bold">
                <p>
                  {(docket.handling || 0) +
                    (docket.pickupCharge ? "," + docket.pickupCharge : "") +
                    (docket.doorDelCharges
                      ? docket.pickupCharge
                        ? "," + docket.doorDelCharges
                        : "," + 0 + "," + docket.doorDelCharges
                      : "")}
                </p>
              </div> */}
                {/* <div className="part3_label text-bold">
                <p> H.C.</p>
              </div> */}
              </div>
            </div></>}
          {/* )} */}
        </div>
      </div>

      {/* Part 4 */}
      {/* Booked by & Rate */}
      <div className="flex">
        <div className="part3_label">
          <p className="hide_print">bookedBy</p>
        </div>
        <div className="part3_value">
          <p>{user?.fullName || ""}</p>
        </div>
        <div className="part3_label">
          <p className="hide_print">RATE</p>
        </div>
      </div>

      {/* TO BE DELIVERED AT */}

      <div className="flex deliveryCss">
        <div className="address">
          {lastPlace?.formatted_address}
        </div>

        <div className="flex-column qr">
          <QrGenerator
            value={`https://taptap.in/strack/${myEncode(
              shipmentNumber
            )}`}
            size={60}
          />
          <div className="part3_label qrTrack">
            <p>SCAN TO TRACK</p>
          </div>
        </div>
        <div data-testid="charges_labels_column" className="flex-column">
          <div className="part3_label">
            <p className="hide_print"></p>
          </div>
          <div className="part3_label">
            <p className="hide_print"></p>
          </div>
          <div className="part3_label">
            <p className="hide_print"></p>
          </div>
          <div className="part3_label">
            <p className="hide_print"></p>
          </div>
          <div className="part3_label">
            <p className="hide_print"></p>
          </div>
          <div className="part3_label">
            <p className="hide_print"></p>
          </div>
        </div>
        <div
          data-testid="charges_labels_column"
          className="flex-column gap_part3  "
        >
          {/* <div className="part3_label text-bold paymentMode">
              <p> {paymentMode.toUpperCase()} </p>
            </div> */}
        </div>
        <div className="printPurpose">
          {/* <p>{printPurpose}</p> */}
        </div>
      </div>
      {/* )} */}

      {/* {docket.calculatedGstAmt && (
          <div
            style={{
              fontWeight: 450,
              fontSize: "18px",
              marginTop: "-30px",
              marginLeft: "380px",
            }}
          >
            <p>GST Amount : {docket.calculatedGstAmt}</p>
          </div>
        )} */}
      {/* {docket.gstAmount > 0 && (
          <div
            style={{
              fontWeight: 450,
              fontSize: "18px",
              marginTop: "-30px",
              marginLeft: "380px",
            }}
          >
            <p>GST Amount : {docket.gstAmount}</p>
          </div>
        )} */}
      {
        //docket.gst
        //   ? docket.purpose === "C"
        //     ?
        // ((docket.paymentMode === "paid" || docket.paymentMode === "noncash") &&
        //   docket.senderGst) ||
        //   (docket.paymentMode === "topay" && docket.receiverGst) ||
        //   (docket.paymentMode === "credit" &&
        //     docket.creditCustomer &&
        //     docket.creditCustomer.gst && (
        //       <div
        //       style={{
        //         fontWeight: 450,
        //         fontSize: "18px",
        //         marginTop: "-30px",
        //         marginLeft: "380px",
        //       }}
        //     >
        //       <p>GST Amount : {docket.gstAmount}</p>
        //     </div>
        //     ))
        //   : ""
        // : ""
      }

      {/* {docket.delSpecific === true ? <div>Specific Delivery: YES</div> : null}
        {docket.remarks && <div>{docket.remarks}</div>} */}
    </div>
  );
}
export const printBuilty2 = (
  data: any,
  //  user:any,
  //  opBranch?: any
) => {
  // const newData = {...data, user};
  let css = builtyCSS
  //.replace("$marginTop", opBranch.biltyPrintConfig.margin.top).replace("$marginRight", opBranch.biltyPrintConfig.margin.right).replace("$marginBottom", opBranch.biltyPrintConfig.margin.bottom).replace("$marginLeft", opBranch.biltyPrintConfig.margin.left)
  printReactComponent(
    (props: any) => {
      return (
        <BuiltyPureComponent2 {...props} />
      )
    }
    ,
    data
    ,
    css
  );
};

export const QRFleetPureComponent = (props: any) => {
  const { fleetData } = props;

  return (
    <div className="container">
      <div className="qr_container">
        <div className="qr_box">
          <QRCodeSVG
            style={{ width: "100%", height: "100%" }}
            value={`${fleetData.regNumber}~${fleetData.fleetId}`}
          />
        </div>
      </div>
      <div className="data_container">
        <div className="table_container">
          <table>
            <tr>
              <th style={{ fontSize: 20 }} colSpan={2}>
                KABRA EXPRESS
              </th>
            </tr>
            <tr>
              <th>REG NUMBER</th>
              <th>{fleetData.regNumber}</th>
            </tr>
            <tr>
              <th>TYPE</th>
              <th>{fleetData.type}</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export const printQRFleet = (fleetData: any) => {
  printReactComponent(
    (props: any) => {
      return (
        <Fragment>
          {Array.isArray(fleetData) ? (
            fleetData.map((fleet: any) => (
              <QRFleetPureComponent
                {...props}
                fleetData={{
                  fleetId: fleet._id,
                  regNumber: fleet.regNumber,
                  type: fleet.fleetType,
                }}
              />
            ))
          ) : (
            <QRFleetPureComponent {...props} fleetData={fleetData} />
          )}
        </Fragment>
      );
    },
    {},
    qrCssFleet
  );
};

export const printCustomerBuilty = (
  docket: any,
) => {
  printReactComponent(
    (props: any) => {
      return (
        <Fragment>
          <BuiltyPrint
            {...props}
          />
        </Fragment>
      );
    },
    { docket },
  );
};