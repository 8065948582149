import { createAsyncThunk } from "@reduxjs/toolkit";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import { departmentListService } from "../../../services/user";
import showMessage, {
  failed,
  showHttpError,
  success,
} from "../../../utils/message";
import { getCategoryList } from "../../../services/category";
import {
  changeAddIncomeTypes,
  submitIncomeTypeSuccess,
} from "../company/companySlice";
import { extractHandler, resolveAPI } from "../../../services/helpers/error";
import {
  deletedIncomeRecord,
  editIncomeRecordService,
  getIncomeTypesService,
  getPastIncomes as getPastIncomesService,
  incomeSubmitHandler,
  postIncomeRecord,
} from "../../../services/income";
import {
  clearAddIncome,
  editIncomeRecordFailed,
  getAssetBal,
  getIncomeTypesData,
  getIncomeTypesFailed,
  getSubTypes,
  postIncomeRecordFailed,
  postIncomeRecordSuccess,
  setAssetTypes,
  setIncomeRecordData,
  setPastIncome,
  updatePastIncomes,
} from "./incomeSlice";
import { RootState } from "../../store";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import {
  getAssetBalance,
  listAssetType,
  viewAssetBalanceDetails,
} from "../../../services/asset";
import { updateBalance } from "../user/userSlice";
import { toast } from "react-toastify";
import { final_Incur_Income_Validation, validateIncome } from "./helper";
import jsonToFormdata from "../../../utils/jsonToFormdata";
import { AgentList } from "../../../services/branch";

export const changeIncomeTypeApi = createAsyncThunk(
  "income/changeIncomeType",
  async ({ what, val }: { what: string; val: string }, { dispatch }) => {
    let payload = { what, val };
    try {
      dispatch(showLoader());
      let response = await getCategoryList();
      dispatch(setAssetTypes(response))
      dispatch(changeAddIncomeTypes(payload));
      return response;
    } catch (error) {
      showHttpError(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const changeIncomeType = (what: any, val: any) => {
  return (dispatch: any) => dispatch(changeIncomeTypeApi({ what, val }));
};

export const incomeSubmit = createAsyncThunk(
  "income/incomeSubmit",
  async (data: any, { dispatch }) => {
    try {
      await dispatch(showLoader());
      let response = await resolveAPI(incomeSubmitHandler, [data]);
      showMessage("Income type created successfully!", success);
      dispatch(submitIncomeTypeSuccess());
      return response.data;
    } catch (error) {
      showHttpError(error);
      await dispatch(hideLoader());
    }
  }
);

export const fetchDepartmentList = createAsyncThunk(
  "userForm/fetchDepartmentList",
  async ({ companyId }: any) => {
    try {
      const response = await departmentListService(companyId);
      return response;
    } catch (error) {
      showHttpError("Could Not Fetch Departments!");
    }
  }
);

export const setIncomeRecord = createAsyncThunk(
  "income/setIncomeRecord",
  async ({ data, cb }: any, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const fetchedInputTypes = state.income.fetchedInputTypes;

      if (isArrayCheck(fetchedInputTypes)) {
        let ref = fetchedInputTypes.find((f: { id: any }) => f.id == data.ref);
        if (!ref)
          throw new Error("Income type of selected record does not exists.");
        data = {
          ...data,
          ref: {
            label: ref.name,
            value: ref.id,
          },
        };
      }
      if (data.route) {
        let fetchedRoutes = state.user.routes as any;
        if (isArrayCheck(fetchedRoutes)) {
          let foundRoute = fetchedRoutes.find(
            (r: { _id: string }) => r._id == data.route
          );
          data = {
            ...data,
            is_route: true,
            route: {
              label: foundRoute && foundRoute.name ? foundRoute.name : "N/A",
              value: foundRoute._id ? foundRoute._id : "",
            },
          };
        }
      }
      let sub_val = data.sub_val;
      switch (data.sub) {
        case "A": {
          let fetchAssetDetails = await viewAssetBalanceDetails(sub_val);
          let { _id, category, assetName } = fetchAssetDetails.type;
          let assetType = { label: assetName, value: _id };
          data = {
            ...data,
            assetType,
            // category
          };
          let body = {
            categoryId: category,
            company: state.user.company._id,
          };
          let assetTypes = await listAssetType(body);
          dispatch(setAssetTypes(assetTypes));
          let assetData = {
            assetType: _id,
            company: state.user.company._id,
          };
          const temp = await getAssetBalance(assetData);
          dispatch(getAssetBal(temp));
          if (isArrayCheck(temp)) {
            sub_val = temp.find((s: { _id: string }) => s._id == sub_val);
            data = {
              ...data,
              sub_val: {
                label: sub_val.uniqueId
                  ? sub_val.uniqueId
                  : sub_val.qty + " " + sub_val.type.assetName,
                value: sub_val._id,
              },
            };
          }
          break;
        }
        case "C": {
          let fetchAssetDetails = await viewAssetBalanceDetails(sub_val);
          let { _id, category, assetName } = fetchAssetDetails.type;
          let assetType = { label: assetName, value: _id };
          data = {
            ...data,
            assetType,
            // category
          };
          let body = {
            categoryId: category,
            company: state.user.company._id,
          };
          let assetTypes = await listAssetType(body);
          dispatch(setAssetTypes(assetTypes));
          let assetData = {
            assetType: _id,
            company: state.user.company._id,
          };
          const temp = await getAssetBalance(assetData);
          dispatch(getAssetBal(temp));
          if (isArrayCheck(temp)) {
            sub_val = temp.find((s: { _id: string }) => s._id == sub_val);
            data = {
              ...data,
              sub_val: {
                label: sub_val.uniqueId
                  ? sub_val.uniqueId
                  : sub_val.qty + " " + sub_val.type.assetName,
                value: sub_val._id,
              },
            };
          }
          break;
        }
        case "B": {
          let branches = state.user.branches;
          sub_val = branches.find((b: { _id: string }) => b._id == sub_val);
          data = {
            ...data,
            sub_val: { label: sub_val.branchName, value: sub_val._id },
          };
          dispatch(getSubTypes(branches));
          break;
        }
        case "G": {
          break;
        }
        default: {
          showMessage("Unhandled Sub Type", failed);
          break;
        }
      }
      if (typeof cb === "function") {
        cb();
      }
      dispatch(setIncomeRecordData(data));
    } catch (error) {
      showHttpError(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const getSubIncomeType = createAsyncThunk(
  "income/getSubIncomeType",
  async (incomeData: any, { dispatch, getState }) => {
    try {
      dispatch(clearAddIncome());
      const { user } = getState() as RootState;
      switch (incomeData.sub) {
        case "B": {
          dispatch(getSubTypes(user.branches));
          break;
        }
        case "A": {
          let body = {
            categoryId: incomeData.category,
            company: user.company._id,
          };

          let response = await listAssetType(body);
          dispatch(setAssetTypes(response));
          break;
        }
        case "C": {
          let body = {
            categoryId: incomeData.category,
            company: user.company._id,
          };

          let response = await listAssetType(body);
          dispatch(setAssetTypes(response));
          break;
        }
        default:
          break;
      }
    } catch (error) {
      showHttpError("Could not fetch Sub Types");
    }
  }
);

export const setIncomeRecordApi = (data: any, cb: any) => {
  return setIncomeRecord({ data, cb });
};

export const getIncomeTypes = createAsyncThunk(
  "income/getIncomeTypes",
  async (data: any, { dispatch, getState }) => {
    try {
      let { adminLevelIncur } = (getState() as RootState).rights.wallet
        .incurIncome;
      dispatch({ type: "SHOW_LOADER" });
      let response = await getIncomeTypesService(data);
      let result = [];
      if (adminLevelIncur === false) {
        for (let i = 0; i < response.length; i++) {
          if (response[i].general == true) result.push(response[i]);
        }
      } else result = response;
      dispatch(getIncomeTypesData(result));
    } catch (error) {
      showMessage("Could not fetch Income types !", failed);
      dispatch(getIncomeTypesFailed());
    } finally {
      dispatch({ type: "HIDE_LOADER" });
    }
  }
);

export const fetchAssetTypes = createAsyncThunk(
  "income/fetchAssetTypes",
  async (id: number, { dispatch, getState }) => {
    try {
      let data: { [k: string]: any } = {};
      data["assetType"] = id;
      data["company"] = (getState() as RootState).user.company._id;
      let response = await getAssetBalance(data);
      dispatch(getAssetBal(response));
    } catch (error) {
      showHttpError("Could not fetch Asset");
    }
  }
);

export const deleteIncomeRecord = createAsyncThunk(
  "income/deleteIncomeRecord",
  async (_, { dispatch, getState }) => {
    try {
      dispatch({ type: "SHOW_LOADER" });
      const state = getState() as RootState;
      let id = state.income.postIncomeRecord?.id;
      let company = state.user.company._id;
      let { balance } = await deletedIncomeRecord(id, company);
      if (state.user.uid == balance.user) {
        const payload = {
          balance: balance?.balance,
          options: balance,
        };
        dispatch(updateBalance(payload));
      }
      await dispatch(updatePastIncomes(null, { remove: true, id }));
    } catch (error) {
      dispatch({ type: "HIDE_LOADER" });
      showHttpError(error);
    }
  }
);

export const sendIncomeRecord = createAsyncThunk(
  "income/sendIncomeRecord",
  async (data: any, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      dispatch({ type: "SHOW_LOADER" });
      let isValid;
      let income = state.income;
      try {
        isValid = validateIncome(final_Incur_Income_Validation, data);
        if (typeof isValid == "string") {
          throw "Validation Error";
        }
      } catch (error) {
        showMessage(isValid, failed);
        dispatch(postIncomeRecordFailed());
      }
      if (typeof isValid == "string") throw isValid;
      try {
        let { balance, income: freshRecord } = await postIncomeRecord(
          jsonToFormdata(data)
        );
        await AgentList();
        try {
          if (state.user.uid == balance.user) {
            const payload = {
              balance: balance?.balance,
              options: balance,
            };
            dispatch(updateBalance(payload));
          }
        } catch (err: any) {
          showHttpError(err);
        }
        dispatch(
          updatePastIncomes({
            ...freshRecord,
            name: income.postIncomeRecord.ref.label,
          })
        );
      } catch (err: any) {
        throw extractHandler(err);
      }
      dispatch({ type: "HIDE_LOADER" });
      showMessage("Income Record successfully Added", success);
      dispatch(postIncomeRecordSuccess());
    } catch (error) {
      if (typeof error === "string") toast.error(error);
      dispatch({ type: "HIDE_LOADER" });
    }
  }
);

export const editIncomeRecord = createAsyncThunk(
  "income/editIncomeRecord",
  async (data: any, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      let isValid;
      dispatch({ type: "SHOW_LOADER" });
      try {
        isValid = validateIncome(final_Incur_Income_Validation, data);
        if (typeof isValid == "string") throw "Validation Error";
      } catch (error) {
        dispatch(editIncomeRecordFailed());
        showMessage(isValid, failed);
      }
      if (typeof isValid == "string") throw "Escaped Error";
      let response = await editIncomeRecordService(
        jsonToFormdata(data),
        data.id
      );
      await dispatch(hideLoader());
      let { balance, record } = response;
      if (!isNaN(balance.balance)) {
        const { user, sub, entity } = balance;

        if (user == state.user.uid) {
          const payload = {
            balance: balance?.balance,
            options: { sub, entity },
          };
          await dispatch(updateBalance(payload));
        }
      }
      await dispatch(
        updatePastIncomes(record, {
          id: record.id,
          update: true,
        })
      );
      dispatch(editIncomeRecordFailed());
      showMessage(isValid, failed);
    } catch (error: any) {
      dispatch({ type: "HIDE_LOADER" });
      if (error && error.message && error.message) {
        let { message: err } = error;
        let temp = Object.keys(err),
          key = temp[temp.length - 1];
        showMessage(err[key], failed);
      } else {
        showMessage(error, failed);
      }
    }
  }
);

export const setPastIncomesApi = createAsyncThunk(
  "income/setPastIncomes",
  async (
    data: { user: string; entity: string; loginType: string },
    { dispatch }
  ) => {
    try {
      const { entity, loginType, user } = data;
      const response = await getPastIncomesService(user, entity, loginType);
      dispatch(setPastIncome(response));
    } catch (err: any) {
      showHttpError(extractHandler(err));
    }
  }
);

export const setPastIncomes = (
  user: any,
  entity: string,
  loginType: string
) => {
  const payload = {
    user,
    entity,
    loginType,
  };
  return setPastIncomesApi(payload);
};

// export const changeFlat = async (key: string, val: any) => {
//   return changeFlatApi({ key, val });
// };
