import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import {
  BookErrorType,
  BookingInitialType,
  ReceiverTouchedType,
  SenderTouchedType,
  senderVerificationType,
} from "./booking.type";
import {
  bookPurpose,
  selectBookService,
  setBookBranches,
  setHandlingChargeSlab,
  setPackageSuggestions,
  updateBookingCounters,
} from "./api";
import { get, omit, set } from "lodash";
import { toRSOption } from "./helper";
import {
  countBuiltyCharges,
  countNewHandlingCharges,
} from "../../../reducers/utils/count";
import { SelectValueType } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/types/booking.type";
import { BookingError } from "../../../validators/err";
import showMessage, { failed, success } from "../../../utils/message";
import { toast } from "react-toastify";
import { isArrayCheck } from "../../../containers/generics/CheckArray";

const initCredit = {
  balance: null,
  company: null,
  customer: null,
  doesEntityHasCreditPermission: false,
  permissions: [],
  fromBranches: [],
  toBranches: [],
};

const colReport = {
  bookings: [],
  expenses: [],
  deliveries: [],
  sumOfExpense: 0,
  sumOfGross: 0,
};

const initialState: BookingInitialType = {
  bookingCounters: {
    dockets: 0,
    packages: 0,
    amount: 0,
  },
  company: {
    id: "",
  },
  allcities: [],
  contactVerified: false,
  insuredCompanies: [],
  isContactValidated: false,
  loginType: "B",
  senderGst: "",
  rateId: "",
  LR: "",
  minValueCharge: 0,
  packageSuggestions: [],
  perPackageValueCharge: 0,
  rateSuggestions: [],
  receiverGst: false,
  updateBuilty: false,
  valueChargeEnabled: false,
  bookService: { label: "", value: "", Route: [] },
  searchBookingEntity: "",
  receiverBranchAddress: {
    city: { _id: "" },
    pincode: "",
  },
  creditNumberLoading: false,
  canelReason: false,
  resetPackageErrors: false,
  bookingUpdate: {
    packages: false,
    destCity: false,
    sizes: false,
    payment: false,
  },
  lastBookingDetail: {
    createdAt: "",
    docketNumber: "",
    trackingNumber: "",
    _id: "",
  },
  material: "",
  trackingNumber: "",
  docketNumber: "",
  packingType: [],
  purpose: { label: "Commercial", value: "C" },
  goods: "",
  delDocket: {
    docket: {
      _id: "",
      status: false,
      isOrangeBooking: "",
      isFedexBooking: false,
      settled: false,
      gross: 0,
      user: "",
      cancelRequested: false,
      docketNumber: "",
      // packages: false,
    },
  },
  fedexLR: "",
  originCity: {
    label: "",
    value: "",
  },
  fedexServiceType: {
    label: "",
    value: "",
  },
  fedexServiceTypeOptions: [],
  pickupContact: "",
  contactData: null,
  remarks: "",
  cancelReason: "",
  userName: null,
  contact: null,
  driverName: null,
  from: null,
  to: null,
  assign: {
    branches: null,
    departments: null,
    fleets: null,
  },
  ppf: false,
  kgw: false,
  fr_ppf: false,
  fr_kgw: false,
  editBranchId: null,
  routeSugg: null,
  fr: 0,
  grossFr: 0,
  totalWeight: 0,
  pickupCharge: 0,
  deliveryCharge: 0,
  editDocket: false,
  deliveryType: { value: "Branch To Branch", label: "Branch To Branch" },
  bf: 0,
  hf: 0,
  dc: 0,
  gross: 0,
  packages: [
    {
      id: 0,
      qty: "",
      materialType: { label: "", value: "" },
      packingType: { label: "", value: "" },
      size: { label: "custom", value: "custom" },
      dimension: { l: "", b: "", h: "" },
      weight: "",
      rate: { value: "Per package", label: "Per package" },

      unit: "",
      amount: "",
      stack: false,
      haz: false,
      frag: false,
      status: "",
    },
  ],
  // transportCharges:[],// this field for store transport charge company wise
  fetchDocketUpdate: [],
  risk: 0,
  imageGalleryArr: [
    {
      billUrl: "",
      idUrl: "",
      consigneeIdImage: "",
      receiverPhoto: "",
      docketUrls: "",
    },
  ],
  valueCharge: 0,
  ins: 0,
  isCol: false,
  howcollect: "",
  colMonth: 0,
  customPack: "null",
  bookingRate: "Direct",
  credit: initCredit,
  assignroute: "",
  across: "interState",
  col: "",
  bookdestcity: {
    label: "",
    value: "",
  },
  bookroute: {
    label: "",
    value: "",
  },
  bookdestbranch: {
    label: "",
    value: "",
    company: {
      _id: "",
      companyName: "",
    },
    distance: "",
  },
  actDestBranch: {
    label: "",
    value: "",
    company: { _id: "" },
    distance: "",
  },
  isEwayBill: false,
  franchiseNumber: {
    label: "",
    value: "",
  },
  sender: {
    name: {
      label: "",
      value: "",
    },
    connectedContacts: {
      contact: "",
      gstNos: [],
    },
    customerType: "",
    contact: "",
    gst: {
      label: "",
      value: "",
    },
    l1: "",
    l2: "",
    pin: "",
    city: "",
    otherAddr: [],
    gstNos: undefined,
    names: undefined,
    senderCode: "",
    ewayBillSenderId: "",
    ewayBillSenderName: "",
    ewayBillSenderContact: "",
    isSenderDisable: false,
    contactVerified: false,
  },
  intermediateCharge: 0,
  onlinePaymentStatus: "Pending",
  packValue: 0,
  insMinValue: 0,
  receiver: {
    name: {
      label: "",
      value: "",
    },
    connectedContacts: {
      contact: "",
      gstNos: [],
    },
    contact: "",
    customerType: "",
    gst: {
      label: "",
      value: "",
    },
    l1: "",
    l2: "",
    pin: "",
    city: "",
    otherAddr: [],
    gstNos: undefined,
    names: undefined,
    receiverCode: "",
    ewayBillReceiverId: "",
    ewayBillReceiverName: "",
    ewayBillReceiverContact: "",
    isReceiverDisable: false,
    isContactValidated: false,
  },
  bookingStatus: null,
  filbranches: [],
  fixRateCustomer: {
    name: "",
    contact: "",
    customerType: "",
  },
  ewb: "",
  idUrl: "",
  billUrl: "",
  billFetch: false,
  newBranches: [],
  bookRoutes: [],
  docketUrls: [],
  creditNumber: "",
  docketsFetch: false,
  resetBooking: false,
  opBranch: {
    address: {
      city: "",
    },
  },
  orgBranch: {
    label: "",
    value: "",
  },
  colValue: 0,
  colReport,
  delAmt: "",
  specificDelivery: false,
  idFetch: false,
  handlingChargeSlab: [],
  deliveryChargesSlab: [],
  builtyChargeSlab: [],
  multipleEwayBillData: [],
  senderRecieverEwaySet: { from: "", ind: null },
  multipleEwayBillCount: 0,
  sizes: [],
  receiverGstInvalid: false,
  senderGstInvalid: false,
  ewayBillLoading: false,
  gstLoading: false,
  gstLoadingReceiver: false,
  receiverSuggestions: [],
  eWayBills: [],
  ewayGoods: [],
  ewayBillNo: [],
  bookingTime: new Date(),
  bookTouches: {
    purpose: false,
    bookroute: false,
    bookdestbranch: false,
    sender: {
      gst: false,
      name: false,
      contact: false,
      l1: false,
      l2: false,
      pin: false,
      city: false,
      contactVerified: false,
      senderCode: false,
      gstRemoveReqData: false,
    },
    receiver: {
      gst: false,
      name: false,
      contact: false,
      l1: false,
      l2: false,
      pin: false,
      city: false,
      contactVerified: false,
      receiverCode: false,
      gstRemoveReqData: false,
    },
  },
  bookErrors: {
    gst: false,
    sender: false,
    name: false,
    receiver: false,
    purpose: false,
    bookroute: false,
  },
  orangeLR: null,
  bookingMode: {
    label: "ToPay",
    value: "topay",
  },
  nonCashPaymentType: "",
  nonCashTxnId: "",
  nonCashTxnImage: "",
  nonCashTxnsNumber: "",
  bookingCredit: initCredit,
  senderAddressLock: false,
  receiverAddressLock: false,
  focOTP: "",
  focContact: "",
  isFocOTPSent: false,
  senderOTP: "",
  isSenderNew: false,
  isSenderOTPSent: false,
  isSenderVerificationDialog: false,
  isResendDisabled: false,
  senderType: "",
  employeeContact: "",
  //isEmployeeContactValidate:false,
  isEmployeeVerificationRequired: true,
  employeeOTP: "",
  isEmployeeOTPSent: false,
  isEmployeeVerificationDialog: false,
  isEmployeeNew: false,
  isEmployeeFetched: false,
  employeeName: "",
  individualFleet: {
    name: {
      fName: "",
      lName: "",
    },
    regNumber: "",
    otp: 0,
  },
  doorDelivery: {
    deliveryCharge: 0,
    origin: {},
    l1: "",
    l2: "",
    officeName: "",
    floor: "",
    tower: "",
    nearby_landmark: "",
    doorDeliveryPaymentMode: "",
    pincode: "",
    city: "",
    area: "",
    state: "",
    shouldDoorDeliveryBook: false,
    serviceType: "",
    assetType: "",
    delCity: "",
  },
  senderForceNameRemoveDueToGst: false,
  receiverForceNameRemoveDueToGst: false,
  senderGstRelativeFieldDisable: false,
  receiverGstRelativeFieldDisable: false,

  senderGstRemoveReqData: {
    dialog: false,
    step: 1,
    gstData: [],
    newGst: "",
    removedGst: "",
    primaryContact: "",
  },
  receiverGstRemoveReqData: {
    dialog: false,
    step: 1,
    gstData: [],
    newGst: "",
    removedGst: "",
    primaryContact: "",
  },

  senderSecondaryContactReqData: {
    dialog: false,
    step: 1,
    secondaryContactData: [],
    newContact: "",
    removedContact: "",
    primaryContact: "",
  },
  receiverSecondaryContactReqData: {
    dialog: false,
    step: 1,
    secondaryContactData: [],
    newContact: "",
    removedContact: "",
    primaryContact: "",
  },
  isEwayBillDetailsFromApi : true,
  senderGstContacts: [],
  receiverGstContacts: [],
  bookCities: {
    _id: "",
    name: "",
  },
  generalWarning: "",
  bill: "",
};

export function debounce(func: any, wait: number, immediate?: boolean): any {
  let timeout: any;
  return function (this: any) {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    bookBill: (state, action) => {
      state.bill = action.payload;
    },
    // resetState : {
    //   reducer : (_, action : PayloadAction<{state: any, data: any}>) => {
    //     const {state,data} = action.payload;
    //     const exclude = data ? data.exclude : {};
    //     exclude.filbranches = 1; // Ensuring `filbranches` is always retained

    //     const retain: any = {};
    //     Object.keys(exclude).forEach((key) => (retain[key] = state[key]));

    //     Object.assign(state, {
    //       searchBookingEntity: null,
    //       purpose: null,
    //       bookErrors: {},
    //       resetBooking: true,
    //       ...retain, // Restore the excluded properties
    //     });

    //   },
    //   prepare: (state: any, data: any) => ({ payload: {
    //     state,data
    //   } }),
    // },
    bookChangeDocket: (state, action) => {
      state.searchBookingEntity = action.payload;
      // const data = optimizedReset(state, { exclude: { docketNumber: 1 } })

      const data = { exclude: { docketNumber: 1 }}
      const exclude : any = data ? data.exclude : {};
      exclude.filbranches = 1;
      const retain: any = {};
      Object.keys(exclude).forEach((key) => (retain[key] = (state as any)[key]));
      const data1 = {
        searchBookingEntity: null,
        purpose: null,
        bookErrors: {},
        resetBooking: true,
        ...retain,
      };
      Object.assign(state,data1)
    },
    setLastBookingDetails: (state, action) => {
      console.log(`jlzdnvgzdfv`, action.payload);
      state.lastBookingDetail = { ...action.payload };
    },
    setAcrossData: (
      state,
      action: PayloadAction<BookingInitialType["across"]>
    ) => {
      if (typeof action.payload !== "string") return state;
      const { bookingUpdate } = state;
      state.across = action.payload;
      state.bookingUpdate = {
        ...bookingUpdate,
        packages: true,
      };
    },
    ValidationError: {
      reducer: (
        state,
        action: PayloadAction<{
          path: string[];
          message: string | boolean;
          field?: keyof BookErrorType;
        }>
      ) => {
        if (action.payload?.field) {
          state.bookErrors[action.payload.field] = action.payload.message;
        }
      },
      prepare: (path: string[], message: string | boolean) => ({
        payload: {
          path,
          message,
        },
      }),
    },
    bookDestBranch: (state, action) => {
      const { value } = action.payload;
      const { filbranches } = state;
      const branch = filbranches.find((b: any) => b._id === value);
      const receiverAddress = branch.address;
      if (!branch || !branch.address || !branch.address.city) return state;

      const receiver = {
        ...state.receiver,
      };
      state.receiver = receiver;
      state.receiverBranchAddress = receiverAddress;
      (state.bookdestbranch = action.payload),
        (state.bookdestcity = {
          label: branch.address.city.name,
          value: branch.address.city._id,
        });
      state.bookTouches.bookdestbranch = true;
      (state.receiverAddressLock = true),
        (state.bookingUpdate = {
          ...state.bookingUpdate,
          destCity: true,
        });
    },
    bookEWB: (state, action) => {
      state.ewb = action.payload;
    },
    showEwaybillLoader: (state) => {
      state.ewayBillLoading = true;
    },
    hideEwaybillLoader: (state) => {
      state.ewayBillLoading = false;
    },
    setSenderGstContacts: (state, action) => {
      state.senderGstContacts = action.payload;
    },
    setReceiverGstContacts: (state, action) => {
      state.receiverGstContacts = action.payload;
    },
    setDocketFromEwaybill: (state, action) => {
      const data = Object.assign({}, action.payload);

      const newSender = omit(state.sender, ["contact"]);

      const sender: any = {
        ...newSender,
        name: toRSOption(data.legal_name_of_consignor),
        gst: toRSOption(data.gstin_of_consignor),
        ewayBillSenderId: data?.sender?._id,
        ewayBillSenderName: data?.sender?.name,
        // ewayBillSenderContact : data?.sender?.contact,
        ewayBillAddress: {
          l1: data?.address1_of_consignor,
          l2: data?.address2_of_consignor,
          city: data?.place_of_consignor,
          pincode: data?.pincode_of_consignor,
          state: data?.state_of_consignor,
        },
      };
      const newReceiver = omit(state.receiver, ["contact"]);
      const receiver: any = {
        ...newReceiver,
        name: toRSOption(data.legal_name_of_consignee),
        gst: toRSOption(data.gstin_of_consignee),
        ewayBillReceiverId: data?.reciever?._id,
        ewayBillReceiverName: data?.reciever?.name,
        // ewayBillReceiverContact : data?.reciever?.contact,
        ewayBillAddress: {
          l1: data?.address1_of_consignee,
          l2: data?.address2_of_consignee,
          city: data?.place_of_consignee,
          pincode: data?.pincode_of_consignee,
          state: data?.state_of_supply,
        },
      };
      const bill = data.document_number;
      const goods = data.total_invoice_value;
      state.sender = sender;
      state.receiver = receiver;
      state.bill = bill;
      state.goods = goods;
      state.isEwayBill = true;
    },
    resetEwayBillData: (state) => {
      const sender: any = {
        ...initialState.sender,
      };
      const receiver: any = {
        ...initialState.receiver,
      };
      const bill = "";
      const goods = initialState.goods;
      state.sender = sender;
      state.receiver = receiver;
      state.bill = bill;
      state.goods = goods;
      state.isEwayBill = false;
    },
    handleValidationError: {
      reducer: (
        state,
        action: PayloadAction<{ path: string[]; message: string | boolean | undefined }>
      ) => {
        const { path, message } = action.payload;
        let oldMessage = get(state.bookErrors, path);
        console.log(oldMessage,"oldMessage")
        if (!oldMessage?.message) {
          set(state, ["bookErrors",...path], message);
          // set(state,["step_2Error",...path,"message"], message);
        }
        // return state;
      },
      prepare: (path: string[], message: string | boolean | undefined) => ({
        payload: {
          path,
          message,
        },
      }),
    },
    changeDeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setFedexServiceType: (state, action) => {
      state.fedexServiceType = action.payload;
    },
    creditNumberChangeData: (state, action) => {
      const { bookingUpdate } = state;
      state.creditNumber = action.payload;
      bookingUpdate.payment = true;
    },
    bookCreditReset: (state) => {
      state.bookingCredit = initCredit;
    },
    creditNumberHideLoader: (state) => {
      return {
        ...state,
        creditNumberLoading: false,
      };
    },
    creditNumberShowLoader: (state) => {
      return {
        ...state,
        creditNumberLoading: true,
      };
    },
    bookCreditSet: (state, action) => {
      const {
        fromBranch_permissions,
        toBranch_permissions,
        fleet_permissions,
      } = action.payload.data;
      const { bookingUpdate, bookdestbranch } = state;
      const { loginType, opBranch, opFleet } = action.payload.user;
      let creditPermission = false;
      if (loginType == "B") {
        if (fromBranch_permissions.length === 0) {
          creditPermission = toBranch_permissions.find(
            (b: any) => b.branch._id === bookdestbranch.value
          )
            ? true
            : false;
        } else if (toBranch_permissions.length === 0) {
          creditPermission = fromBranch_permissions.find(
            (b: any) => b.branch._id === opBranch._id
          )
            ? true
            : false;
        } else {
          creditPermission =
            fromBranch_permissions.find(
              (b: any) => b.branch._id === opBranch._id
            ) &&
            toBranch_permissions.find(
              (b: any) => b.branch._id === bookdestbranch.value
            )
              ? true
              : false;
        }
      } else {
        creditPermission = fleet_permissions.find(
          (b: any) => b.fleet == opFleet._id
        )
          ? true
          : false;
      }
      state.bookingCredit = {
        ...action.payload.data,
        doesEntityHasCreditPermission: creditPermission,
      };
      bookingUpdate.payment = true;
    },
    setParticularEWB: (state, action) => {
      state.multipleEwayBillData = action.payload;
    },
    setMultipleEwayBillData: (state, action) => {
      if (
        state.sender.name?.value !== "" &&
        state?.sender?.gst?.value !== "" &&
        state?.receiver?.name?.value !== "" &&
        state?.receiver?.gst?.value !== "" &&
        action?.payload?.srData?.arr?.gstin_of_consignor !==
          state?.sender?.gst?.value &&
        action?.payload?.srData?.arr?.gstin_of_consignee !==
          state?.receiver?.gst?.value
      ) {
        return state;
      }
      if (action?.payload?.srData && action?.payload?.srData?.check) {
        const data = Object.assign({}, action?.payload?.srData?.arr);
        const sender: any = {
          ...state.sender,
          name: toRSOption(data.legal_name_of_consignor),
          gst: toRSOption(data.gstin_of_consignor),
          ewayBillSenderId: data?.sender?._id,
          ewayBillSenderName: data?.sender?.name,
          ewayBillSenderContact: data?.sender?.contact,
        };
        const receiver: any = {
          ...state.receiver,
          name: toRSOption(data.legal_name_of_consignee),
          gst: toRSOption(data.gstin_of_consignee),
          ewayBillReceiverId: data?.reciever?._id,
          ewayBillReceiverName: data?.reciever?.name,
          ewayBillReceiverContact: data?.reciever?.contact,
        };
        state.multipleEwayBillData = action?.payload;
        state.sender = sender;
        state.receiver = receiver;
        state.senderRecieverEwaySet = {
          from: "additionalEwayBills",
          ind: action?.payload?.srData?.indexValue,
        };
      } else {
        state.multipleEwayBillData = action?.payload;
        state.senderRecieverEwaySet = { from: "", ind: null };
      }
    },
    changeMultipleEwayBillManualData: (state, action) => {
      state.multipleEwayBillData = action?.payload;
    },
    addMultipleEwayBill: (state, action) => {
      state.multipleEwayBillData = [
        ...state?.multipleEwayBillData,
        {
          index: action.payload,
          ewb: "",
          bill: "",
          goods: "",
          ins: "",
          col: "",
        },
      ];
      state.multipleEwayBillCount = state?.multipleEwayBillCount + 1;
    },
    removeMultipleEwayBill: (state, action) => {
      if (
        action?.payload === state?.senderRecieverEwaySet?.ind &&
        state?.senderRecieverEwaySet?.from === "additionalEwayBills"
      ) {
        state.multipleEwayBillData = state?.multipleEwayBillData?.filter(
          (x: any) => x?.index !== action.payload
        );
        state.multipleEwayBillCount = state?.multipleEwayBillCount - 1;
        state.sender = {
          name: undefined,
          contact: "",
          gst: undefined,
          l1: "",
          l2: "",
          pin: "",
          city: "",
          otherAddr: [],
          gstNos: undefined,
          names: undefined,
          senderCode: "",
          ewayBillSenderId: "",
          ewayBillSenderName: "",
          ewayBillSenderContact: "",
          isSenderDisable: false,
          contactVerified: false,
        };
        state.receiver = {
          name: undefined,
          contact: "",
          gst: undefined,
          l1: "",
          l2: "",
          pin: "",
          city: "",
          otherAddr: [],
          gstNos: undefined,
          names: undefined,
          receiverCode: "",
          ewayBillReceiverId: "",
          ewayBillReceiverName: "",
          ewayBillReceiverContact: "",
          isReceiverDisable: false,
        };
      } else {
        state.multipleEwayBillData = state?.multipleEwayBillData?.filter(
          (x: any) => x?.index !== action.payload
        );
        state.multipleEwayBillCount = state?.multipleEwayBillCount - 1;
      }
    },
    resetMultipleEwayBill: (state) => {
      state.multipleEwayBillData = [];
      state.multipleEwayBillCount = 0;
    },
    bookingMode: (state, action) => {
      const { bookingUpdate } = state;
      state.bookingMode = action.payload;
      (state.creditNumber = ""),
        (state.bookingCredit = initCredit),
        (state.bookingUpdate = { ...bookingUpdate, payment: true });
    },
    nonCashPaymentTypeChange: (state, action) => {
      state.nonCashPaymentType = action.payload;
    },
    nonCashTxnIDChange: (state, action) => {
      state.nonCashTxnId = action.payload;
    },
    nonCashTxnImageChange: (state, action) => {
      state.nonCashTxnImage = action.payload;
    },
    nonCashTxnsNumberChange: (state, action) => {
      state.nonCashTxnsNumber = action.payload;
    },
    setOnlinePaymentStatus: (state, action) => {
      state.onlinePaymentStatus = action.payload;
    },
    bookPack: (state, action) => {
      try {
        const val = action.payload;
        let charge = 0;
        (state as any)?.company.packaging.forEach((slab: any) => {
          if (slab.name == val) {
            charge = slab.price;
          }
        });
        state.customPack = action.payload;
        state.packValue = charge;
      } catch (err: any) {
        return state;
      }
    },
    bookingHowCollect: {
      reducer: (
        state,
        action: PayloadAction<{ data: string; companyData: any }>
      ) => {
        try {
          if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
          const newCol = parseInt(String(state.col), 10);
          const arr = action.payload.companyData[action.payload.data];

          let colCharge = 0;
          if (arr && arr.length > 0) {
            arr.forEach((ele: any) => {
              if (newCol >= ele.min && newCol <= ele.max) {
                colCharge = ele["charge"];
              }
            });
          } else if (action.payload.data == "") {
            state.howcollect = action.payload.data;
            state.colValue = 0;
            state.col = 0;
          }
          state.howcollect = action.payload.data;
          state.colValue = colCharge;
        } catch (err: any) {
          if (err == "GOODS_NULL") {
            // showMessage("Insert goods value first", failed);
          }
          return state;
        }
      },
      prepare: (data: string, companyData: any) => {
        return {
          payload: {
            data,
            companyData,
          },
        };
      },
    },
    bookingRiskChange: {
      reducer: (
        state,
        action: PayloadAction<{ risk: 0 | 1 | 2; companyData: any }>
      ) => {
        try {
          if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
          const flag = action.payload.risk;
          let arr = [];
          if (flag == 0) {
            return { ...state, risk: action.payload.risk, valueCharge: 0 };
          }
          if (flag == 1) {
            arr = action.payload.companyData.insurance.owner;
          }
          if (flag == 2) {
            arr = action.payload.companyData.insurance.carrier;
          }

          const newIns = parseInt(String(state.ins), 10);
          let insCharge = 0;
          arr.forEach((ele: any) => {
            if (newIns >= ele.min && newIns <= ele.max) {
              insCharge = ele["charge"];
            }
          });
          state.ins = newIns;
          state.risk = newIns;
          state.valueCharge = insCharge;
        } catch (err: any) {
          if (err == "GREATER") {
          }
          // showMessage(
          //   "Insurance base value must be less than goods value",
          //   failed
          // );
          else if (err == "GOODS_NULL")
            // showMessage("Set goods value first", failed);
            return state;
        }
      },
      prepare: (risk: 0 | 1 | 2, companyData: any) => {
        return {
          payload: {
            risk,
            companyData,
          },
        };
      },
    },
    bookingGoodsChange: (state, action) => {
      try {
        let insCharge = 0;

        const val = parseInt(action.payload, 10);
        //calculate insurance
        if (state.risk != 0) {
          let arr = [];
          if (state.risk == 1) arr = (state as any).company.insurance.owner;
          if (state.risk == 2) arr = (state as any).company.insurance.carrier;
          arr.forEach((ele: any) => {
            if (val >= ele.min && val <= ele.max) {
              insCharge = ele["charge"];
            }
          });
        }
        let colCharge = 0;
        if (state.isCol) {
          let arr = [];
          if (state.howcollect == "cod") {
            arr = (state as any).company.cod;
          } else if (state.howcollect == "col") {
            arr = (state as any).company.col;
          }

          if (state.howcollect == "cod" || state.howcollect == "col") {
            arr.forEach((ele: any) => {
              if (val >= ele.min && val <= ele.max) {
                colCharge = ele[state.across];
              }
            });
          } else colCharge = 0;
          if (
            (colCharge == null || colCharge == 0) &&
            (state.howcollect == "cod" || state.howcollect == "col")
          ) {
            colCharge = arr[arr.length - 1].charge;
          }
        }
        state.goods = action.payload;
        state.ins = action.payload;
        state.col = action.payload;
        state.valueCharge = insCharge;
        state.colValue = colCharge;
      } catch (err: any) {
        return state;
      }
    },
    bookingValueChargeChange: (state, action) => {
      state.valueCharge = action.payload;
    },
    bookingColChange: (state, action) => {
      try {
        if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
        let newCol = parseInt(action.payload, 10);
        let goods = parseInt(state.goods, 10);
        if (newCol > goods) throw "GREATER";
        let arr = (state as any).company[state.howcollect];
        if (!arr || arr.length === 0) throw "INVALID CHOICE";
        let colCharge = 0;
        arr.forEach((ele: any) => {
          if (newCol >= ele.min && newCol <= ele.max) {
            colCharge = ele["charge"];
          }
        });
        state.col = newCol;
        state.colValue = colCharge;
      } catch (err: any) {
        if (err == "GREATER") {
        }
        // showMessage("Collection value must be less than goods value", failed);
        else if (err == "GOODS_NULL")
          // showMessage("Set goods value first", failed);
          return state;
      }
    },
    addsize: (
      state,
      action: PayloadAction<{
        name: string | number;
        l: string | number;
        b: string | number;
        h: string | number;
        unit: string | SelectValueType;
        weight: number;
        _id: number;
      }>
    ) => {
      // const {
      //   sizes,
      // } = action.payload;
      const sizes = state.sizes.slice();
      sizes.push(action.payload);
      state.sizes = sizes;
      state.bookingUpdate.sizes = true;
    },

    delPackage: (state, action: PayloadAction<{ id: number }>) => {
      try {
        const { id } = action.payload,
          packages = state.packages
            .filter((p: any) => p.id != id)
            .map((p: any, idx: number) => ({ ...p, id: idx }));
        const { Route } = state.bookService;
        const hf = countNewHandlingCharges(
          packages,
          state.handlingChargeSlab,
          (Route.length > 1 ? Route.length - 1 : 1) * 2
        );
        const bf = countBuiltyCharges(
          packages,
          state.builtyChargeSlab,
          state.across
        );
        state.packages = packages;
        state.hf = hf;
        state.bf = bf;
        state.bookingUpdate.packages = true;
        state.bookingUpdate.payment = true;
      } catch (err: any) {
        return state;
      }
    },
    bookAddPackage: (state, action) => {
      const packages = state.packages.slice();
      packages.push(action.payload);
      state.packages = packages;
      state.bookingUpdate.packages = true;
    },
    clearPackageSuggestions: (state) => {
      state.packageSuggestions = null;
    },
    packageUpdateAck: (state) => {
      state.bookingUpdate = { ...state.bookingUpdate, packages: false };
    },
    touchSender: (state, action: any) => {
      // Set the sender contact id in the bookTouches object
      console.log(`mnasvn zdjsfnv`, action);
      state.bookTouches.sender = {
        ...state.bookTouches.sender,
        [action.payload]: true,
      };
    },
    touchReceiver: (state, action: any) => {
      // Set the sender contact id in the bookTouches object
      console.log(`mnasvn zdjsfnv`, action);
      state.bookTouches.receiver = {
        ...state.bookTouches.receiver,
        [action.payload]: true,
      };
    },
    bookingRefresh: (state) => {
      try {
        let freight = state.fr;
        const { Route } = state.bookService;
        const hf = countNewHandlingCharges(
          state.packages,
          state.handlingChargeSlab,
          (Route.length > 1 ? Route.length - 1 : 1) * 2
        );

        let bf = 0;
        freight = parseInt(String(freight), 10);
        bf = countBuiltyCharges(
          state.packages,
          state.builtyChargeSlab,
          state.across
        );

        return {
          ...state,
          fr: freight,
          hf: hf,
          bf: bf,
          gross: freight + hf + bf,
        };
      } catch (err: any) {
        switch (err) {
          case "AcrossError": {
            showMessage("Select Destination city first", failed);
            break;
          }
          default: {
            showMessage("Something went wrong", failed);
          }
        }
        return state;
      }
    },
    editPackage: {
      reducer: (
        state,
        action: PayloadAction<{
          id: number;
          name:
            | "stack"
            | "frag"
            | "haz"
            | "size"
            | "mat"
            | "pack"
            | "qty"
            | "l"
            | "b"
            | "h"
            | "u"
            | "w"
            | "rate"
            | "amount"
            | "fixRate"
            | "standardRate"
            | "standardRateAmount"
            | "status";
          val?: any;
          cb?: (data: any) => void;
        }>
      ) => {
        try {
          const { bookingUpdate } = state;
          let grossFr = state.grossFr;
          let packages = [];
          const { id, name } = action.payload;
          switch (name) {
            case "stack": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.stack = !p.stack;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "frag": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.frag = !p.frag;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "haz": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.haz = !p.haz;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "size": {
              const newSize = action.payload.val.value;
              state.packages.forEach((p: any) => {
                const units = [
                  { label: "Mt", value: "m" },
                  { label: "Cm", value: "cm" },
                  { label: "Inch", value: "in" },
                ];
                if (p.id == id) {
                  const temp: any = Object.assign({}, p);
                  temp.size = action.payload.val;
                  if (action.payload.val != "") {
                    const { sizes } = state;
                    sizes.forEach((size: any) => {
                      if (size.name == newSize) {
                        const { l, b, h } = size;
                        temp.dimension = { l, b, h };
                        temp.weight = size.weight;
                        units.map((u) => {
                          if (u.value == size.unit) {
                            temp.unit = u;
                          }
                        });
                      }
                    });
                  }
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "mat": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.materialType = action.payload.val;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "pack": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.packingType = action.payload.val;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "qty": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.qty = action.payload.val;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "l": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p),
                    dimension = Object.assign({}, temp.dimension);
                  dimension.l = action.payload.val;
                  temp.dimension = dimension;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "b": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p),
                    dimension = Object.assign({}, temp.dimension);
                  dimension.b = action.payload.val;
                  temp.dimension = dimension;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "h": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p),
                    dimension = Object.assign({}, temp.dimension);
                  dimension.h = action.payload.val;
                  temp.dimension = dimension;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "u": {
              const { val, id } = action.payload;
              packages = state.packages.map((item: any) =>
                item.id == id ? { ...item, unit: val } : item
              );
              break;
            }
            case "w": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp: any = Object.assign({}, p);
                  temp.weight = parseInt(action.payload.val, 10);
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "rate": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.rate = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "amount": {
              const { val } = action.payload,
                amt = val;
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  resPackages.push({ ...p, amount: isNaN(amt) ? "" : amt });
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "status": {
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.status = !p.status;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "fixRate": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.fixRate = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "standardRateAmount": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.standardRateAmount = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "standardRate": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.standardRate = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
          }
          // let transportCharges=new Map()
          let totalWeight = state.totalWeight;
          if (
            name == "qty" ||
            name == "w" ||
            name == "amount" ||
            name == "standardRate" ||
            name == "rate"
          ) {
            grossFr = 0;
            totalWeight = 0;
            let totalQty = 0;
            let minimumChargeAbleWeight = 0;
            packages.forEach((p: any) => {
              let qty = parseInt(p.qty, 10);
              let tWeight = parseInt(p.weight, 10);
              totalWeight += qty * tWeight;
              totalQty += qty;
            });
            packages = packages.map((p: any) => {
              let qty = parseInt(p.qty, 10);
              let tRate = p.rate ? p.rate.value : "Direct";
              let tWeight = parseInt(p.weight, 10);
              let tAmount = p.amount;
              let amount = 0;
              if (!isNaN(qty) && tRate == "Per package") {
                if (p?.standardRate?.length) {
                  p?.standardRate?.map((standardRate: any) => {
                    if (
                      standardRate.serviceSubType.minimumChargeAbleWeight >
                      totalWeight
                    ) {
                      if (
                        standardRate.serviceSubType.minimumChargeAbleWeight >
                        minimumChargeAbleWeight
                      ) {
                        minimumChargeAbleWeight =
                          standardRate.serviceSubType.minimumChargeAbleWeight;
                      }
                    } else {
                      let volumeDiscount =
                        standardRate?.volumeDiscount?.length > 0
                          ? standardRate?.volumeDiscount?.find(
                              (volumeDis: any) =>
                                volumeDis.minimumWeight <= totalWeight &&
                                volumeDis.maximumWeight >= totalWeight
                            )
                          : null;
                      amount +=
                        standardRate.price *
                        tWeight *
                        (volumeDiscount
                          ? (100 - volumeDiscount.discount) / 100
                          : 1);
                      let transportCharge = isNaN(
                        qty * standardRate.price * tWeight
                      )
                        ? 0
                        : qty *
                          standardRate.price *
                          tWeight *
                          (volumeDiscount
                            ? (100 - volumeDiscount.discount) / 100
                            : 1);
                      grossFr += transportCharge;
                    }
                  });
                } else {
                  grossFr += isNaN(qty * tAmount) ? 0 : qty * tAmount;
                }
              } else if (!isNaN(qty) && !isNaN(tWeight) && tRate == "Per Kg") {
                // let price=0
                if (p?.standardRate?.length) {
                  p?.standardRate?.map((standardRate: any) => {
                    if (
                      standardRate.serviceSubType.minimumChargeAbleWeight >
                      totalWeight
                    ) {
                      if (
                        standardRate.serviceSubType.minimumChargeAbleWeight >
                        minimumChargeAbleWeight
                      ) {
                        minimumChargeAbleWeight =
                          standardRate.serviceSubType.minimumChargeAbleWeight;
                      }
                    } else {
                      let volumeDiscount =
                        standardRate?.volumeDiscount?.length > 0
                          ? standardRate?.volumeDiscount?.find(
                              (volumeDis: any) =>
                                volumeDis.minimumWeight <= totalWeight &&
                                volumeDis.maximumWeight >= totalWeight
                            )
                          : null;
                      amount +=
                        standardRate.price *
                        (volumeDiscount
                          ? (100 - volumeDiscount.discount) / 100
                          : 1);
                      let transportCharge = isNaN(
                        qty * standardRate.price * tWeight
                      )
                        ? 0
                        : qty *
                          standardRate.price *
                          tWeight *
                          (volumeDiscount
                            ? (100 - volumeDiscount.discount) / 100
                            : 1);

                      grossFr += transportCharge;
                    }
                  });
                } else {
                  grossFr += isNaN(qty * tAmount * tWeight)
                    ? 0
                    : qty * tAmount * tWeight;
                }
              }
              return {
                ...p,
                amount: p.standardRate?.length > 0 ? amount : p.amount,
              };
            });
            if (minimumChargeAbleWeight > totalWeight) {
              // transportCharges=new Map()
              let newGrossFr = 0;
              let standardRate: any = [];
              packages.map((pkg: any) => {
                if (pkg.standardRate?.length) {
                  standardRate = pkg.standardRate;
                }
              });
              // packages.forEach((pkg:any)=>{
              standardRate?.map((standardRate: any) => {
                let weight = minimumChargeAbleWeight;
                let transportCharge = isNaN(standardRate.price * weight)
                  ? 0
                  : standardRate.price * weight;
                newGrossFr += transportCharge;
              });
              // })
              if (standardRate.length) {
                packages = packages.map((pkg: any) => {
                  let newAmount =
                    newGrossFr /
                    (totalQty * (pkg.rate.value == "Per Kg" ? pkg.weight : 1));
                  return { ...pkg, amount: newAmount.toFixed(1) };
                });

                if (newGrossFr > 0) {
                  grossFr = newGrossFr;
                }
              }
            }
          }

          if (!state.packages || state.packages.length == 0) return state;
          let freight = state.fr;
          const { Route } = state.bookService;
          const hf = countNewHandlingCharges(
            packages,
            state.handlingChargeSlab,
            (Route.length > 1 ? Route.length - 1 : 1) * 2
          );

          const bf = countBuiltyCharges(
            packages,
            state.builtyChargeSlab,
            state.across
          );
          freight = parseInt(String(freight), 10);

          const insObj: any = {};
          if (name === "qty") {
            if (
              (state as any).valueChargeEnabled &&
              (state as any).insuredCompanies &&
              (state as any).insuredCompanies.includes(
                (state as any).company.id
              ) &&
              (state as any).bookdestbranch &&
              (state as any).insuredCompanies.includes(
                (state as any).bookdestbranch.company._id
              )
            ) {
              insObj.insMinValue = Math.max(
                (state as any).minValueCharge,
                (state as any).perPackageValueCharge *
                  packages.reduce((pv: any, cv: any) => pv + Number(cv.qty), 0)
              );
              if (Number(state.risk) === 0) {
                insObj.risk = "1";
              }
            }
          }

          return {
            ...state,
            ...insObj,
            fr: freight,
            totalWeight,
            grossFr,
            hf: hf,
            bf: bf,
            gross: freight + hf + bf,
            packages,
            bookingUpdate: {
              ...bookingUpdate,
              packages: true,
              payment: true,
            },
            // transportCharges:Array.from(transportCharges.values())
          };
        } catch (err: any) {
          if (err instanceof BookingError) {
            showMessage(err.message, failed);
          }
          return state;
        }
      },
      prepare: (
        data: {
          id: number;
          name:
            | "stack"
            | "frag"
            | "haz"
            | "size"
            | "mat"
            | "pack"
            | "qty"
            | "l"
            | "b"
            | "h"
            | "u"
            | "w"
            | "rate"
            | "amount"
            | "fixRate"
            | "standardRate"
            | "standardRateAmount"
            | "status";
          val?: any;
        },
        cb?: (data: any) => void
      ) => {
        if (typeof cb == "function") {
          setTimeout(() => {
            cb(data);
          }, 100);
        }
        return {
          payload: {
            ...data,
            cb,
          },
        };
      },
    },
    bookResetSender: (state) => {
      state.sender = initialState.sender;
    },
    bookSender: (
      state,
      action: PayloadAction<{ what: SenderTouchedType; val?: any }>
    ) => {
      const { receiver } = state;
      const sender = Object.assign({}, state.sender);
      const { what, val } = action.payload;
      sender[what] = val;
      let senderGst = false;
      let senderForceNameRemoveDueToGst = false;
      if (
        what === "contact" &&
        receiver &&
        receiver.contact &&
        receiver.contact === val
      ) {
        toast.error("Sender Contact Cannot Be same as Receiver Contact");
        return state;
      }
      if (what === "gst") {
        const { gstNos } = sender;
        if (gstNos && isArrayCheck(gstNos) && val && val.value) {
          const gstConcern = gstNos.find((g: any) => g.GSTIN === val.value);
          const checkIsGstNameExists = sender?.names?.find(
            (name: any) =>
              gstConcern?.name?.toUpperCase() === name?.toUpperCase()
          );
          senderForceNameRemoveDueToGst =
            sender?.names?.length >= 3 && !checkIsGstNameExists;

          if (gstConcern) {
            sender.name = {
              label: gstConcern.name,
              value: gstConcern.name,
            };
            senderGst = true;
          }
        } else if (val !== undefined) {
          senderGst = true;
        } else if (val === undefined) {
          senderGst = false;
        }
        if (val && val.value) {
          sender[what] = {
            label: val.label.toUpperCase(),
            value: val.value.toUpperCase(),
          };
        } else {
          sender[what] = undefined; // this field change with null --> undefined
        }
      }
      state.sender = sender;
      state.bookTouches = set(state.bookTouches, ["sender", what], true);
      state.senderGst = senderGst;
      state.senderForceNameRemoveDueToGst = senderForceNameRemoveDueToGst;
      if (senderForceNameRemoveDueToGst) {
        state.senderGstRelativeFieldDisable = true;
      } else {
        state.senderGstRelativeFieldDisable = false; // this field change with {} --> false
      }
    },
    setSenderSecondaryContactReqData: (state, action) => {
      state.senderSecondaryContactReqData = action.payload;
    },
    setGeneralWarning: (state, action) => {
      state.generalWarning = action.payload;
    },
    setSenderGstRemoveReqData: (state, action) => {
      state.senderGstRemoveReqData = action.payload;
    },
    setSenderByGst: (state, action) => {
      const {
        trade_name,
        senderForceNameRemoveDueToGst,
        senderGstRelativeFieldDisable,
      } = action.payload;

      state.sender.name = toRSOption(trade_name);
      state.bookTouches = set(state.bookTouches, ["sender", "name"], true);
      state.senderForceNameRemoveDueToGst = senderForceNameRemoveDueToGst;
      state.senderGstRelativeFieldDisable = senderGstRelativeFieldDisable;
      state.senderGstInvalid = false;
      state.senderGst = true;
    },
    senderGstInvalid: (state) => {
      state.senderGstInvalid = true;
      state.sender.name = undefined;
    },
    bookSetSenderVerification: (
      state,
      action: PayloadAction<{ what: senderVerificationType | ""; val: any }>
    ) => {
      const { what, val } = action.payload;

      if (what) {
        state[what] = val;
        return {
          ...state,
          [what]: val,
        };
      } else {
        (state.senderOTP = ""), (state.isSenderNew = false);
        state.isSenderOTPSent = false;
        state.isSenderVerificationDialog = false;
        (state.isContactValidated = false), (state.contactVerified = false);
      }
    },
    setSenderForceNameRemoveDueToGstData: (state, action) => {
      state.senderForceNameRemoveDueToGst = action.payload;
    },
    bookSetSender: (state, action) => {
      const {
          _id,
          address: serverAddress,
          gst,
          otherAddr,
          name,
          gstNos,
          contact,
          names,
          contactVerified,
          image,
          isContactValidated,
          customerType,
          connectedContacts,
        } = action.payload,
        { pincode } = serverAddress;
      const address = { ...serverAddress, pin: pincode };
      let newOthr: any[] = [],
        senderData: any;
      if (isArrayCheck(otherAddr) && address) {
        newOthr = otherAddr.map((a: any) => ({ ...a, pin: a.pincode }));
      }

      const onlyPrimaryGST =
        customerType === "P" ? gstNos : connectedContacts?.gstNos;
      newOthr.push(address);
      if (state.sender.gst) {
        senderData = {
          contact: contact,
          otherAddr: newOthr,
          image,
          ...address,
        };
      } else {
        senderData = {
          contact: contact,
          gst,
          otherAddr: newOthr,
          image,
          ...address,
        };
      }

      if (!state.isEwayBill) {
        senderData.name = { value: name, label: name };
      }
      // if (state.sender.name != "") delete senderData.name;
      const senderPayload = {
        _id,
        ...state.sender,
        ...senderData,
        gstNos: isArrayCheck(onlyPrimaryGST)
          ? onlyPrimaryGST.filter(
              (g: any) =>
                g.GSTIN && g.GSTIN.length > 0 && g.name && g.name.length > 0
            )
          : [],
        names: names,
        contactVerified: contactVerified ? contactVerified : false,
        isContactValidated: isContactValidated ? isContactValidated : false,
        customerType,
        connectedContacts,
      };
      state.sender = senderPayload;
    },
    resetSenderName: (state) => {
      state.sender.name = undefined;
      state.senderGst = false;
    },
    setReceiverSuggetions: (state, action) => {
      state.receiverSuggestions = action.payload;
    },
    docketsFetchAck: (state) => {
      state.docketsFetch = false;
    },
    setRateSuggetions: (state, action) => {
      state.rateSuggestions = action.payload;
    },
    bookSetIsSenderDisable: (state, action) => {
      state.sender.isSenderDisable = action.payload;
    },
    bookResetReceiver: (state) => {
      const receiverPayload = {
        ...state.receiver,
        isReceiverDisable: false,
        name: undefined,
        contact: "",
        gst: undefined,
        contactVerified: false,
        isContactValidated: false,
      };
      state.receiver = receiverPayload;
    },
    bookReceiver: (
      state,
      action: PayloadAction<{ what: ReceiverTouchedType; val?: any }>
    ) => {
      const { sender } = state;
      const receiver = Object.assign({}, state.receiver);
      let receiverGst = false;
      const { what, val } = action.payload;
      receiver[what] = val;
      if (
        what === "contact" &&
        sender &&
        sender.contact &&
        sender.contact === Number(val)
      ) {
        toast.error("Sender Contact Cannot Be same as Receiver Contact");
        return state;
      }

      let receiverForceNameRemoveDueToGst = false;
      if (what === "gst") {
        if (val && val.value) {
          const { gstNos } = receiver;
          if (gstNos && isArrayCheck(gstNos) && val && val.value) {
            const gstConcern = gstNos.find((g: any) => g.GSTIN == val.value);
            const checkIsGstNameExists = receiver?.names?.find(
              (name: any) =>
                gstConcern?.name?.toUpperCase() === name?.toUpperCase()
            );
            receiverForceNameRemoveDueToGst =
              receiver?.names?.length >= 3 && !checkIsGstNameExists;
            if (gstConcern) {
              receiver.name = {
                label: gstConcern.name,
                value: gstConcern.name,
              };
              receiverGst = true;
            }
          } else if (val !== undefined) {
            receiverGst = true;
          } else if (val === undefined) {
            receiverGst = false;
          }

          receiver[what] = {
            label: val.label.toUpperCase(),
            value: val.value.toUpperCase(),
          };
        } else {
          receiver[what] = undefined;
        }
      }
      state.receiver = receiver;
      state.bookTouches = set(state.bookTouches, ["receiver", what], true);
      state.receiverGst = receiverGst;
      state.receiverForceNameRemoveDueToGst = receiverForceNameRemoveDueToGst;
      if (receiverForceNameRemoveDueToGst) {
        state.receiverGstRelativeFieldDisable = true;
      } else {
        state.receiverGstRelativeFieldDisable = false;
      }
    },
    setReceiverByGst: (state, action) => {
      const {
        trade_name,
        // address1: l1,
        // address2: l2,
        // pincode,
        receiverForceNameRemoveDueToGst,
        receiverGstRelativeFieldDisable,
      } = action.payload;
      state.receiver.name = toRSOption(trade_name);
      state.bookTouches = set(state.bookTouches, ["receiver", "name"], true);
      state.receiverGstInvalid = false;
      state.receiverForceNameRemoveDueToGst = receiverForceNameRemoveDueToGst;
      state.receiverGstRelativeFieldDisable = receiverGstRelativeFieldDisable;
      state.receiverGst = true;
    },
    receiverGstInvalid: (state) => {
      state.receiverGstInvalid = true;
      state.receiver.name = undefined;
    },
    resetParticularEwayBillData: (state, action) => {
      state.multipleEwayBillData = action.payload;
    },
    bookSetSenderValidate: (state, action) => {
      const { isContactValidated, message } = action.payload;
      if (message) {
        showMessage(message, success);
      }
      state.sender.isContactValidated = isContactValidated;
    },
    bookSetReceiverValidate: (state, action) => {
      const { isContactValidated, message } = action.payload;
      if (message) {
        showMessage(message, success);
      }
      state.receiver.isContactValidated = isContactValidated;
    },
    setReceiverSecondaryContactReqData: (state, action) => {
      state.receiverSecondaryContactReqData = action.payload;
    },
    setReceiverForceNameRemoveDueToGst: (state, action) => {
      state.receiverForceNameRemoveDueToGst = action.payload;
    },
    setReceiverGstRemoveReqData: (state, action) => {
      state.receiverGstRemoveReqData = action.payload;
    },
    setFedxServiceOptions: (state, action) => {
      state.fedexServiceTypeOptions = action.payload;
    },
    bookChangeFedexServiceType: (state, action) => {
      state.fedexServiceType = action.payload;
    },
    bookSetReceiver: (state, action) => {
      const {
          _id,
          address: serverAddress,
          gst,
          otherAddr,
          name,
          gstNos,
          contact,
          names,
          contactVerified,
          isContactValidated,
          customerType,
          connectedContacts,
        } = action.payload,
        { pincode } = serverAddress;

      const address = { ...serverAddress, pin: pincode };
      let newOthr: any[] = [],
        receiverData: any;
      if (isArrayCheck(otherAddr) && address) {
        newOthr = otherAddr.map((a: any) => ({ ...a, pin: a.pincode }));
      }
      newOthr.push(address);
      if (state.receiver.gst) {
        receiverData = {
          otherAddr: newOthr,
          contact: contact,
        };
      } else {
        receiverData = {
          gst,
          otherAddr: newOthr,
          contact: contact,
        };
      }

      if (!state.isEwayBill) {
        receiverData.name = { value: name, label: name };
      }
      // if (state.receiver.name != "") delete receiverData.name;

      const onlyPrimaryGST =
        customerType === "P" ? gstNos : connectedContacts?.gstNos;
      const receiverPayload = {
        _id,
        ...state.receiver,
        ...receiverData,
        gstNos: onlyPrimaryGST,
        names: names,
        isContactValidated: isContactValidated ? isContactValidated : false,
        contactVerified,
      };
      state.receiver = receiverPayload;
    },
    resetReceiverName: (state) => {
      const receiver = {
        ...state.receiver,
        name: undefined,
        names: [],
      };
      state.receiver = receiver;
      state.receiverGst = false;
    },
    bookSetIsReceiverDisable: (state, action) => {
      state.receiver.isReceiverDisable = action.payload;
    },
    setMaterial: (state, action) => {
      state.material = action.payload;
      state.bookingUpdate.packages = true;
    },
    setPackingType: (state, action) => {
      state.packingType = action.payload;
      state.bookingUpdate.packages = true;
    },
    paymentUpdateAck: (state) => {
      state.bookingUpdate.payment = false;
      state.resetBooking = false;
    },
    idFetchAck: (state) => {
      state.idFetch = false;
    },
    billFetchAck: (state) => {
      state.billFetch = false;
    },
    resetSenderAddress: (state) => {
      const senderPayload = {
        ...state.sender,
        sender: {
          ...state.sender,
          l1: "",
          l2: "",
          pin: "",
          city: "",
        },
      };
      state.sender = senderPayload;
      state.senderAddressLock = false;
    },
    resetReceiverAddress: (state) => {
      const receiverPayload = {
        ...state.receiver,
        receiver: {
          ...state.receiver,
          l1: "",
          l2: "",
          pin: "",
          city: "",
        },
      };
      state.receiver = receiverPayload;
      state.receiverAddressLock = false;
    },
    setSenderAddressFromBranch: (state) => {
      if (!state.opBranch || !state.opBranch.address) return state;
      const { address } = state.opBranch;
      let city: any = "";
      if (state.allcities) {
        city = state.allcities.find((c: any) => c._id == address.city);
        if (city) {
          city = city.name;
        }
      }
      const sender = {
        ...state.sender,
      };
      state.sender = sender;
      state.senderAddressLock = true;
    },
    setReceiverAddressFromBranch: (state) => {
      const receiver = {
        ...state.receiver,
      };
      state.receiver = receiver;
      state.receiverAddressLock = true;
    },
    setSenderAddress: (state) => {
      const sender = state.sender;
      let newSender = {
        ...sender,
      };
      state.sender = newSender;
    },
    setReceiverAddress: (state) => {
      // const _address = action.payload;
      const receiver = state.receiver;
      let newReceiver = {
        ...receiver,
      };
      state.receiver = newReceiver;
    },
    setPackageErrorFlagFalse: (state) => {
      if (state.resetPackageErrors === true) {
        state.resetPackageErrors = false;
      } else {
        return state;
      }
    },
    bookingRemarksChange: (state, action) => {
      state.remarks = action.payload;
    },
    resetRemarks: (state) => {
      state.remarks = "";
    },
    setFetchDocketPkgData: (state, action) => {
      const val = action.payload;
      let prevArr = [...state.fetchDocketUpdate];
      let arr = prevArr.includes(val) ? prevArr : [val, ...prevArr];
      state.fetchDocketUpdate = [...arr];
    },
    setRateId: (state, action) => {
      state.rateId = action.payload;
    },
    setDoorDeliveryData: (state, action) => {
      state.doorDelivery = action.payload;
    },
    isEwayBillDetailsFromApi: (state,action) => {
      state.isEwayBillDetailsFromApi = action.payload
    },
    resetDoorDelivery: (state) => {
      const doorDeliveryPayload = {
        ...state.doorDelivery,
        deliveryCharge: 0,
        origin: {},
        l1: "",
        l2: "",
        officeName: "",
        floor: "",
        tower: "",
        nearby_landmark: "",
        doorDeliveryPaymentMode: "",
        pincode: "",
        city: "",
        area: "",
        state: "",
        shouldDoorDeliveryBook: false,
        serviceType: "",
      };
      state.doorDelivery = doorDeliveryPayload;
    },
    setFixRateCustomer: (state, action) => {
      const { name, contact, customerType } = action.payload;
      state.fixRateCustomer.name = name;
      state.fixRateCustomer.contact = contact;
      state.fixRateCustomer.customerType = customerType;
    },
    resetFixRateCustomer: (state) => {
      state.fixRateCustomer = initialState.fixRateCustomer;
    },
    setRoutesData: (state, action) => {
      try {
        const { franchise, own } = action.payload;
        const routes = franchise
          .map((r: any) => ({ ...r, isFranchise: true }))
          .concat(own);
        state.bookRoutes = routes;
      } catch (err: any) {
        return state;
      }
    },
    setSettingsData: (state, action) => {
      const {
        valueChargeEnabled,
        minValueCharge,
        perPackageValueCharge,
        insuredCompanies,
        // handlingChargeSlab,
        deliveryChargesSlab,
        builtyChargeSlab,
        sizes,
      } = action.payload;
      state.valueChargeEnabled = valueChargeEnabled;
      state.minValueCharge = minValueCharge;
      state.perPackageValueCharge = perPackageValueCharge;
      state.insuredCompanies = insuredCompanies;
      // handlingChargeSlab,
      state.deliveryChargesSlab = deliveryChargesSlab;
      state.builtyChargeSlab = builtyChargeSlab;
      state.sizes = sizes;
    },
    pickUpChargeChange: {
      reducer: (
        state,
        action: PayloadAction<{
          what: "pickupCharge" | "deliveryCharge";
          val: string;
        }>
      ) => {
        const { what, val } = action.payload;
        return {
          ...state,
          [what]: val,
        };
      },
      prepare: (what: "pickupCharge" | "deliveryCharge", val: string) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setFocVerification: {
      reducer: (state, action: PayloadAction<{ what: any; val: any }>) => {
        const { what, val } = action.payload;
        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return {
            ...state,
            focOTP: "",
            focContact: "",
            isFocOTPSent: false,
          };
        }
      },
      prepare: (what: any, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setSenderVerification: {
      reducer: (state, action: PayloadAction<{ what: any; val: any }>) => {
        const { what, val } = action.payload;
        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return {
            ...state,
            senderOTP: "",
            isSenderNew: false,
            isSenderOTPSent: false,
            isSenderVerificationDialog: false,
            isContactValidated: false,
            contactVerified: false,
          };
        }
      },
      prepare: (what: any, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setBookVerification: {
      reducer: (state, action: PayloadAction<{ what: any; val: any }>) => {
        const { what, val } = action.payload;
        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return state;
        }
      },
      prepare: (what: any, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setEmployeeVerification: {
      reducer: (state, action: PayloadAction<{ what: any; val: any }>) => {
        const { what, val } = action.payload;
        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return state;
        }
      },
      prepare: (what: any, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setDoorDelivery: {
      reducer: (state, action: PayloadAction<{ what: any; val: any }>) => {
        const { what, val } = action.payload;
        return {
          ...state,
          doorDelivery: {
            ...state.doorDelivery,
            [what]: val,
          },
        };
      },
      prepare: (what: any, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setIndividualFleet: {
      reducer: (state, action: PayloadAction<{ what: string; val: any }>) => {
        const { what, val } = action.payload;
        return {
          ...state,
          individualFleet: {
            ...state.individualFleet,
            [what]: val,
          },
        };
      },
      prepare: (what: string, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    setLR: (state, action) => {
      state.LR = action.payload;
    },
    setOrangeLR: (state, action) => {
      state.orangeLR = action.payload;
    },
    updateBuilty: (state) => {
      state.updateBuilty = true;
    },
    bookDestService: (state, action) => {
      const packages = state.packages;
      const { Route } = action.payload;
      let hf = 0;
      if (packages.length) {
        hf = countNewHandlingCharges(
          packages,
          state.handlingChargeSlab,
          (Route.length > 1 ? Route.length - 1 : 1) * 2
        );
      }
      state.hf = hf;
      state.bookService = action.payload;
      state.bookTouches = set(state.bookTouches, ["bookService"], true);
    },
    colMonthChange: (state, action) => {
      state.colMonth = parseInt(action.payload, 10);
    },
    setDocketData: (state, action) => {
      const {
        docket,
        packages,
        billUrl,
        idUrl,
        fedexLabels,
        docketUrls,
        delivery,
        consigneeIdImage,
        podImage,
        allcities,
        imageGallery,
      } = action.payload;
      // for older fedex bookings wihtout dest branch
      if (!docket.destBranch) {
        docket.destBranch = docket.originBranch;
      }
      const {
        purpose,
        originBranch,
        eWayBill,
        billNo,
        paymentMode,
        senderName,
        receiverName,
        senderGst: senderGST,
        receiverGst: receiverGST,
        franchiseNumber,
        createdAt,
        // remark,
        docketNumber,
        remarks,
        // cancelReason,
        trackingNumber,
        destBranch,
        gross,
        ewayGoods,
        ewayBillNo,
        eWayBills,
        intermediateCharge,
      } = docket;
      const purposes: {
        [k: string]: { label: string; value: string };
      } = {
        P: { label: "Personal", value: "P" },
        C: { label: "Commercial", value: "C" },
      };

      const sender: any = {
          name: toRSOption(senderName),
          contact: docket.sender.contact,
          gst: toRSOption(senderGST),
          gstNos: docket.sender.gstNos,
          names: docket.sender.names,
        },
        receiver: any = {
          name: toRSOption(receiverName),
          contact: docket.receiver.contact,
          gst: toRSOption(receiverGST),
          gstNos: docket.receiver.gstNos,
          names: docket.receiver.names,
        };
      // orgBranch,
      // findCity,
      // originCity,
      // remarks = docket.remarks,
      let senderGst = false,
        receiverGst = false;
      if (docket.sender.gst && docket.purpose != "P") {
        senderGst = true;
      }
      if (docket.receiver.gst && docket.purpose != "P") {
        receiverGst = true;
      }
      const orgBranch = {
        label: originBranch.branchName,
        value: originBranch._id,
      };
      const findCity = allcities.find(
        (c: any) => c._id == originBranch.address.city._id
      );
      const originCity = { label: findCity.name, value: findCity.name };

      const findDestCity = allcities.find(
        (c: any) => c._id == destBranch.address.city._id
      );

      const destCity = { label: findDestCity.name, value: findDestCity._id };

      const uniquePackageMap: any = {};
      packages.forEach((p: any) => {
        const {
          materialType,
          packingType,
          size,
          dimension,
          weight,
          rate,
          amount,
          unit,
          stack,
          haz,
          frag,
          status,
          extraGrossFreight,
        } = p;

        const { l, b, h } = dimension;
        const uid = [
          materialType && materialType.name ? materialType.name : "N/A",
          materialType && materialType._id ? materialType._id : "N/A",
          packingType && packingType.name ? packingType.name : "N/A",
          packingType && packingType._id ? packingType._id : "N/A",
          size,
          l,
          b,
          h,
          unit,
          weight,
          rate,
          amount + (extraGrossFreight || 0),
          stack,
          haz,
          frag,
          status,
        ].join("@");
        if (uid in uniquePackageMap) {
          uniquePackageMap[uid] += 1;
        } else {
          uniquePackageMap[uid] = 1;
        }
      });
      const tempPackages = Object.keys(uniquePackageMap).map((uid, idx) => {
        const [
          materialType_name,
          materialType_id,
          packingType_name,
          packingType_id,
          size,
          l,
          b,
          h,
          unit,
          weight,
          rate,
          amount,
          stack,
          haz,
          frag,
          status,
        ] = uid.split("@");

        const qty = uniquePackageMap[uid];
        const freightRates: {
          [k: string]: { label: string; value: string };
        } = {
          Direct: { label: "Direct", value: "Direct" },
          "Per package": { label: "Per package", value: "Per package" },
          "Per Kg": { label: "Per Kg", value: "Per Kg" },
        };
        const units: {
          [k: string]: { label: string; value: string };
        } = {
          m: { label: "Mt", value: "m" },
          cm: { label: "Cm", value: "cm" },
          in: { label: "Inch", value: "in" },
        };
        const sizeArr = state.sizes,
          sizeMap: any = {};
        if (isArrayCheck(sizeArr)) {
          sizeArr.forEach((s: { name: string }) => {
            sizeMap[s.name] = { label: s.name, value: s.name };
          });
        }
        return {
          qty,
          materialType: {
            label: materialType_name,
            value: materialType_id,
          },
          packingType: {
            label: packingType_name,
            value: packingType_id,
          },
          size: sizeMap[size],
          dimension: { l, b, h },
          weight,
          rate: freightRates[rate],
          amount: parseInt(amount), //rate == 'Per package' ? parseInt(amount) : parseInt(amount, 10) * parseInt(qty, 10),
          unit: units[unit],
          stack: stack == "true",
          haz: haz == "true",
          frag: frag == "true",
          id: idx,
          status: status,
        };
      });
      const paymentModeMap: {
        [k: string]: { label: string; value: string };
      } = {
        paid: { label: "Paid", value: "paid" },
        topay: { label: "ToPay", value: "topay" },
        credit: { label: "Credit", value: "credit" },
        foc: { label: "FOC", value: "foc" },
        noncash: { label: "Non Cash", value: "noncash" },
      };

      const orangeLR = docket.isOrangeBooking
        ? docket.orangeDetails.lrNumber
        : null;
      const otherValues = {
        hf: docket.handling,
        bf: docket.bilty,
        dc: docket.Delivery,
        orangeLR,
        senderGst,
        receiverGst,
        pickupCharge: docket.pickupCharge,
        deliveryCharge: docket.deliveryCharge,
        fr: docket.freight,
        risk: docket.risk,
        ins: docket.insAmt,
        valueCharge: docket.valueCharge,
        isCol: docket.colEnabled,
        howcollect: docket.collectionType,
        col: docket.colAmt,
        colValue: docket.colFee,
        packValue: docket.packFee,
        colMonth: docket.colMonth,
        customPack: docket.customPack,
        // bookingRate: docket.freightType,
        bookingRate: docket.freightType,
        bookingMode: !delivery ? paymentModeMap[paymentMode] : paymentMode,
        goods: docket.goods,
        purpose: purposes[purpose],
        docketStatus: docket.status,
        franchiseNumber,
        remark: docket.remarks,
        specificDelivery: docket.delSpecific,
        isEwayBill: eWayBill && eWayBill.length > 0,
        bookingTime: createdAt,
        fromAddr: docket.originBranch ? docket.originBranch.branchName : "",
        toAddr: docket.destBranch ? docket.destBranch.branchName : "",
        gross,
        intermediateCharge,
        ewayGoods: ewayGoods,
        ewayBillNo: ewayBillNo,
        eWayBills: eWayBills,
        imageGalleryArr: imageGallery,
      };
      // const bookdestcity = allcities
      // ? docket.isFedexBooking
      //   ? allcities.filter((c: any) => c._id == docket.destBranch.address.city._id)[0]
      //   : allcities.filter((c: any) => c._id == packageSample.destCity)[0]
      // : null;
      const bookdestbranch = docket.destBranch;
      const actDestBranch = docket.actDestBranch;

      const delDocket = {
        docket: {
          ...docket,
          paymentMode: paymentModeMap[docket.paymentMode],
          consigneeIdImage,
          podImage,
        },
        packages,
      };
      const payloadData = {
        ...state,
        docketNumber,
        trackingNumber,
        fedexLabels,
        sender,
        receiver,
        bill: billNo,
        bookingStatus: docket.status,
        ewb: eWayBill,
        packages: tempPackages,
        oldPackages: tempPackages,
        fetchDocketUpdate: [],
        delDocket,
        editDocket: true,
        idUrl,
        orgBranch,
        remarks,
        originCity,
        idFetch: typeof idUrl === "string" && idUrl.length > 0 ? true : false,
        billUrl,
        billFetch:
          typeof billUrl === "string" && billUrl.length > 0 ? true : false,
        docketUrls,
        docketsFetch:
          Array.isArray(docketUrls) && docketUrls.length > 0 ? true : false,
        bookingUpdate: {
          ...state.bookingUpdate,
          payment: true,
          packages: true,
          destCity: true,
        },
        bookdestcity: destCity,
        bookdestbranch: bookdestbranch
          ? { label: bookdestbranch.branchName, value: bookdestbranch._id }
          : null,
        actDestBranch: actDestBranch
          ? { label: actDestBranch.branchName, value: actDestBranch._id }
          : null,
        ...otherValues,
      };
      Object.assign(state, payloadData);
    },
    resetEdit: (state, action) => {
      const exclude = action?.payload ? action?.payload?.exclude : {};

      exclude.filbranches = 1;
      const retain: any = {};
      if (exclude) {
        Object.keys(exclude).forEach((key) => (retain[key] = (state as any)[key]));
      }
      const bookingUpdate = {
        packages: true,
        destCity: true,
        payment: true,
      };
      const fixRateCustomer = {
        name: "",
        contact: "",
        customerType: "",
      };
      const sender = {
          name: "",
          contact: "",
          gst: "",
          l1: "",
          l2: "",
          pin: "",
          city: "",
          senderCode: "",
          isSenderDisable: false,
          isContactValidated: false,
        },
        receiver = {
          name: "",
          contact: "",
          gst: "",
          l1: "",
          l2: "",
          pin: "",
          city: "",
          receiverCode: "",
          isReceiverDisable: false,
          isContactValidated: false,
        };
      const otherValues: any = {
        LR: "",
        orangeLR: "",
        hf: "",
        dc: "",
        docketNumber: "",
        bf: "",
        fr: "",
        grossFr: 0,
        totalWeight: 0,
        risk: "0",
        ins: 0,
        valueCharge: 0,
        insMinValue: 0,
        isCol: false,
        howcollect: "",
        pickupCharge: 0,
        deliveryCharge: 0,
        col: 0,
        colValue: 0,
        packValue: 0,
        colMonth: 0,
        customPack: "null",
        bookingRate: 0,
        bookingMode: null,
        nonCashPaymentType: "",
        nonCashTxnId: "",
        nonCashTxnImage: "",
        nonCashTxnsNumber: "",
        onlinePaymentStatus: "",
        goods: "",
        docketStatus: false,
        bill: "",
        purpose: { label: "Commercial", value: "C" },
        bookingStatus: true,
        franchiseNumber: null,
        remark: "",
        pickupContact: "",
        creditNumber: "",
        bookingCredit: initCredit,
        focOTP: "",
        focContact: "",
        isFocOTPSent: false,
        senderOTP: "",
        isSenderNew: false,
        isSenderOTPSent: false,
        isSenderVerificationDialog: false,
        isResendDisabled: false,
        isEmployeeVerificationRequired: true,
        employeeOTP: "",
        isEmployeeOTPSent: "",
        isEmployeeVerificationDialog: false,
        employeeContact: "",
        employeeName: "",
        isEmployeeNew: false,
        isEmployeeFetched: false,
        //isEmployeeContactValidate:false,
        senderType: "",
        employeeImage: null,
        senderPhoto: null,
        individualImage: null,
        individualFleet: initialState.individualFleet,
        gross: 0,
        intermediateCharge: 0,
        ewayGoods: [],
        ewayBillNo: [],
        eWayBills: [],
        imageGalleryArr: [],
        doorDelivery: {
          deliveryCharge: 0,
          origin: {},
          l1: "",
          l2: "",
          officeName: "",
          floor: "",
          tower: "",
          nearby_landmark: "",
          doorDeliveryPaymentMode: "",
          pincode: "",
          city: {},
          area: "",
          state: "",
          shouldDoorDeliveryBook: false,
          doorDelDocketNumber: "",
          serviceType: "",
          delCity: {},
        },
      };
      if (state.loginType == "B" && state.opBranch && state.opBranch.address) {
        const { address } = state.opBranch;
        let city: any = "";
        if (state.allcities) {
          city = state.allcities.find((c: any) => c._id == address.city);
          if (city) {
            city = city.name;
          }
        }
        const sender = {
          name: null,
          gst: null,
          contact: "",
        };
        otherValues.sender = sender;
        otherValues.senderAddressLock = true;
      }
      return {
        ...state,
        orangeLR: null,
        resetPackageErrors: true,
        receiverGstInvalid: false,
        senderGstInvalid: false,
        senderForceNameRemoveDueToGst: false,
        receiverForceNameRemoveDueToGst: false,
        senderGstRelativeFieldDisable: false,
        receiverGstRelativeFieldDisable: false,
        ewb: "",
        sender,
        receiver,
        fixRateCustomer,
        bookingUpdate,
        editDocket: false,
        isEwayBill: false,
        deliveryType: {
          value: "Branch To Branch",
          label: "Branch To Branch",
        },
        bookdestcity: "",
        bookdestbranch: "",
        actDestBranch: "",
        receiverBranchAddress: "",
        receiverGst: false,
        senderGst: false,
        bookService: { label: "", value: "", Route: [] },
        bookErrors: {},
        bookTouches: {},
        specificDelivery: false,
        delDocket: {
          docket: {
            status: true,
          },
        },
        packages: [
          {
            id: 0,
            qty: "",
            materialType: "",
            packingType: "",
            size: "custom",
            dimension: { l: "", b: "", h: "" },
            weight: "",
            rate: "Direct",
            unit: "",
            amount: "",
            stack: false,
            haz: false,
            frag: false,
            status: "",
          },
        ],
        fetchDocketUpdate: [],
        ...otherValues,
        ...retain,
        resetBooking: true,
        bookingTime: null,
        remarks: "",
        contactData: null,
        senderGstRemoveReqData: {
          dialog: false,
          step: 1,
          gstData: [],
          removedGst: "",
          newGst: "",
          primaryContact: "",
        },
        receiverGstRemoveReqData: {
          dialog: false,
          step: 1,
          gstData: [],
          removedGst: "",
          newGst: "",
          primaryContact: "",
        },
        senderSecondaryContactReqData: {
          dialog: false,
          step: 1,
          secondaryContactData: [],
          newContact: "",
          removedContact: "",
          primaryContact: "",
        },
        receiverSecondaryContactReqData: {
          dialog: false,
          step: 1,
          secondaryContactData: [],
          newContact: "",
          removedContact: "",
          primaryContact: "",
        },
        senderGstContacts: [],
        receiverGstContacts: [],
        bookingToken: `${
          action?.payload?.loggedInUser?.opBranch?.shortName
        }-${new Date().getTime()}`,
      };
    },
    bookDocket: (state, action) => {
      state.searchBookingEntity = action.payload;
    },
    bookingEditPackage: (state, action) => {
      try {
        if (!state.across || !state.deliveryType) {
          throw new BookingError("Select Proper Destination first", "across");
        }
        if (
          state?.deliveryType?.value == "Branch To Branch" &&
          (!state.bookdestcity || !state.bookdestcity.value)
        ) {
          throw new BookingError(
            "Select Proper Destination first",
            "bookdestcity"
          );
        }
        if (
          state.deliveryType.value == "Home Delivery" &&
          (!state.receiver.pin || String(state.receiver.pin).length !== 6)
        ) {
          throw new BookingError(
            "Select Proper Destination first",
            "receiverPincode"
          );
        }
        const { bookingUpdate } = state;
        let grossFr = state.grossFr;
        let packages = [];
        const { id, name } = action.payload;
        switch (name) {
          case "stack": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.stack = !p.stack;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "frag": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.frag = !p.frag;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "haz": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.haz = !p.haz;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "size": {
            const newSize = action.payload.val.value;
            state.packages.forEach((p: any) => {
              console.log("for package : ", p, action.payload);
              const units = [
                { label: "Mt", value: "m" },
                { label: "Cm", value: "cm" },
                { label: "Inch", value: "in" },
              ];
              if (p.id == id) {
                const temp: any = Object.assign({}, p);
                temp.size = action.payload.val;
                if (action.payload.val != "") {
                  const { sizes } = state;
                  sizes.forEach((size: any) => {
                    if (size.name == newSize) {
                      console.log("size matched");
                      const { l, b, h } = size;
                      temp.dimension = { l, b, h };
                      temp.weight = size.weight;
                      units.map((u) => {
                        if (u.value == size.unit) {
                          console.log("u: ", u);
                          temp.unit = u;
                        }
                      });
                    }
                  });
                }
                console.log("temp: ", temp);
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "mat": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.materialType = action.payload.val;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "pack": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.packingType = action.payload.val;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "qty": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.qty = action.payload.val;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "l": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p, action.payload);
              if (p.id == id) {
                const temp = Object.assign({}, p),
                  dimension = Object.assign({}, temp.dimension);
                dimension.l = action.payload.val;
                temp.dimension = dimension;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "b": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p),
                  dimension = Object.assign({}, temp.dimension);
                dimension.b = action.payload.val;
                temp.dimension = dimension;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "h": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p),
                  dimension = Object.assign({}, temp.dimension);
                dimension.h = action.payload.val;
                temp.dimension = dimension;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "u": {
            console.log("\n\ngot to change unit : ", action.payload);
            const { val, id } = action.payload;
            packages = state.packages.map((item: any) =>
              item.id == id ? { ...item, unit: val } : item
            );
            break;
          }
          case "w": {
            state.packages.forEach((p: any) => {
              console.log("for package : ", p);
              if (p.id == id) {
                const temp: any = Object.assign({}, p);
                temp.weight = parseInt(action.payload.val, 10);
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "rate": {
            const resPackages: any[] = [];
            state.packages.forEach((p: any) => {
              console.log("for package : ", p, " condition : ", p.id == id);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.rate = action.payload.val;
                resPackages.push(temp);
              } else resPackages.push(p);
            });
            packages = resPackages;
            break;
          }
          case "amount": {
            const { val } = action.payload,
              amt = val;
            const resPackages: any[] = [];
            state.packages.forEach((p: any) => {
              console.log("for package : ", p, " condition : ", p.id == id);
              if (p.id == id) {
                resPackages.push({ ...p, amount: isNaN(amt) ? "" : amt });
              } else resPackages.push(p);
            });
            packages = resPackages;
            break;
          }
          case "status": {
            state.packages.forEach((p: any) => {
              console.log("for package ans: ", p);
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.status = !p.status;
                packages.push(temp);
              } else packages.push(p);
            });
            break;
          }
          case "fixRate": {
            const resPackages: any[] = [];
            state.packages.forEach((p: any) => {
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.fixRate = action.payload.val;
                resPackages.push(temp);
              } else resPackages.push(p);
            });
            packages = resPackages;
            break;
          }
          case "standardRateAmount": {
            const resPackages: any[] = [];
            state.packages.forEach((p: any) => {
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.standardRateAmount = action.payload.val;
                resPackages.push(temp);
              } else resPackages.push(p);
            });
            packages = resPackages;
            break;
          }
          case "standardRate": {
            const resPackages: any[] = [];
            state.packages.forEach((p: any) => {
              if (p.id == id) {
                const temp = Object.assign({}, p);
                temp.standardRate = action.payload.val;
                resPackages.push(temp);
              } else resPackages.push(p);
            });
            packages = resPackages;
            break;
          }
        }
        // let transportCharges=new Map()
        let totalWeight = state.totalWeight;
        if (
          name == "qty" ||
          name == "w" ||
          name == "amount" ||
          name == "standardRate" ||
          name == "rate"
        ) {
          grossFr = 0;
          totalWeight = 0;
          let totalQty = 0;
          let minimumChargeAbleWeight = 0;
          packages.forEach((p: any) => {
            let qty = parseInt(p.qty, 10);
            let tWeight = parseInt(p.weight, 10);
            totalWeight += qty * tWeight;
            totalQty += qty;
          });
          packages = packages.map((p: any) => {
            let qty = parseInt(p.qty, 10);
            let tRate = p.rate ? p.rate.value : "Direct";
            let tWeight = parseInt(p.weight, 10);
            let tAmount = p.amount;
            let amount = 0;
            if (!isNaN(qty) && tRate == "Per package") {
              if (p?.standardRate?.length) {
                p?.standardRate?.map((standardRate: any) => {
                  if (
                    standardRate.serviceSubType.minimumChargeAbleWeight >
                    totalWeight
                  ) {
                    if (
                      standardRate.serviceSubType.minimumChargeAbleWeight >
                      minimumChargeAbleWeight
                    ) {
                      minimumChargeAbleWeight =
                        standardRate.serviceSubType.minimumChargeAbleWeight;
                    }
                  } else {
                    let volumeDiscount =
                      standardRate?.volumeDiscount?.length > 0
                        ? standardRate?.volumeDiscount?.find(
                            (volumeDis: any) =>
                              volumeDis.minimumWeight <= totalWeight &&
                              volumeDis.maximumWeight >= totalWeight
                          )
                        : null;
                    amount +=
                      standardRate.price *
                      tWeight *
                      (volumeDiscount
                        ? (100 - volumeDiscount.discount) / 100
                        : 1);
                    let transportCharge = isNaN(
                      qty * standardRate.price * tWeight
                    )
                      ? 0
                      : qty *
                        standardRate.price *
                        tWeight *
                        (volumeDiscount
                          ? (100 - volumeDiscount.discount) / 100
                          : 1);
                    //  let oldTransportCharges=transportCharges.get(standardRate.serviceSubType.company)
                    //  transportCharges.set(standardRate.serviceSubType.company,{
                    //   company:standardRate.serviceSubType.company,
                    //   amount:transportCharge+(oldTransportCharges?.amount||0) ,
                    //   price:standardRate.price,
                    //   originBranch:standardRate.originBranch,
                    //   destBranch:standardRate.destBranch,
                    //   qty:totalQty,
                    //   weight:totalWeight,
                    //   route:standardRate.route
                    // })
                    grossFr += transportCharge;
                  }
                });
              } else {
                grossFr += isNaN(qty * tAmount) ? 0 : qty * tAmount;
              }
            } else if (!isNaN(qty) && !isNaN(tWeight) && tRate == "Per Kg") {
              // let price=0
              if (p?.standardRate?.length) {
                p?.standardRate?.map((standardRate: any) => {
                  if (
                    standardRate.serviceSubType.minimumChargeAbleWeight >
                    totalWeight
                  ) {
                    if (
                      standardRate.serviceSubType.minimumChargeAbleWeight >
                      minimumChargeAbleWeight
                    ) {
                      minimumChargeAbleWeight =
                        standardRate.serviceSubType.minimumChargeAbleWeight;
                    }
                  } else {
                    let volumeDiscount =
                      standardRate?.volumeDiscount?.length > 0
                        ? standardRate?.volumeDiscount?.find(
                            (volumeDis: any) =>
                              volumeDis.minimumWeight <= totalWeight &&
                              volumeDis.maximumWeight >= totalWeight
                          )
                        : null;
                    amount +=
                      standardRate.price *
                      (volumeDiscount
                        ? (100 - volumeDiscount.discount) / 100
                        : 1);
                    let transportCharge = isNaN(
                      qty * standardRate.price * tWeight
                    )
                      ? 0
                      : qty *
                        standardRate.price *
                        tWeight *
                        (volumeDiscount
                          ? (100 - volumeDiscount.discount) / 100
                          : 1);
                    //  let oldTransportCharges=transportCharges.get(standardRate.serviceSubType.company)
                    //  transportCharges.set(standardRate.serviceSubType.company,
                    //   {amount:transportCharge+(oldTransportCharges?.amount||0),
                    //     company:standardRate.serviceSubType.company,
                    //     price:standardRate.price,
                    //     originBranch:standardRate.originBranch,
                    //     destBranch:standardRate.destBranch,
                    //     qty:totalQty,
                    //     weight:totalWeight,
                    //     rateType:tRate,
                    //     route:standardRate.route
                    //   })

                    grossFr += transportCharge;
                  }
                });
              } else {
                grossFr += isNaN(qty * tAmount * tWeight)
                  ? 0
                  : qty * tAmount * tWeight;
              }
            }
            // if(typeof p?.fixRate?.deliveryCharges=="number"){
            //   fixDeliveryCharge=p.fixRate.deliveryCharges
            //   fixDeliveryChargeExists=true
            // }
            // if(fixDeliveryCharge>p?.fixRate?.deliveryCharges &&typeof p?.fixRate?.deliveryCharges=="number"){
            //   fixDeliveryCharge=p?.fixRate?.deliveryCharges
            // }
            // if (toBranchData) {
            //   console.log("we are in calculateIntermediateCharge");
            //   intermediateCharges += calculateIntermediateCharge(
            //     p.qty,
            //     p.amount,
            //     p.rate && p.rate.value,
            //     fromBranchdata,
            //     toBranchData
            //   );
            // }
            return {
              ...p,
              amount: p.standardRate?.length > 0 ? amount : p.amount,
            };
          });
          // console.log("companyWiseAmount : ",transportCharges)
          console.log("totalWeight : ", totalWeight);
          if (minimumChargeAbleWeight > totalWeight) {
            // transportCharges=new Map()
            let newGrossFr = 0;
            let standardRate: any = [];
            packages.map((pkg: any) => {
              if (pkg.standardRate?.length) {
                standardRate = pkg.standardRate;
              }
            });
            // packages.forEach((pkg:any)=>{
            standardRate?.map((standardRate: any) => {
              let weight = minimumChargeAbleWeight;
              let transportCharge = isNaN(standardRate.price * weight)
                ? 0
                : standardRate.price * weight;
              // let oldTransportCharges=transportCharges.get(standardRate.serviceSubType.company)
              // transportCharges.set(standardRate.serviceSubType.company,
              //   {amount:transportCharge+(oldTransportCharges?.amount||0) ,
              //   company:standardRate.serviceSubType.company,
              //   price:standardRate.price,
              //   originBranch:standardRate.originBranch,
              //   destBranch:standardRate.destBranch,
              //    qty:totalQty,
              //   weight:totalWeight,
              //   // rateType:tRate,
              //   route:standardRate.route
              // })
              newGrossFr += transportCharge;
            });
            // })
            if (standardRate.length) {
              packages = packages.map((pkg: any) => {
                let newAmount =
                  newGrossFr /
                  (totalQty * (pkg.rate.value == "Per Kg" ? pkg.weight : 1));
                return { ...pkg, amount: newAmount.toFixed(1) };
              });

              if (newGrossFr > 0) {
                grossFr = newGrossFr;
              }
            }
          }
          // console.log("companyWiseAmount : ",transportCharges)
        }

        if (!state.packages || state.packages.length == 0) return state;
        let freight = state.fr;
        // const {Route}=state.bookService
        const hf = countNewHandlingCharges(
          packages,
          state.handlingChargeSlab,
          2 // Route?.length *2
        );

        const bf = countBuiltyCharges(
          packages,
          state.builtyChargeSlab,
          state.across
        );
        freight = parseInt(String(freight), 10);

        const insObj: any = {};
        if (name === "qty") {
          if (
            state.valueChargeEnabled &&
            state.insuredCompanies &&
            state.insuredCompanies.includes(state.company.id) &&
            state.bookdestbranch &&
            state.insuredCompanies.includes(state.bookdestbranch.company._id)
          ) {
            insObj.insMinValue = Math.max(
              state.minValueCharge,
              state.perPackageValueCharge *
                packages.reduce((pv: any, cv: any) => pv + Number(cv.qty), 0)
            );
            if (Number(state.risk) === 0) {
              insObj.risk = "1";
            }
          }
        }

        return {
          ...state,
          ...insObj,
          fr: freight,
          totalWeight,
          grossFr,
          hf: hf,
          bf: bf,
          gross: freight + hf + bf,
          packages,
          bookingUpdate: {
            ...bookingUpdate,
            packages: true,
            payment: true,
          },
          // transportCharges:Array.from(transportCharges.values())
        };
      } catch (err: any) {
        if (err instanceof BookingError) {
          console.log("got the correct one");
          showMessage(err.message, failed);
        }
        console.log(err, " is ", err instanceof BookingError);
        return state;
      }
    },
    bookingContactDataChange: (state, action) => {
      state.contactData = action.payload;
    },
    bookingPickupContactChange: (state, action) => {
      state.pickupContact = action.payload;
    },
    refreshBooking: (state) => {
      state.bookingUpdate.payment = true;
      state.bookingUpdate.packages = true;
      state.bookingUpdate.destCity = true;
      state.senderGstInvalid = false;
      state.senderForceNameRemoveDueToGst = false;
      state.senderGstRelativeFieldDisable = false;
      state.receiverForceNameRemoveDueToGst = false;
      state.receiverGstRelativeFieldDisable = false;
      state.receiverGstInvalid = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setBookBranches.fulfilled, (state, action) => {
      const { payload } = action;

      const cityMap = new Map(
        payload
          .filter((p: any) => p.address && p.address.city)
          .map((p: any) => [p.address.city._id, p.address.city.name])
      );

      const cities: any = Array.from(cityMap, ([id, name]) => ({
        _id: id,
        name,
      }));

      cities.sort((a: any, b: any) =>
        a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
      );

      state.filbranches = payload.filter(
        (br: any) => br && br.operation && br.operation.delivery
      );
      state.bookCities = cities;
      state.bookingUpdate.destCity = true;
    });

    builder.addCase(updateBookingCounters.fulfilled, (state, action) => {
      state.bookingCounters = action.payload;
    });

    builder.addCase(bookPurpose.fulfilled, (state: any, action) => {
      state.purpose = action.payload;
      const newStateData = omit(state.bookErrors, ["purpose"]);
      Object.assign(state, { ...state, ...newStateData });
    });

    builder.addCase(setHandlingChargeSlab.fulfilled, (state, action) => {
      state.handlingChargeSlab = action.payload;
    });

    builder
      .addCase(selectBookService.fulfilled, (state: any, action: any) => {
        const { Route } = action.payload;
        let hf = 0;
        let newState = current(state);
        console.log(
          `kjjajvfnjddfvasdf`,
          action.payload,
          JSON.parse(JSON.stringify(newState)),
          `SvSvsvSdv`,
          newState?.packages,
          `Svsv`,
          newState?.handlingChargeSlab
        );

        if (newState.packages.length) {
          hf = countNewHandlingCharges(
            newState?.packages,
            newState?.handlingChargeSlab,
            (Route?.length > 1 ? Route?.length - 1 : 1) * 2
          );
        }

        console.log(`kjjajvfnjddfvasdf 00.1`, Route, hf);
        state.hf = hf;
        console.log(`kjjajvfnjddfvasdf 00.2`, Route, hf);
        state.bookService = action.payload;
        console.log(`kjjajvfnjddfvasdf 00.3`, Route, hf);
        state.bookTouches.bookService = true;
        const newStateData = omit(state.bookErrors, ["bookService"]);
        Object.assign(state, { ...state, ...newStateData });
      })
      .addCase(selectBookService.rejected, (state: any, action: any) => {
        if (action.payload?.field) {
          state.bookErrors[action.payload.field] = action.payload.message;
        }
      });
    builder
      .addCase(setPackageSuggestions.fulfilled, (state, action) => {
        state.packageSuggestions = action.payload;
      })
      .addCase(setPackageSuggestions.rejected, (state) => {
        state.packageSuggestions = null;
      });
  },
});

export const {
  bookBill,
  bookChangeDocket,
  setLastBookingDetails,
  setAcrossData,
  ValidationError,
  bookDestBranch,
  bookEWB,
  showEwaybillLoader,
  hideEwaybillLoader,
  setReceiverGstContacts,
  setSenderGstContacts,
  setDocketFromEwaybill,
  resetEwayBillData,
  handleValidationError,
  changeDeliveryType,
  setFedexServiceType,
  creditNumberChangeData,
  bookCreditReset,
  creditNumberHideLoader,
  creditNumberShowLoader,
  bookCreditSet,
  setParticularEWB,
  setMultipleEwayBillData,
  changeMultipleEwayBillManualData,
  addMultipleEwayBill,
  removeMultipleEwayBill,
  resetMultipleEwayBill,
  bookingMode,
  nonCashPaymentTypeChange,
  nonCashTxnIDChange,
  nonCashTxnImageChange,
  nonCashTxnsNumberChange,
  setOnlinePaymentStatus,
  bookingHowCollect,
  bookPack,
  bookingRiskChange,
  bookingColChange,
  bookingGoodsChange,
  bookingValueChargeChange,
  addsize,
  delPackage,
  clearPackageSuggestions,
  packageUpdateAck,
  bookAddPackage,
  bookingRefresh,
  editPackage,
  touchSender,
  touchReceiver,
  bookResetSender,
  bookSender,
  setSenderSecondaryContactReqData,
  setGeneralWarning,
  setSenderGstRemoveReqData,
  setSenderByGst,
  senderGstInvalid,
  bookSetSenderVerification,
  setSenderForceNameRemoveDueToGstData,
  bookSetSender,
  resetSenderName,
  setReceiverSuggetions,
  setRateSuggetions,
  bookSetIsSenderDisable,
  bookResetReceiver,
  docketsFetchAck,
  bookReceiver,
  setReceiverByGst,
  receiverGstInvalid,
  resetParticularEwayBillData,
  bookSetReceiverValidate,
  bookSetSenderValidate,
  setReceiverSecondaryContactReqData,
  setReceiverForceNameRemoveDueToGst,
  setReceiverGstRemoveReqData,
  setFedxServiceOptions,
  bookChangeFedexServiceType,
  bookSetReceiver,
  resetReceiverName,
  bookSetIsReceiverDisable,
  setMaterial,
  setPackingType,
  paymentUpdateAck,
  billFetchAck,
  idFetchAck,
  resetReceiverAddress,
  setReceiverAddress,
  setReceiverAddressFromBranch,
  setSenderAddress,
  setSenderAddressFromBranch,
  setPackageErrorFlagFalse,
  resetRemarks,
  resetDoorDelivery,
  resetFixRateCustomer,
  resetSenderAddress,
  setDoorDeliveryData,
  setFetchDocketPkgData,
  setFixRateCustomer,
  setRateId,
  setRoutesData,
  setSettingsData,
  pickUpChargeChange,
  setBookVerification,
  setDoorDelivery,
  setEmployeeVerification,
  setFocVerification,
  setIndividualFleet,
  setLR,
  setSenderVerification,
  setOrangeLR,
  bookDestService,
  updateBuilty,
  colMonthChange,
  setDocketData,
  resetEdit,
  bookDocket,
  bookingEditPackage,
  bookingContactDataChange,
  bookingRemarksChange,
  bookingPickupContactChange,
  refreshBooking,
  isEwayBillDetailsFromApi
} = bookingSlice.actions;

export default bookingSlice.reducer;
