import { createSlice } from "@reduxjs/toolkit";
import { cancelBill, fetchDueCredit, generateBill, getCustomerByContact, getInvoiceDetails, getInvoiceDetailsForCustomer, saveCredit, saveCustomer, settleBill } from "./api";
import { Customer_boolean, validate, validationSchema } from "./helper";

const initCredit = {
    limit: "",
    balance: "",
    company: "",
    customer: "",
    // permissions: [],
    fromBranches: [],
    toBranches: [],
    fleetPermissions: [],
    includeDeliveryCharge: false
};

const initCustomer = {
    _id: "",
    name: "",
    contact: "",
    gst: "",
    connected: [],
    connectedContacts: [],
    referenceUser: null,
    address: {
        l1: "",
        l2: "",
        city: "",
        pincode: "",
    },
    panCardImage: "",
    panCardImageVerified: false,
    adhaarCardImage: "",
    adhaarCardImageVerified: false,
    codeGenerateOTP: "",
    customerVerifyOTP: "",
    adminRemarks: "",
};

const initialState = {
    customer: initCustomer,
    credit: initCredit,
    customerList: [],
    customer_errors: { ...Customer_boolean(true) },
    customer_touched: { ...Customer_boolean(false) },
    originalCreditDueList: [],
    creditDueListUnchanged: [],
    allCredit: [],
    bills: [],
    receipts: [],
    creditDocketMap: new Map(),
    invoiceDetails: [],
    invoiceParty: new Map(),
    invoiceCustomer: {},
    docketRecords: [],
    receiptNo: null,
    printCredit: false,
    newCredit: initCredit,
};

const customerErpSlice = createSlice({
    name: "customerErp",
    initialState,
    reducers: {
        selectAllCreditDockets: (state, action) => {
            const creditList = state.originalCreditDueList.map((row: any) => ({
                ...row,
                selected: action.payload,
            }));

            Object.assign(state, {
                originalCreditDueList: creditList,
            });
        },
        toggleCreditRow: (state, action) => {
            const id = action.payload;
            const creditList = state.originalCreditDueList.map((row: any) =>
                row._id == id ? { ...row, selected: !row.selected } : row
            );

            Object.assign(state, { originalCreditDueList: creditList });
        },
        resetAllPackageAmounts: (state) => {
            Object.assign(state, {
                originalCreditDueList: state.creditDueListUnchanged
            });
        },
        resetPackageAmounts: (state, action) => {
            const docketNumber = action.payload;

            const data = state.creditDueListUnchanged.find(
                (ele: any) => ele._id === docketNumber
            );

            const creditList = state.originalCreditDueList.map((r: any) =>
                r._id === docketNumber ? data : r
            );

            Object.assign(state, { originalCreditDueList: creditList });
        },
        changeEffectivePerPackageAmountOfSelectedDockets: (state, action) => {
            const amount = action.payload;
            if (typeof amount !== "number" || amount < 0) return state;

            const creditList = state.originalCreditDueList.map((row: any) => ({
                ...row,
                packages: row.packages.map((pck: any) => ({
                    ...pck,
                    amount: amount || 0,
                    ...(pck.actAmount ? { actAmount: amount || 0 } : {}),
                })),
            }));

            Object.assign(state, { originalCreditDueList: creditList });
        },
        changePackageAmountBulk: (state, action) => {
            const { docketNumber, amount } = action.payload;
            if (amount < 0) return state;

            const creditList = state.originalCreditDueList.map((row: any) =>
                row._id == docketNumber
                    ? {
                        ...row,
                        packages: row.packages.map((pck: any) => ({
                            ...pck,
                            amount: amount || 0,
                            actAmount: amount || 0,
                        })),
                    }
                    : row
            );

            Object.assign(state, { originalCreditDueList: creditList });
        },
        changePackageAmount: (state, action) => {
            const { docketNumber, packageId, amount } = action.payload;
            if (amount < 0) return state;

            const creditList = state.originalCreditDueList.map((row: any) =>
                row._id == docketNumber
                    ? {
                        ...row,
                        packages: row.packages.map((pck: any) =>
                            packageId === pck._id
                                ? { ...pck, amount: amount || 0 }
                                : pck
                        ),
                    }
                    : row
            );

            Object.assign(state, { originalCreditDueList: creditList });
        },
        changeCreditField: (state: any, action) => {
            const [what, val] = action.payload;

            // Ensure 'credit' object exists in state
            if (!state.credit) {
                state.credit = {};
            }

            // Ensure 'what' is a valid key before updating
            if (typeof what === "string") {
                // Directly mutate the nested field
                state.credit[what] = val;
            }

        },
        setCustomerError: (state, action) => {
            const { what } = action.payload;
            let FinalCheck: any = validationSchema;
            let checkValidation = FinalCheck[what]; // Access as an object property

            Object.assign(state, {
                customer_errors: {
                    ...state.customer_errors,
                    [what]: validate(checkValidation, "")
                },
                customer_touched: {
                    ...state.customer_touched,
                    [what]: true
                }
            });
        },
        changeCustomerField: (state: any, action) => {
            console.log(`Payload received:`, action.payload);

            const { what, val } = action.payload; // Destructuring correctly as an object

            let FinalCheck: any = validationSchema;
            let checkValidation = FinalCheck[what];

            console.log(`Payload received: 0.1`, checkValidation);
            state.customer[what] = val;
            state.customer_errors[what] = validate(checkValidation, val);
            state.customer_touched[what] = true;
        },
        clearCustomer: (state) => {
            Object.assign(state, {
                customer: initCustomer,
                credit: initCredit,
                allCredit: [],
                customer_errors: { ...Customer_boolean(true) },
                customer_touched: { ...Customer_boolean(false) },
            });
        },
        newCreditSubmit: (state) => {
            Object.assign(state, state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
            const { creditRecords, docketRecords, customer } = action.payload;
            const recordsMap = new Map(
                Array.isArray(docketRecords)
                    ? docketRecords.map((r) => [r.docketNumber, r])
                    : []
            );

            Object.assign(state, {
                invoiceDetails: creditRecords,
                invoiceParty: recordsMap,
                invoiceCustomer: customer,
                docketRecords: docketRecords,
            });
        });

        builder.addCase(getInvoiceDetailsForCustomer.fulfilled, (state, action) => {
            const { creditRecords, docketRecords, customer } = action.payload;
            const recordsMap = new Map(
                Array.isArray(docketRecords)
                    ? docketRecords.map((r) => [r.docketNumber, r])
                    : []
            );

            Object.assign(state, {
                invoiceDetails: creditRecords,
                invoiceParty: recordsMap,
                invoiceCustomer: customer,
                docketRecords: docketRecords,
            });
        });
        builder.addCase(fetchDueCredit.fulfilled, (state, action) => {
            const { creditDetails, bills, receipts } = action.payload;

            const creditList = creditDetails.map((row: any) => {
                let fixedAmount = 0;
                let PackagesAmount = row.packages.reduce(
                    (pv: number, cv: any) => pv + (cv.actAmount ? cv.actAmount : cv.amount),
                    0
                );

                fixedAmount = row.amount - PackagesAmount;

                return { ...row, fixed: fixedAmount };
            });

            Object.assign(state, {
                originalCreditDueList: creditList,
                creditDueListUnchanged: creditList,
                bills,
                receipts: receipts.map((r: { receiptNo: any }) => ({
                    value: r.receiptNo,
                    label: r.receiptNo,
                })),
                invoiceDetails: [],
                invoiceParty: new Map(),
                invoiceCustomer: {},
            });
        });
        builder.addCase(getCustomerByContact.fulfilled, (state, action) => {
            console.log(`Payload received: 0.1`, action.payload);
            const { credit, customer, allbranches, allfleets } = action.payload;

            // Process Customer Data
            const customerDoc = Object.assign({}, customer, {
                connected: Array.isArray(customer.connected) && customer.connected.length > 0
                    ? customer.connected.map((c: { contact: any; _id: string }) => ({
                        label: c.contact,
                        value: c._id,
                    }))
                    : [],
                connectedContacts: Array.isArray(customer.connectedContacts)
                    ? customer.connectedContacts.length > 0
                        ? [...customer.connectedContacts]
                        : []
                    : customer.connectedContacts,
            });

            // Process Credit Data - Preserve Initial State If API Doesn't Send Credit
            let newCreditDoc = { ...state.credit }; // Preserve existing credit structure

            if (credit) {
                newCreditDoc = {
                    ...state.credit, // Keep existing values
                    ...credit.credit, // Merge API credit data
                    fromBranches: credit.fromBranch_permissions
                        ? credit.fromBranch_permissions.map((o: any) => {
                            const branch = allbranches.find((b: { _id: string }) => b._id === o.branch._id);
                            return branch ? { label: branch.branchName, value: o.branch._id } : null;
                        }).filter((cp: any) => cp !== null)
                        : state.credit.fromBranches, // Keep old value if missing

                    toBranches: credit.toBranch_permissions
                        ? credit.toBranch_permissions.map((o: any) => {
                            const branch = allbranches.find((b: { _id: string }) => b._id === o.branch._id);
                            return branch ? { label: branch.branchName, value: o.branch._id } : null;
                        }).filter((cp: any) => cp !== null)
                        : state.credit.toBranches,

                    fleetPermissions: credit.fleet_permissions
                        ? credit.fleet_permissions.map((f: any) => {
                            const fleet = allfleets.find((fleet: { _id: string }) => fleet._id === f.fleet);
                            return fleet ? { label: fleet.regNumber, value: f.fleet } : null;
                        }).filter((cp: any) => cp !== null)
                        : state.credit.fleetPermissions,

                    includeDeliveryCharge: credit?.includeDeliveryCharge?.includeDeliveryCharge ?? state.credit.includeDeliveryCharge,
                };
            }

            // Update Redux State Without Overwriting Credit When API Fails
            Object.assign(state, {
                customer: customerDoc,
                credit: newCreditDoc, // Ensure credit structure remains intact
                allCredit: credit?.allCredit || state.allCredit, // Preserve if missing
                customer_errors: { ...Customer_boolean(true) },
                customer_touched: { ...Customer_boolean(false) },
            });
        });

        builder.addCase(saveCustomer.fulfilled, (state) => {
            Object.assign(state, { ...initialState });
        });
        builder.addCase(generateBill.fulfilled, (_, action) => {
            console.log(`Payload received: 0.1`, action.payload);
        });
        builder.addCase(settleBill.fulfilled, (_, action) => {
            console.log(`Payload received: 0.1`, action.payload);
        });
        builder.addCase(cancelBill.fulfilled, (_, action) => {
            console.log(`Payload received: 0.1`, action.payload);
        });
        builder.addCase(saveCredit.fulfilled, (_, action) => {
            console.log(`Payload received: 0.1`, action.payload);
        });
    }
});

export const { selectAllCreditDockets, toggleCreditRow, resetAllPackageAmounts, resetPackageAmounts,
    changeEffectivePerPackageAmountOfSelectedDockets, changePackageAmountBulk, changePackageAmount,
    changeCreditField, setCustomerError, changeCustomerField, clearCustomer, newCreditSubmit
} = customerErpSlice.actions;

export default customerErpSlice.reducer;