export const API_URL : any = {
  production: "https://api.taptap.in",
  // production: "https://singularly-prompt-penguin.ngrok-free.app",
  staging: "http://taptap-lmsdeploy-stag.ap-south-1.elasticbeanstalk.com",
  // staging: "http://taptap-lmsdeploy-stag.ap-south-1.elasticbeanstalk.com",
  // development:"http://192.168.0.5:5000",
  development: "https://api.taptap.in",
  // development: "https://devapi.taptap.in",
  // development: "http://lmsdeploy-devtest1.ap-south-1.elasticbeanstalk.com",
  //  development: "https://api.taptap.in",
  // development: "http://lmsdeploy-devtest1.ap-south-1.elasticbeanstalk.com",
  // development: "http://lmsdeploy-devtest1.ap-south-1.elasticbeanstalk.com",
  // development: "http://localhost:5000", /* Own Backend Server */
  // development:"http://192.168.1.23:5000",  /* Krunal's Backend Server */
  // development:"http://192.168.1.3:5001",   /* Ashish Vyas's Backend Server */
  // development:"http://192.168.1.63:5000",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5002",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5003",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5004",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5000",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5005",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5006",  /* Pankaj Backend Server */
  // development:"http://192.168.1.63:5007",  /* Pankaj Backend Server */
  // development: "https://03c9-122-175-72-70.ngrok-free.app",  /* Subham's Server */
  // development: "http://192.168.1.2:5000",  /* Hinal's Server */
  // development:"http://192.168.1.14:5003",  /* Pankaj Backend Server */
  // development:"http://192.168.1.255:5001",  /* Pankaj Backend Airtel Server */
  // development:"http://localhost:5000",  /* Aditya Backend Server */
  // development: "http://192.168.1.14:5001", /* Jay Backend Server */
  // development:"http://192.168.1.222:5005",  /* Jay Backend Server */
  test: "http://127.0.0.1:3000",
};

export const REACT_APP_VERSION = "1.0.146";
  