import { createAsyncThunk } from "@reduxjs/toolkit";
import { filterBookBranches } from "../../../services/branch";
import {
  SelectValueType,
  SelectValueTypeForBookService,
} from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/types/booking.type";
import showMessage, {
  failed,
  showHttpError,
  success,
} from "../../../utils/message";
import axios from "axios";
import { baseURL } from "../../../axios";
import {
  bookAddPackage,
  bookChangeFedexServiceType,
  bookCreditReset,
  bookCreditSet,
  bookDestBranch,
  bookDocket,
  bookEWB,
  bookingEditPackage,
  bookingRefresh,
  bookReceiver,
  bookResetReceiver,
  bookResetSender,
  bookSender,
  bookSetIsReceiverDisable,
  bookSetIsSenderDisable,
  bookSetReceiver,
  bookSetReceiverValidate,
  bookSetSender,
  bookSetSenderValidate,
  bookSetSenderVerification,
  changeMultipleEwayBillManualData,
  creditNumberChangeData,
  creditNumberHideLoader,
  creditNumberShowLoader,
  debounce,
  handleValidationError,
  hideEwaybillLoader,
  isEwayBillDetailsFromApi,
  receiverGstInvalid,
  resetEdit,
  resetEwayBillData,
  resetParticularEwayBillData,
  resetReceiverName,
  resetSenderName,
  senderGstInvalid,
  setAcrossData,
  setDocketData,
  setDocketFromEwaybill,
  setFedxServiceOptions,
  setGeneralWarning,
  setLastBookingDetails,
  setLR,
  setMaterial,
  setMultipleEwayBillData,
  setOrangeLR,
  setPackingType,
  setParticularEWB,
  setRateSuggetions,
  setReceiverByGst,
  setReceiverForceNameRemoveDueToGst,
  setReceiverGstContacts,
  setReceiverGstRemoveReqData,
  setReceiverSecondaryContactReqData,
  setReceiverSuggetions,
  setRoutesData,
  setSenderByGst,
  setSenderForceNameRemoveDueToGstData,
  setSenderGstContacts,
  setSenderGstRemoveReqData,
  setSenderSecondaryContactReqData,
  setSettingsData,
  showEwaybillLoader,
  ValidationError,
} from "./bookingSlice";
import { acrossFun } from "../../../reducers/utils/helpers";
import { RootState } from "../../store";
import {
  cancelDocketByCustomerService,
  cancelDocketService,
  editDocketService,
  getAcrossService,
  getBillDetails,
  resendSmsService,
} from "../../../services/operations";
import {
  fetchCustomerByContact,
  fetchCustomerByContactGeneralService,
  fetchCustomerByCustomerCodeService,
  getConnectedContactsByGST,
  getConnectedCustomersByContact,
} from "../../../services/customer";
import { listHandlingChargeSlab } from "../../../services/handlingChargeSlab";
import { updateBalance } from "../user/userSlice";
import { getCustomerByGST } from "../../../services/GST";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import {
  bookingMaxGstCount,
  bookingMaxNameCount,
  bookingMaxSecondaryContactCount,
  gstRegex,
} from "../../../constant";
import { suggestPacking, suggestReceiver } from "../../../services/suggest";
import { suggestRate } from "../../../services/rate";
import {
  fetchBranchwiseBookingCounters,
  getLastBookedDocket,
} from "../../../services/booking";
import jsonToFormdata from "../../../utils/jsonToFormdata";
import { getCityByPincode } from "../../../services/area";
import {
  getMaterialList,
  getPackingListForBranchOrder,
} from "../../../services/intraCityRate";
import { bookFilterRoute } from "../../../services/route";
import { getSettingsService } from "../../../services/settings";
import {
  printBuilty,
  printQRDocket,
} from "../../../containers/operations/bookutils/steps/builty";
import { setCities } from "../../globalApis/globalApi";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import { ReceiverTouchedType, SenderTouchedType } from "./booking.type";
import { bookingSchema, toFirstPage } from "./helper";

export const setBookBranches = createAsyncThunk(
  "booking/setBookBranches",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const { id: company } = state.user.company;
      const originBranch = state.user.opBranch._id;
      const branches = await filterBookBranches({ company, originBranch });
      return branches || [];
    } catch (err) {
      return rejectWithValue(err || "Failed to fetch branches");
    }
  }
);

export const updateBookingCounters = createAsyncThunk(
  "booking/updateBookingCounters", // Action type
  async (_, { getState, rejectWithValue }) => {
    try {
      const user: any = getState() as RootState;

      // Check if the user?.user? has a valid login type
      if (user?.user?.loginType) {
        const sub = user?.user?.loginType;
        const entity =
          user?.user?.loginType === "B"
            ? user?.user?.opBranch._id
            : user?.user?.opFleet._id;

        // Fetch the data
        const result = await fetchBranchwiseBookingCounters({ sub, entity });

        // Return the result which will be the payload for the fulfilled action
        return result;
      }

      throw new Error("Invalid user login type");
    } catch (err: any) {
      // If there's an error, reject the action with a custom error message
      return rejectWithValue(err.message || "Something went wrong");
    }
  }
);

export const bookPurpose = createAsyncThunk(
  "booking/bookPurpose",
  async (choice: SelectValueType, { dispatch, rejectWithValue }) => {
    try {
      await bookingSchema.purpose.validate(choice.value);
      await dispatch(ValidationError(["purpose"], false));
      return choice;
    } catch (err: any) {
      if (err.name === "ValidationError") {
        return rejectWithValue({ field: "purpose", message: err.message });
      }
      showHttpError(err);
      return rejectWithValue(err);
    }
  }
);

export const selectBookService = createAsyncThunk(
  "booking/selectBookService",
  async (route: SelectValueTypeForBookService) => {
    try {
      console.log(`mhasdrvbnzdfvuzdfbmn xdfgb`, route);
      // await bookingSchema.bookService.validateSync(route.value);
      console.log(`mhasdrvbnzdfvuzdfbmn xdfgb 0.1`, route);
      return route;
    } catch (err: any) {
      // if (err.name === "ValidationError") {
      //   return rejectWithValue({ field: "bookService", message: false });
      // }
      showHttpError(err);
      // return rejectWithValue(err);
    }
  }
);

export const setHandlingChargeSlab = createAsyncThunk(
  "booking/setHandlingChargeSlab",
  async (_, { rejectWithValue }) => {
    try {
      const result = await listHandlingChargeSlab();
      return result;
    } catch (err: any) {
      showHttpError(err);
      return rejectWithValue(err.message);
    }
  }
);

export const setAcross = createAsyncThunk(
  "booking/setAcross",
  async (
    data: {
      fromBranch: string;
      toCityPincode?: string;
      toCity?: string;
    },
    { dispatch }
  ) => {
    try {
      const url = baseURL;
      const response = await axios.get(
        url +
          "/city/across/" +
          data.fromBranch +
          "/" +
          (data.toCity ? data.toCity : data.toCityPincode)
      );
      if (response.data.status != 1)
        throw "Could not decide inter or intra state";
      let msg: any = acrossFun(response.data.response);
      msg += " charges will be calculated";
      showMessage(msg, success, 1500);
      dispatch(setAcrossData(response.data.response));
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const bookingDestChangeFun = createAsyncThunk(
  "booking/bookingDestChange",
  async (
    {
      payload,
      cb,
    }: {
      payload: SelectValueType;
      cb: () => void;
    },
    { dispatch, getState }
  ) => {
    console.log(`adfvjknzdfkvndf`, payload);
    try {
      dispatch(bookDestBranch(payload));
      await bookingSchema.bookdestbranch.validateSync(payload.value);
      await dispatch(ValidationError(["bookdestbranch"], false));
      const { user, booking } = getState() as RootState;
      console.log("bookdest branch", booking.bookdestcity);
      await dispatch(
        setAcross({
          fromBranch: user.opBranch._id,
          toCity: booking.bookdestcity.value,
        })
      );
      if (typeof cb === "function") {
        cb();
      }
    } catch (err: any) {
      if (err.name == "ValidationError") {
        dispatch(ValidationError(["bookdestbranch"], err.message));
      }
      showHttpError(err);
    }
  }
);

export const bookingDestChange = (payload: SelectValueType, cb: () => void) => {
  return (dispatch: any) => {
    const data = {
      payload,
      cb,
    };
    dispatch(bookingDestChangeFun(data));
  };
};

export const bookChangeEWB = createAsyncThunk(
  "booking/bookChangeEWB",
  async (val: string, { dispatch }) => {
    try {
      dispatch(bookEWB(val));
      dispatch(isEwayBillDetailsFromApi(false))
      if (val.length == 12) {
        dispatch(showEwaybillLoader());
        const response = (await getBillDetails({
          email: "chakrapratyush@gmail.com",
          eWayBillNumber: val,
        })) as any;
        if (response == "Could not retrieve data") {
          dispatch(isEwayBillDetailsFromApi(false))
          throw "ewaybilerror";
        } else {
          let regexGstin =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
          try {
            if (regexGstin.test(response?.gstin_of_consignor)) {
              const senderGstContacts = await getConnectedContactsByGST(
                response?.gstin_of_consignor
              );
              const senderPrimary = senderGstContacts?.primaryCustomer;
              const senderSecondary = senderGstContacts?.secondaryContacts;
              dispatch(
                setSenderGstContacts([
                  ...(senderPrimary ? [senderPrimary] : []),
                  ...(senderSecondary?.length ? senderSecondary : []),
                ])
              );
              dispatch(isEwayBillDetailsFromApi(true))
            }
          } catch (error: any) {
            showHttpError(error);
            dispatch(isEwayBillDetailsFromApi(false))
          }
          try {
            if (regexGstin.test(response?.gstin_of_consignee)) {
              const receiverGstContacts = await getConnectedContactsByGST(
                response?.gstin_of_consignee
              );
              const receiverPrimary = receiverGstContacts?.primaryCustomer;
              const receiverSecondary = receiverGstContacts?.secondaryContacts;
              dispatch(
                setReceiverGstContacts([
                  ...(receiverPrimary ? [receiverPrimary] : []),
                  ...(receiverSecondary?.length ? receiverSecondary : []),
                ])
              );
            dispatch(isEwayBillDetailsFromApi(true))              
            }
          } catch (error: any) {
            showHttpError(error);
            dispatch(isEwayBillDetailsFromApi(false))
          }
          dispatch(setDocketFromEwaybill(response));
          dispatch(isEwayBillDetailsFromApi(true))
        }
        if (response == "Could not retrieve data")
          showMessage("Please Check And Try Again", failed);
          dispatch(isEwayBillDetailsFromApi(false))
      } else {
        dispatch(resetEwayBillData());
        dispatch(isEwayBillDetailsFromApi(false))
      }
    } catch (error) {
      dispatch(isEwayBillDetailsFromApi(false))
      if (error == "ewaybilerror") {
        showMessage("Could not fetch details", failed);
      }
      dispatch(resetEwayBillData());
      showHttpError(error);
    } finally {
      dispatch(hideEwaybillLoader());
    }
  }
);

export const cancelBookingApi = createAsyncThunk(
  "booking/cancelBooking",
  async (
    {
      cancelReason,
      id,
      newDocketNum,
      canceledName,
      canceledNumber,
      canceledOtp,
    }: {
      id: string;
      cancelReason: string;
      newDocketNum: string;
      canceledName?: any;
      canceledNumber?: any;
      canceledOtp?: any;
    },
    { dispatch, getState }
  ) => {
    try {
      await dispatch({ type: "SHOW_LOADER" });

      const { user, booking } = getState() as RootState;
      const payload: any = newDocketNum
        ? {
            docket: id,
            cancelReason: cancelReason,
            newDocket: newDocketNum,
          }
        : {
            docket: id,
            cancelReason: cancelReason,
          };

      if (user.opBranch && user.opBranch._id) {
        payload.sub = "B";
        payload.entity = user.opBranch._id;
      } else if (user.opFleet && user.opFleet._id) {
        payload.sub = "F";
        payload.entity = user.opFleet._id;
      } else {
        throw "select entity first";
      }
      if (booking.canelReason) {
        payload.cancelReason = booking.cancelReason;
      }
      if (canceledName) {
        payload.reciverName = canceledName;
      }
      if (canceledNumber) {
        payload.reciverContact = canceledNumber;
      }
      if (canceledOtp) {
        payload.otp = canceledOtp;
      }

      const { balance } = await cancelDocketService(payload);
      showMessage("Booking Cancelled", success);
      dispatch(resetEdit(undefined));
      await dispatch({ type: "HIDE_LOADER" });

      if (Number(balance) >= 0) {
        const payload = {
          balance: Number(balance?.balance),
        };
        dispatch(updateBalance(payload));
      }
    } catch (error) {
      await dispatch({ type: "HIDE_LOADER" });

      showHttpError(error);
    }
  }
);

export const cancelBooking = (
  id: string,
  cancelReason: string,
  newDocketNum: string,
  canceledName?: any,
  canceledNumber?: any,
  canceledOtp?: any
) => {
  return (dispatch: any) => {
    const payload = {
      id,
      cancelReason,
      newDocketNum,
      canceledName,
      canceledNumber,
      canceledOtp,
    };
    dispatch(cancelBookingApi(payload));
  };
};

export const cancelBookingByCustomerApi = createAsyncThunk(
  "booking/cancelBookingByCustomer",
  async (
    {
      cancelReason,
      id,
      canceledName,
      canceledNumber,
      canceledOtp,
    }: {
      id: string;
      cancelReason: string;
      canceledName?: any;
      canceledNumber?: any;
      canceledOtp?: any;
    },
    { dispatch, getState }
  ) => {
    try {
      await dispatch({ type: "SHOW_LOADER" });

      const { user } = getState() as RootState;
      const payload: any = {
        docket: id,
        remark: cancelReason,
      };

      if (user.opBranch && user.opBranch._id) {
        payload.sub = "B";
        payload.entity = user.opBranch._id;
      } else if (user.opFleet && user.opFleet._id) {
        payload.sub = "F";
        payload.entity = user.opFleet._id;
      } else {
        throw "select entity first";
      }

      if (canceledName) {
        payload.customerName = canceledName;
      }
      if (canceledNumber) {
        payload.customerContact = canceledNumber;
      }
      if (canceledOtp) {
        payload.otp = canceledOtp;
      }

      const { balance } = await cancelDocketByCustomerService(payload);
      showMessage("Booking Cancelled", success);
      dispatch(resetEdit(undefined));
      await dispatch({ type: "HIDE_LOADER" });

      if (Number(balance) >= 0) {
        const payload = {
          balance: Number(balance?.balance),
        };
        dispatch(updateBalance(payload));
      }
    } catch (error) {
      await dispatch({ type: "HIDE_LOADER" });

      showHttpError(error);
    }
  }
);

export const cancelBookingByCustomer = (
  id: string,
  cancelReason: string,
  canceledName?: any,
  canceledNumber?: any,
  canceledOtp?: any
) => {
  return (dispatch: any) => {
    const payload = {
      id,
      cancelReason,
      canceledName,
      canceledNumber,
      canceledOtp,
    };
    dispatch(cancelBookingByCustomerApi(payload));
  };
};

export const resendSMS = createAsyncThunk(
  "booking/resendSMS",
  async (id: string) => {
    try {
      await resendSmsService(id);
      // dispatch({ type: RESEND_SMS });
    } catch (error) {
      showHttpError(error);
    }
  }
);

export const creditNumberChange = createAsyncThunk(
  "booking/creditNumberChange",
  async (payload: { val: string; contact: string }, { dispatch, getState }) => {
    try {
      const { val } = payload;
      const url = baseURL;
      dispatch(creditNumberChangeData(payload.val));
      dispatch(bookCreditReset());
      if (val.length == 10) {
        dispatch(creditNumberShowLoader());
        const credit = await axios.post(
          url + "/credit/customer/fetch",
          payload
        );
        if (credit.data.status != 1) throw "CreditAPIError";
        const { user } = getState() as RootState;
        dispatch(bookCreditSet({ data: credit.data.response, user }));
      }
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(creditNumberHideLoader());
    }
  }
);

export const ChangeMultipleEWBApi = createAsyncThunk(
  "booking/ChangeMultipleEWB",
  async (
    { index, val }: { val: string; index: number },
    { dispatch, getState }
  ) => {
    try {
      dispatch(isEwayBillDetailsFromApi(false))
      let flag = false;
      let { multipleEwayBillData, sender, receiver } = (getState() as RootState)
        .booking;
      let setOnlyEWayBillNo = multipleEwayBillData.map(
        (item: any, index20: any) => {
          if (index === index20) {
            return {
              ...item,
              ewb: val,
            };
          }
          return item;
        }
      );
      await dispatch(setParticularEWB(setOnlyEWayBillNo));
      if (val.length == 12) {
        dispatch(showEwaybillLoader());
        const response: any = await getBillDetails({
          email: "chakrapratyush@gmail.com",
          eWayBillNumber: val,
        });
        if (
          sender?.name?.value === "" &&
          sender?.gst?.value === "" &&
          receiver?.name?.value === "" &&
          receiver?.gst?.value === ""
        ) {
          flag = true;
        }
        let finalData = multipleEwayBillData.map((item: any, index20: any) => {
          if (index === index20) {
            return {
              ...item,
              ewb: val,
              bill: response?.document_number,
              goods: response?.total_invoice_value,
              isEwayBill: true,
            };
          }
          return item;
        });
        if (response == "Could not retrieve data") {
          dispatch(isEwayBillDetailsFromApi(false))
          throw "ewaybilerror";
        } else {
          const payload = {
            finalData,
            srData: { check: flag, arr: response, indexValue: index },
          };
          if (
            sender.name?.value !== "" &&
            sender?.gst?.value !== "" &&
            receiver?.name?.value !== "" &&
            receiver?.gst?.value !== "" &&
            payload?.srData?.arr?.gstin_of_consignor !== sender?.gst?.value &&
            payload?.srData?.arr?.gstin_of_consignee !== receiver?.gst?.value
          ) {
            showMessage(
              `Two Different Sender And Reciever's E-way Bill Not Allowed`,
              failed,
              5000
            );
          }
          dispatch(setMultipleEwayBillData(payload));
          dispatch(isEwayBillDetailsFromApi(true))
        }
        dispatch(hideEwaybillLoader());
        if (response == "Could not retrieve data")
          showMessage("Please Check And Try Again", failed);
          dispatch(isEwayBillDetailsFromApi(false))
      } else {
        let resetArr = multipleEwayBillData.map((item: any, index20: any) => {
          if (index === index20) {
            return {
              ...item,
              ewb: val,
              bill: "",
              goods: "",
            };
          }
          return item;
        });
        dispatch(resetParticularEwayBillData(resetArr));
        dispatch(isEwayBillDetailsFromApi(false))
      }
    } catch (error) {
      showHttpError(error);
      // if (error == "ewaybilerror") {
      //   showMessage("Could not fetch details", failed);
      // }
      // showHttpError(error);
      dispatch(isEwayBillDetailsFromApi(false))
    } finally {
      dispatch(hideEwaybillLoader());
    }
  }
);

export const ChangeMultipleEWB = (val: string, index: any) => {
  return (dispatch: any) => {
    const payload = {
      val,
      index,
    };
    dispatch(ChangeMultipleEWBApi(payload));
  };
};

export const changeMultipleEWayBillManual = createAsyncThunk(
  "booking/changeMultipleEWayBillManual",
  async (data: any, { dispatch, getState }) => {
    let { multipleEwayBillData } = (getState() as RootState).booking;
    let finalData = multipleEwayBillData.map((item: any, index20: any) => {
      if (data?.indexNo === index20) {
        if (data?.contentType === "goods") {
          return {
            ...item,
            goods: data?.value,
          };
        }
        if (data?.contentType === "bill") {
          return {
            ...item,
            bill: data?.value,
          };
        }
      }
      return item;
    });
    await dispatch(changeMultipleEwayBillManualData(finalData));
  }
);

export const addPackage = createAsyncThunk(
  "booking/addPackage",
  async (
    data: {
      qty: string | number;
      materialType: string | SelectValueType;
      packingType: string | SelectValueType;
      dimension: {
        l: string | number;
        b: string | number;
        h: string | number;
      };
      weight: string | number;
      size: string | SelectValueType;
      stack: boolean;
      frag: boolean;
      haz: boolean;
      rate: string | SelectValueType;
      amount: string | number;
      unit: string | SelectValueType;
      id: number;
    },
    { dispatch }
  ) => {
    dispatch(bookAddPackage(data));
    dispatch(bookingRefresh());
  }
);

export const validateSender = createAsyncThunk(
  "booking/validateSender",
  async (_, { dispatch, getState }) => {
    try {
      let { sender, bookTouches } = (getState() as RootState)
        .booking;
      const touched = bookTouches;
      const senderTouched = touched.sender;
      const payload: any = {};
      const schema: any = {};
      for (let key in senderTouched) {
        let k = key as SenderTouchedType;
        console.log({ key, senderTouched });
        if (senderTouched[k] === true) {
          payload[key] = sender[k];
          const validator = bookingSchema.sender[key];
          schema[key] = validator;
          try {
            await validator.validate(sender[k]);
          } catch (err: any) {
            console.log(`validation err : ${key}`, err);
            // set(state, ["bookErrors", "sender", key], err.message);
            // showHttpError(err);
          }
          dispatch(handleValidationError(["sender",key], !validator.isValidSync(sender[k])))
        }
      }
      // dispatch(handleValidationError(["sender"], bookErrors.sender));
    } catch (err: any) {
      console.log(err.message, "message");
      if (err.name == "ValidationError") {
        await dispatch(handleValidationError(["sender"], err.message));
      }
    }
  }
);

export const bookChangeSenderApi = createAsyncThunk(
  "booking/bookChangeSender",
  async (
    {
      what,
      val,
      reset,
    }: {
      what:
        | "contact"
        | "l1"
        | "l2"
        | "pin"
        | "city"
        | "name"
        | "gst"
        | "senderCode";
      val: any;
      reset?: boolean;
    },
    { dispatch, getState }
  ) => {
    const {
      sender,
      isEwayBill,
      purpose,
      senderSecondaryContactReqData,
      senderGstContacts,
    } = (getState() as RootState).booking;
    const state = getState() as RootState;
    try {
      if (what === "senderCode" && !isEwayBill) {
        dispatch(bookResetSender());
      }
      if (what === "contact") {
        dispatch(bookSender({ what: "contactVerified", val: false }));
        if (reset) {
          dispatch(bookSender({ what: "contact", val: "" }));
          dispatch(
            setSenderSecondaryContactReqData({
              dialog: false,
              step: 1,
              secondaryContactData: [],
              removedContact: "",
              newContact: "",
            })
          );
        }
      }
      if (what === "gst") {
        if (purpose?.value === "C" && isEwayBill === false) {
          let regexGstin =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
          if (!regexGstin.test(val?.value)) {
            if (val?.value?.trim() !== "URP") {
              showHttpError(`Gstin Number is Not in Valid Format ~!`);
              dispatch(bookSender({ what }));
              return;
            }
          } else if (regexGstin.test(val?.value)) {
            const gstNos =
              sender?.customerType === "P"
                ? sender?.gstNos
                : sender?.connectedContacts?.gstNos;
            const primaryContact =
              sender?.customerType === "P"
                ? sender?.contact
                : sender?.connectedContacts?.contact;

            if (val?.__isNew__) {
              try {
                await dispatch({ type: "SHOW_LOADER" });
                const senderGstContacts = await getConnectedContactsByGST(
                  val?.value
                );
                const senderPrimary = senderGstContacts?.primaryCustomer;

                const allowToConnect =
                  sender?.customerType === "S" &&
                  !sender?.connectedContacts?.contact;

                await dispatch({ type: "HIDE_LOADER" });
                if (senderPrimary && !allowToConnect) {
                  await dispatch(
                    setGeneralWarning(
                      `Sender GST ${val?.value} is already connected with other primary customer. Please contact admin.`
                    )
                  );
                  // showHttpError();
                  return;
                }
              } catch (err) {
                await dispatch({ type: "HIDE_LOADER" });
                showHttpError(err);
                return;
              }
            }
            if (val?.__isNew__ && gstNos?.length >= bookingMaxGstCount) {
              dispatch(
                setSenderGstRemoveReqData({
                  dialog: true,
                  step: 1,
                  removeIndex: null,
                  gstData: gstNos,
                  newGst: "",
                  primaryContact,
                })
              );
              return;
            }
            if (sender && isArrayCheck(sender.gstNos)) {
              const concernedGst = sender.gstNos.find(
                (g: { GSTIN: string }) => g.GSTIN == val.value
              );
              if (concernedGst && concernedGst.GSTIN) {
              } else {
                await dispatch({ type: "SHOW_LOADER" });
                try {
                  const customer = await getCustomerByGST({ gst: val.value });
                  const checkIsGstNameExists = sender?.names?.find(
                    (name: any) =>
                      customer?.trade_name?.toUpperCase() ===
                      name?.toUpperCase()
                  );
                  await dispatch(
                    setSenderByGst({
                      ...customer,
                      senderForceNameRemoveDueToGst:
                        sender?.names?.length >= bookingMaxNameCount &&
                        !checkIsGstNameExists,
                      senderGstRelativeFieldDisable: true,
                    })
                  );
                  await dispatch(validateSender());
                  await dispatch({ type: "HIDE_LOADER" });
                } catch (err: any) {
                  showMessage("Please Check And Try Again", failed);
                  showHttpError(err);
                  await dispatch(senderGstInvalid());
                  await dispatch(bookSender({ what }));
                  await dispatch({ type: "HIDE_LOADER" });
                  return;
                }
              }
            } else {
              await dispatch({ type: "SHOW_LOADER" });
              try {
                const customer = await getCustomerByGST({ gst: val.value });
                const checkIsGstNameExists = sender?.names?.find(
                  (name: any) =>
                    customer?.trade_name?.toUpperCase() === name?.toUpperCase()
                );
                await dispatch(
                  setSenderByGst({
                    ...customer,
                    senderForceNameRemoveDueToGst:
                      sender?.names?.length >= bookingMaxNameCount &&
                      !checkIsGstNameExists,
                    senderGstRelativeFieldDisable: true,
                  })
                );
                await dispatch(validateSender());
                await dispatch({ type: "HIDE_LOADER" });
              } catch (err: any) {
                showMessage("Please Check And Try Again", failed);
                showHttpError(err);
                await dispatch(senderGstInvalid());
                await dispatch(bookSender({ what }));
                await dispatch({ type: "HIDE_LOADER" });
                return;
              }
            }
          }
        } else {
          return showHttpError(`Write a Perfect Gstin Number ~!`);
        }
      }
      await dispatch(bookSender({ what, val }));
      const { bookroute, ewb, receiverBranchAddress } = state.booking;
      const { phoneVerificationSetting } = state.allGeneralSettings;
      const { opBranch } = state.user;
      if (what == "contact" && val.length == 10) {
        let senderDoc: any = {};
        try {
          await dispatch(
            bookSetSenderVerification({
              what: "",
              val: "",
            })
          );
          senderDoc = await fetchCustomerByContactGeneralService({
            contact: val,
          });

          if (sender?.gst?.value) {
            const checkIsGstNameExists = senderDoc?.names?.find(
              (name: any) =>
                sender?.name?.value?.toUpperCase() === name?.toUpperCase()
            );
            const senderForceNameRemoveDueToGst =
              senderDoc?.names?.length >= bookingMaxNameCount &&
              !checkIsGstNameExists;
            await dispatch(
              setSenderForceNameRemoveDueToGstData(
                senderForceNameRemoveDueToGst
              )
            );
          }

          if (isEwayBill) {
            const res = await getConnectedContactsByGST(sender?.gst?.value);
            const senderPrimary = res?.primaryCustomer;
            const senderSecondary = res?.secondaryContacts;

            const senderGstContacts = [
              ...(senderPrimary ? [senderPrimary] : []),
              ...(senderSecondary.length ? senderSecondary : []),
            ];
            await dispatch(setSenderGstContacts(senderGstContacts));
            const isAlreadySecondary = senderGstContacts?.find(
              (c: any) => c?.contact?.toString() === val.toString()
            );
            if (!isAlreadySecondary) {
              const secondaryContacts = senderGstContacts?.filter(
                (c: any) => c.customerType === "S"
              );
              const primaryContact = senderGstContacts?.find(
                (c: any) => c.customerType === "P"
              )?.contact;

              if (senderDoc?.customerType === "P") {
                showHttpError("Sender is already primary customer");
              }

              if (
                senderDoc?.customerType === "S" &&
                senderDoc?.connectedContacts?.length
              ) {
                showHttpError(
                  "Sender is already connected with other primary customer"
                );
              }
              if (secondaryContacts.length >= bookingMaxSecondaryContactCount) {
                await dispatch(bookSender({ what, val: "" }));

                if (
                  senderSecondaryContactReqData.step === 2 &&
                  senderSecondaryContactReqData.newContact.toString() ===
                    val.toString()
                ) {
                  showHttpError("Your request is not approved yet.");
                  return;
                } else {
                  await dispatch(
                    setSenderSecondaryContactReqData({
                      dialog: true,
                      step: 1,
                      secondaryContactData: secondaryContacts,
                      newContact: val.toString(),
                      primaryContact,
                    })
                  );
                }
                return;
              }
            } else {
              await dispatch(
                setSenderSecondaryContactReqData({
                  dialog: false,
                  step: 1,
                  secondaryContactData: senderSecondary,
                  removedContact: "",
                  newContact: "",
                })
              );
            }
          } else {
            const connectedContacts = await getConnectedCustomersByContact(val);
            const senderPrimary = connectedContacts?.primaryCustomer;
            const senderSecondary = connectedContacts?.secondaryContacts;
            const senderGstContacts = [
              ...(senderPrimary ? [senderPrimary] : []),
              ...(senderSecondary.length ? senderSecondary : []),
            ];
            await dispatch(setSenderGstContacts(senderGstContacts));
          }
          await dispatch(
            bookSetSenderVerification({
              what: "isSenderNew",
              val: false,
            })
          );
          await dispatch(bookSetSender(senderDoc));
          if (phoneVerificationSetting) {
            if (senderDoc && senderDoc.isContactValidated == false) {
              await dispatch(
                customerContactValidate("sender", {
                  name: senderDoc.name,
                  contact: senderDoc.contact,
                })
              );
            }
          }
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
        } catch (err: any) {
          await dispatch(
            bookSetSenderVerification({
              what: "isSenderNew",
              val: true,
            })
          );
          const secondaryContacts = senderGstContacts?.filter(
            (c: any) => c.customerType === "S"
          );
          const primaryContact = senderGstContacts?.find(
            (c: any) => c.customerType === "P"
          )?.contact;
          if (secondaryContacts.length >= bookingMaxSecondaryContactCount) {
            await dispatch(
              setSenderSecondaryContactReqData({
                dialog: true,
                step: 1,
                secondaryContactData: secondaryContacts,
                newContact: val.toString(),
                primaryContact,
              })
            );
          }

          if (!ewb) {
            await dispatch;
            await dispatch(resetSenderName());
          }
          showHttpError(err);
        }

        try {
          if (receiverBranchAddress && receiverBranchAddress.pincode) {
            const receiverSuggestions = await suggestReceiver({
              senderContact: val,
              destination:
                receiverBranchAddress && receiverBranchAddress.pincode,
            });
            await dispatch(setReceiverSuggetions(receiverSuggestions));
          }
        } catch (err: any) {
          await dispatch(setReceiverSuggetions([]));
          showHttpError(err);
        }

        if (bookroute && bookroute.value && bookroute.value.length == 24) {
          try {
            const rateSuggestions = await suggestRate({
              sender: val,
              route: bookroute.value,
            });
            await dispatch(setRateSuggetions(rateSuggestions));
          } catch (err: any) {
            await dispatch(setRateSuggetions([]));
            showHttpError(err);
          }
        }
      } else if (what == "senderCode" && val.length >= 3) {
        let contact: any = "";
        try {
          const senderDoc = await fetchCustomerByCustomerCodeService({
            customerCode: val,
          });

          if (senderDoc) {
            if (phoneVerificationSetting) {
              if (senderDoc && senderDoc.isContactValidated == false) {
                await dispatch(
                  customerContactValidate("sender", {
                    name: senderDoc.name,
                    contact: senderDoc.contact,
                  })
                );
              }
            }
            await dispatch(bookSetIsSenderDisable(true));
            contact = senderDoc.contact;
            if (isEwayBill) {
              await dispatch(
                bookSender({ what: "contact", val: senderDoc.contact })
              );
            } else {
              await dispatch(bookSetSender(senderDoc));
            }
          }
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
        } catch (err: any) {
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
          showHttpError(err);
        }

        try {
          const receiverSuggestions = await suggestReceiver({
            senderContact: contact,
            destination:
              receiverBranchAddress && receiverBranchAddress.pincode
                ? receiverBranchAddress.pincode
                : "",
          });
          await dispatch(setReceiverSuggetions(receiverSuggestions));
        } catch (err: any) {
          await dispatch(setReceiverSuggetions([]));
          showHttpError(err);
        }

        if (bookroute && bookroute.value && bookroute.value.length == 24) {
          try {
            const rateSuggestions = await suggestRate({
              sender: contact,
              route: bookroute.value,
            });
            await dispatch(setRateSuggetions(rateSuggestions));
          } catch (err: any) {
            await dispatch(setRateSuggetions([]));
            showHttpError(err);
          }
        }
      } else if (what == "gst" && val.value && val.value.length == 15) {
        if (sender && isArrayCheck(sender.gstNos)) {
          const concernedGst = sender.gstNos.find(
            (g: { GSTIN: string }) => g.GSTIN == val.value
          );
          if (concernedGst && concernedGst.GSTIN) return;
        }
        const payload = { gst: val.value, sender };
        // await dispatch({ type: SHOW_GST_LOADER }); // this needs to change
        await dispatch(getSenderByGST(payload));
        // await dispatch({ type: HIDE_GST_LOADER }); // this needs to change
      } else if (
        what == "name" ||
        (what == "contact" && val.length == 10) ||
        what == "gst"
      ) {
        if (phoneVerificationSetting) {
          const { sender: newSender } = state.booking; //newSender for access latest value from redux
          if (newSender.isContactValidated == false) {
            if (!opBranch.address) {
              showMessage("Origin Branch Adress Not Exists", failed);
              return;
            }
            if (what == "contact" && !newSender.contact) {
              showMessage("sender contact is Empty 1", failed);
              return;
            }
            if (what == "name" && !newSender.name) {
              showMessage("sender contact is Empty 2", failed);
              return;
            }
            if (!newSender.contact || !newSender.name) {
              //  showMessage("receiver contact is Empty",failed)
              return;
            }

            await dispatch(
              customerContactValidate("sender", {
                name: newSender?.name?.value,
                ...(newSender?.gst?.value && gstRegex.test(newSender.gst.value)
                  ? { gst: newSender.gst.value }
                  : {}),
                contact: newSender.contact,
                address: opBranch.address,
              })
            );
          }
        }
      }
      console.log(
        what,
        "what",
        phoneVerificationSetting,
        "phoneVerificationSetting",
        state.booking.sender
      );
    } catch (err: any) {
      // await dispatch({ type: HIDE_GST_LOADER });
      if (err.name == "ValidationError") {
        await dispatch(handleValidationError(["sender"], err.message));
      }
      // showHttpError(err);
    }
  }
);

export const bookChangeSender = (
  what:
    | "contact"
    | "l1"
    | "l2"
    | "pin"
    | "city"
    | "name"
    | "gst"
    | "senderCode",
  val: any,
  reset?: boolean
) => {
  return async (dispatch: any) => {
    const payload = {
      what,
      val,
      reset,
    };
    dispatch(bookChangeSenderApi(payload));
  };
};

export const customerContactValidateApi = createAsyncThunk(
  "booking/customerContactValidate",
  async (
    { what, val }: { what: "sender" | "receiver"; val: any },
    { dispatch }
  ) => {
    try {
      // dispatch(showLoader());
      const response = await fetchCustomerByContact(val);
      if (what === "sender") {
        dispatch(
          bookSetSenderValidate({
            isContactValidated: response.isContactValidated,
            message: response.message,
          })
        );
      } else {
        dispatch(
          bookSetReceiverValidate({
            isContactValidated: response.isContactValidated,
            message: response.message,
          })
        );
      }
      // dispatch(hideLoader());
    } catch (err: any) {
      // dispatch(hideLoader());
      showHttpError(err);
      if (what === "sender") {
        dispatch(bookSetSenderValidate({ isContactValidated: false }));
      } else {
        dispatch(bookSetReceiverValidate({ isContactValidated: false }));
      }
    }
  }
);

export const customerContactValidate = (
  what: "sender" | "receiver",
  val: any
) => {
  return (dispatch: any) => {
    const payload = {
      what,
      val,
    };
    dispatch(customerContactValidateApi(payload));
  };
};

export const getSenderByGST = createAsyncThunk(
  "booking/getSenderByGST",
  async (data: { gst: string; sender: any }, { dispatch }) => {
    try {
      await dispatch({ type: "SHOW_LOADER" });
      const customer = await getCustomerByGST(data);
      const checkIsGstNameExists = data?.sender?.names?.find(
        (name: any) =>
          customer?.trade_name?.toUpperCase() === name?.toUpperCase()
      );
      await dispatch(
        setSenderByGst({
          ...customer,
          senderForceNameRemoveDueToGst:
            data?.sender?.names?.length >= bookingMaxNameCount &&
            !checkIsGstNameExists,
          senderGstRelativeFieldDisable: true,
        })
      );
      await dispatch({ type: "HIDE_LOADER" });
      await dispatch(validateSender());
      return true;
    } catch (err: any) {
      showMessage("Please Check And Try Again", failed);
      showHttpError(err);
      dispatch(senderGstInvalid());
      await dispatch({ type: "HIDE_LOADER" });

      return false;
    }
  }
);

export const validateReceiver = createAsyncThunk(
  "booking/validateReceiver",
  async (_, { dispatch, getState }) => {
    try {
      let { receiver, bookTouches } = (getState() as RootState)
        .booking;
      // if (bookTouches && typeof bookTouches == "function") {
        const touched = bookTouches;
        const receiverTouched = touched.receiver;
        // if (options && options.pre && typeof options.pre == "string") {
        //   receiverTouched[options.pre] = true;
        // }
        const payload: any = {};
        const schema: any = {};
        for (let key in receiverTouched) {
          const k = key as ReceiverTouchedType
          if (receiverTouched[k] === true) {
            payload[key] = receiver[k];
            let validator = bookingSchema.receiver[k];
            schema[key] = validator;
            try {
              await validator.validate(receiver[k]);
            } catch (err: any) {
              // showHttpError(err);
            }
            dispatch(handleValidationError(["receiver",key], !validator.isValidSync(receiver[k])))
          }
        // }
        // dispatch(handleValidationError(["receiver"], bookErrors.receiver));
      }
    } catch (err: any) {
      // showHttpError(err);
      if (err.name == "ValidationError") {
        await dispatch(handleValidationError(["receiver"], err.message));
      }
    }
  }
);

export const bookChangeReceiverApi = createAsyncThunk(
  "booking/bookChangeReceiver",
  async (
    {
      what,
      val,
      reset,
    }: {
      what:
        | "contact"
        | "l1"
        | "l2"
        | "pin"
        | "city"
        | "name"
        | "gst"
        | "receiverCode";
      val: any;
      reset?: boolean;
    },
    { dispatch, getState }
  ) => {
    const {
      sender,
      receiver,
      deliveryType,
      ewb,
      receiverBranchAddress,
      purpose,
      isEwayBill,
      receiverSecondaryContactReqData,
      receiverGstContacts,
    } = (getState() as RootState).booking;
    const state = getState() as RootState;
    const url = baseURL;
    const { phoneVerificationSetting } = state.allGeneralSettings;
    try {
      if (what === "receiverCode" && !isEwayBill) {
        await dispatch(bookResetReceiver());
      }
      if (what == "contact") {
        dispatch(bookReceiver({ what: "contactVerified", val: false }));

        if (reset) {
          dispatch(bookReceiver({ what: "contact", val: "" }));
          dispatch(
            setReceiverSecondaryContactReqData({
              dialog: false,
              step: 1,
              secondaryContactData: [],
              removedContact: "",
              newContact: "",
            })
          );
        }
      }

      if (what === "gst") {
        if (reset) {
          dispatch(bookReceiver({ what: "gst", val: "" }));
          return;
        }
        if (purpose?.value === "C" && isEwayBill === false) {
          let regexGstin =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
          if (!regexGstin.test(val?.value)) {
            if (val?.value?.trim() !== "URP") {
              showHttpError(`Gstin Number is Not in Valid Format ~!`);
              await dispatch(bookReceiver({ what }));
              return;
            }
          } else if (regexGstin.test(val?.value)) {
            const gstNos =
              receiver?.customerType === "P"
                ? receiver?.gstNos
                : receiver?.connectedContacts?.gstNos;
            const primaryContact =
              receiver?.customerType === "P"
                ? receiver?.contact
                : receiver?.connectedContacts?.contact;

            if (val?.__isNew__) {
              try {
                await dispatch({ type: "SHOW_LOADER" });
                const receiverGstContacts = await getConnectedContactsByGST(
                  val?.value
                );
                const receiverPrimary = receiverGstContacts?.primaryCustomer;

                const allowToConnect =
                  receiver?.customerType === "S" &&
                  !receiver?.connectedContacts?.contact;

                await dispatch({ type: "HIDE_LOADER" });
                if (receiverPrimary && !allowToConnect) {
                  await dispatch(
                    setGeneralWarning(
                      `Receiver GST ${val?.value} is already connected with other primary customer. Please contact admin.`
                    )
                  );
                  return;
                }
              } catch (err) {
                await dispatch({ type: "HIDE_LOADER" });
                showHttpError(err);
                return;
              }
            }

            if (val?.__isNew__ && gstNos?.length >= bookingMaxGstCount) {
              await dispatch(bookReceiver({ what: "gstRemoveReqData" }));
              await dispatch(
                setReceiverGstRemoveReqData({
                  dialog: true,
                  step: 1,
                  removeIndex: null,
                  gstData: gstNos,
                  newGst: "",
                  primaryContact,
                })
              );
              return;
            }
            if (receiver && isArrayCheck(receiver.gstNos)) {
              const concernedGst = receiver.gstNos.find(
                (g: { GSTIN: string }) => g.GSTIN == val.value
              );
              if (concernedGst && concernedGst.GSTIN) {
              } else {
                await dispatch({ type: "SHOW_LOADER" });
                try {
                  let customer = await getCustomerByGST({ gst: val.value });
                  const checkIsGstNameExists = receiver?.names?.find(
                    (name: any) =>
                      customer?.trade_name?.toUpperCase() ===
                      name?.toUpperCase()
                  );
                  await dispatch(
                    setReceiverByGst({
                      ...customer,
                      receiverForceNameRemoveDueToGst:
                        receiver?.names?.length >= bookingMaxNameCount &&
                        !checkIsGstNameExists,
                      receiverGstRelativeFieldDisable: true,
                    })
                  );
                  await dispatch(validateReceiver());
                  await dispatch({ type: "HIDE_LOADER" });
                } catch (err: any) {
                  showMessage("Please Check And Try Again", failed);
                  showHttpError(err);
                  await dispatch(receiverGstInvalid());
                  await dispatch(bookReceiver({ what }));
                  await dispatch({ type: "HIDE_LOADER" });
                  return;
                }
              }
            } else {
              await dispatch({ type: "SHOW_LOADER" });
              try {
                let customer = await getCustomerByGST({ gst: val.value });
                const checkIsGstNameExists = receiver?.names?.find(
                  (name: any) =>
                    customer?.trade_name?.toUpperCase() === name?.toUpperCase()
                );
                await dispatch(
                  setReceiverByGst({
                    ...customer,
                    receiverForceNameRemoveDueToGst:
                      receiver?.names?.length >= bookingMaxNameCount &&
                      !checkIsGstNameExists,
                    receiverGstRelativeFieldDisable: true,
                  })
                );
                await dispatch(validateReceiver());
                await dispatch({ type: "HIDE_LOADER" });
              } catch (err: any) {
                showMessage("Please Check And Try Again", failed);
                showHttpError(err);
                await dispatch(receiverGstInvalid());
                await dispatch(bookReceiver({ what }));
                await dispatch({ type: "HIDE_LOADER" });
                return;
              }
            }
          }
        } else {
          return showHttpError(`Write a Perfect Gstin Number ~!`);
        }
      }
      await dispatch(bookReceiver({ what, val }));
      if (
        what == "pin" &&
        deliveryType &&
        deliveryType.value == "Home Delivery"
      ) {
        if (val.length == 6) {
          try {
            dispatch(setFedxServiceOptions("loading"));
            const payload = {
              sourcePincode: sender.pin,
              destinationPincode: val,
              packages: [
                {
                  qty: 1,
                  weight: 10,
                },
              ],
              useDimensions: false,
            };
            const [fedexResponse, city] = await Promise.all([
              axios.post(url + "/fedex/availableRate", payload),
              getCityByPincode(val),
            ]);

            if (fedexResponse.status == 200) {
              const fedexOptions = fedexResponse.data.response;
              const fedexOptionsPayload = fedexOptions.map((opt: any) => ({
                value: opt.type,
                label:
                  opt.type +
                  " | " +
                  opt.Currency +
                  " " +
                  Math.ceil(Number(opt.Amount)) +
                  " per 10KG" +
                  " | Delivery_Time: " +
                  new Date(opt.expectedDeliveryTime).toLocaleDateString(),
              }));
              dispatch(setFedxServiceOptions(fedexOptionsPayload));
              dispatch(bookReceiver({ what: "city", val: city.name }));
            } else {
              dispatch(setFedxServiceOptions("unavailable"));
              showMessage(fedexResponse.data.message, "salmon");
            }
          } catch (err: any) {
            await dispatch(setFedxServiceOptions("unavailable"));
            showMessage(err.data.message, "salmon");
          }
        } else {
          dispatch(setFedxServiceOptions(null));
          dispatch(bookChangeFedexServiceType(null));
          dispatch(bookReceiver({ what: "city", val: "" }));
        }
      }
      if (what == "contact" && val.length == 10) {
        let receiverDoc;
        try {
          receiverDoc = await fetchCustomerByContactGeneralService({
            contact: val,
          });

          if (receiver?.gst?.value) {
            const checkIsGstNameExists = receiverDoc?.names?.find(
              (name: any) =>
                receiver?.name?.value?.toUpperCase() === name?.toUpperCase()
            );
            const receiverForceNameRemoveDueToGst =
              receiverDoc?.names?.length >= bookingMaxNameCount &&
              !checkIsGstNameExists;
            await dispatch(
              setReceiverForceNameRemoveDueToGst(
                receiverForceNameRemoveDueToGst
              )
            );
          }

          if (isEwayBill) {
            const res = await getConnectedContactsByGST(receiver?.gst?.value);
            const receiverPrimary = res?.primaryCustomer;
            const receiverSecondary = res?.secondaryContacts;

            const receiverGstContacts = [
              ...(receiverPrimary ? [receiverPrimary] : []),
              ...(receiverSecondary?.length ? receiverSecondary : []),
            ];
            await dispatch(setReceiverGstContacts(receiverGstContacts));

            const isAlreadySecondary = receiverGstContacts?.find(
              (c: any) => c?.contact?.toString() === val.toString()
            );
            if (!isAlreadySecondary) {
              const secondaryContacts = receiverGstContacts?.filter(
                (c: any) => c.customerType === "S"
              );
              const primaryContact = receiverGstContacts?.find(
                (c: any) => c.customerType === "P"
              )?.contact;

              if (receiverDoc?.customerType === "P") {
                showHttpError("Receiver is already primary customer");
              }

              if (
                receiverDoc?.customerType === "S" &&
                receiverDoc?.connectedContacts?.length
              ) {
                showHttpError(
                  "Receiver is already connected with other primary customer"
                );
              }
              if (secondaryContacts.length >= bookingMaxSecondaryContactCount) {
                await dispatch(bookReceiver({ what, val: "" }));

                if (
                  receiverSecondaryContactReqData.step === 2 &&
                  receiverSecondaryContactReqData.newContact.toString() ===
                    val.toString()
                ) {
                  showHttpError("Your request is not approved yet.");
                  return;
                } else {
                  await dispatch(
                    setReceiverSecondaryContactReqData({
                      dialog: true,
                      step: 1,
                      secondaryContactData: secondaryContacts,
                      newContact: val.toString(),
                      primaryContact,
                    })
                  );
                }
                return;
              }
            } else {
              await dispatch(
                setReceiverSecondaryContactReqData({
                  dialog: false,
                  step: 1,
                  secondaryContactData: receiverSecondary,
                  removedContact: "",
                  newContact: "",
                })
              );
            }
          } else {
            const connectedContacts = await getConnectedCustomersByContact(val);
            const receiverPrimary = connectedContacts?.primaryCustomer;
            const receiverSecondary = connectedContacts?.secondaryContacts;

            const receiverGstContacts = [
              ...(receiverPrimary ? [receiverPrimary] : []),
              ...(receiverSecondary.length ? receiverSecondary : []),
            ];
            await dispatch(setReceiverGstContacts(receiverGstContacts));
          }
          await dispatch(bookSetReceiver(receiverDoc));
          await dispatch(bookReceiver({ what, val }));
          if (phoneVerificationSetting) {
            if (receiverDoc && receiverDoc.isContactValidated == false) {
              await dispatch(
                customerContactValidate("receiver", {
                  name: receiverDoc.name,
                  contact: receiverDoc.contact,
                })
              );
            }
          }
        } catch (err: any) {
          const secondaryContacts = receiverGstContacts?.filter(
            (c: any) => c.customerType === "S"
          );
          const primaryContact = receiverGstContacts?.find(
            (c: any) => c.customerType === "P"
          )?.contact;
          if (secondaryContacts.length >= bookingMaxSecondaryContactCount) {
            await dispatch(
              setReceiverSecondaryContactReqData({
                dialog: true,
                step: 1,
                secondaryContactData: secondaryContacts,
                newContact: val.toString(),
                primaryContact,
              })
            );
          }

          if (!ewb) {
            await dispatch(bookSetReceiver(receiverDoc));
            await dispatch(resetReceiverName());
          }

          showHttpError(err);
        }
      } else if (what == "receiverCode" && val.length >= 3) {
        try {
          const receiverDoc = await fetchCustomerByCustomerCodeService({
            customerCode: val,
          });

          if (receiverDoc) {
            if (phoneVerificationSetting) {
              if (receiverDoc && receiverDoc.isContactValidated == false) {
                await dispatch(
                  customerContactValidate("receiver", {
                    name: receiverDoc.name,
                    contact: receiverDoc.contact,
                  })
                );
              }
            }
            await dispatch(bookSetIsReceiverDisable(true));
            if (isEwayBill) {
              await dispatch(
                bookReceiver({ what: "contact", val: receiverDoc.contact })
              );
            } else {
              await dispatch(bookSetReceiver(receiverDoc));
            }
          }
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
        } catch (err: any) {
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
          showHttpError(err);
        }
      } else if (what == "gst" && val.value && val.value.length == 15) {
        if (receiver && isArrayCheck(receiver.gstNos)) {
          const concernedGst = receiver.gstNos.find(
            (g: { GSTIN: string }) => g.GSTIN == val.value
          );
          if (concernedGst && concernedGst.GSTIN) return;
        }
        const payload = { gst: val.value, receiver };
        // dispatch({ type: SHOW_GST_LOADING_FOR_RECEIVER });
        await dispatch(getReceiverByGST(payload));
        // dispatch({ type: HIDE_GST_LOADING_FOR_RECEIVER });
      }
      if (
        what == "name" ||
        (what == "contact" && val.length == 10) ||
        what == "gst"
      ) {
        if (phoneVerificationSetting) {
          const { receiver: newReceiver } = (getState() as RootState).booking; //newreceiver for access latest value from redux
          if (newReceiver.isContactValidated == false) {
            if (!receiverBranchAddress) {
              showMessage("Dest Branch Adress Not Exists", failed);
              return;
            }
            if (what == "contact" && !newReceiver.contact) {
              showMessage("receiver contact is Empty", failed);
              return;
            }
            if (what == "name" && !newReceiver.name) {
              showMessage("receiver contact is Empty", failed);
              return;
            }
            if (!newReceiver.contact || !newReceiver.name) {
              //  showMessage("receiver contact is Empty",failed)
              return;
            }
            await dispatch(
              customerContactValidate("receiver", {
                name: newReceiver?.name?.value,
                ...(newReceiver?.gst?.value &&
                gstRegex.test(newReceiver.gst.value)
                  ? { gst: newReceiver.gst.value }
                  : {}),
                contact: newReceiver.contact,
                address: {
                  ...receiverBranchAddress,
                  city: receiverBranchAddress?.city?._id,
                },
              })
            );
          }
        }
      }
    } catch (err: any) {
      // dispatch({ type: HIDE_GST_LOADING_FOR_RECEIVER });
      if (err.name == "ValidationError") {
        await dispatch(handleValidationError(["receiver"], err.message));
      }
      // showHttpError(err);
    }
  }
);

export const bookChangeReceiver = (
  what:
    | "contact"
    | "l1"
    | "l2"
    | "pin"
    | "city"
    | "name"
    | "gst"
    | "receiverCode",
  val: any,
  reset?: boolean
) => {
  return async (dispatch: any) => {
    const payload = {
      what,
      val,
      reset,
    };
    dispatch(bookChangeReceiverApi(payload));
  };
};

export const setPackageSuggestions = createAsyncThunk(
  "booking/setPackageSuggestions", // action type string
  async (
    {
      senderContact,
      receiverContact,
      // destination,
      destBranch,
      originBranch,
    }: {
      senderContact: string;
      receiverContact: string;
      // destination: string;
      originBranch: string;
      destBranch: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const packageSuggestions = await suggestPacking({
        senderContact,
        receiverContact,
        // destination,
        originBranch,
        destBranch,
      });
      return packageSuggestions; // This will be dispatched as the payload on success
    } catch (err: any) {
      // You can handle errors here, if you want to dispatch an error message or any specific value
      return rejectWithValue(null); // This will handle the rejection state
    }
  }
);

export const editDocketApi = createAsyncThunk(
  "booking/editDocket", // action type string
  async (
    {
      id,
      json,
    }: {
      id: string;
      json: {
        sender: any;
        receiver: any;
        idImage: any;
        docketImages: any;
        billImage: any;
        delSpecific: any;
      };
    },
    { dispatch, getState }
  ) => {
    try {
      const {
        sender,
        receiver,
        idImage,
        docketImages,
        billImage,
        delSpecific,
      } = json;
      const payload = {
        fromBranch: ((getState() as RootState).user.opBranch || {})._id,
        sender,
        receiver,
        idImage,
        docketImages,
        billImage,
        delSpecific,
      };
      dispatch({ type: "SHOW_LOADER" });
      try {
        await editDocketService(id, jsonToFormdata(payload));
        showMessage("Docket edited successfully", success);
        dispatch({ type: "HIDE_LOADER" });
        toFirstPage();
      } catch (err: any) {
        // dispatch({ type: "HIDE_LOADER"_WITHOUT_BLUR });
        dispatch({ type: "HIDE_LOADER" });
        toFirstPage();
      }
    } catch (err: any) {
      showHttpError(err);
      dispatch({ type: "HIDE_LOADER" });
    }
  }
);

export const editDocket = (
  id: string,
  json: {
    sender: any;
    receiver: any;
    idImage: any;
    docketImages: any;
    billImage: any;
    delSpecific: any;
  }
) => {
  return (dispatch: any) => {
    const payload = {
      id,
      json,
    };
    dispatch(editDocketApi(payload));
  };
};

export const getReceiverByGST = createAsyncThunk(
  "booking/getReceiverByGST", // action type string
  async (data: { gst: string; receiver: any }, { dispatch }) => {
    try {
      await dispatch({ type: "SHOW_LOADER" });
      let customer = await getCustomerByGST(data);
      const checkIsGstNameExists = data?.receiver?.names?.find(
        (name: any) => customer.trade_name.toUpperCase() === name.toUpperCase()
      );
      await dispatch(
        setReceiverByGst({
          ...customer,
          receiverForceNameRemoveDueToGst:
            data?.receiver?.names.length >= bookingMaxNameCount &&
            !checkIsGstNameExists,
          receiverGstRelativeFieldDisable: true,
        })
      );
      await dispatch({ type: "HIDE_LOADER" });
      await dispatch(validateReceiver());
    } catch (err: any) {
      showHttpError(err);
      dispatch(receiverGstInvalid());
      await dispatch({ type: "HIDE_LOADER" });
    }
  }
);

export const getMaterial = createAsyncThunk(
  "booking/getMaterial", // action type string
  async (_, { dispatch, getState }) => {
    try {
      let reduxData = getState() as RootState;
      if (reduxData?.booking?.material?.length === 0) {
        let data = await getMaterialList();
        // if (response.data.status != 1) throw "Could not fetch materials";
        dispatch(setMaterial(data));
      } else {
        dispatch(setMaterial(reduxData?.booking?.material));
      }
    } catch (err: any) {}
  }
);

export const getPackingType = createAsyncThunk(
  "booking/getPackingType", // action type string
  async (_, { dispatch, getState }) => {
    try {
      let reduxData = getState() as RootState;
      if (reduxData?.booking?.packingType?.length === 0) {
        let response = await getPackingListForBranchOrder();
        // if (response.data.status != 1) {
        //   throw "Could not fetch packages";
        // }
        dispatch(setPackingType(response));
      } else {
        dispatch(setPackingType(reduxData?.booking?.packingType));
      }
    } catch (err: any) {}
  }
);

export const resetCustomerValidate = createAsyncThunk(
  "booking/resetCustomerValidate", // action type string
  async (what: "sender" | "receiver", { dispatch }) => {
    const payload = { isContactValidated: false };
    if (what == "sender") {
      dispatch(bookSetSenderValidate(payload));
    } else {
      dispatch(bookSetReceiverValidate(payload));
    }
  }
);

export const setRoutes = createAsyncThunk(
  "booking/setRoutes", // action type string
  async (_, { dispatch, getState }) => {
    try {
      const { user } = getState() as RootState;
      const { id: company } = user.company;
      const result = await bookFilterRoute({ company });
      await dispatch(setRoutesData(result));
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const setSettings = createAsyncThunk(
  "booking/setSettings", // action type string
  async (_, { dispatch }) => {
    try {
      const result = await getSettingsService();
      await dispatch(setSettingsData(result));
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const setCustomerContactValidateFun = createAsyncThunk(
  "booking/setCustomerContactValidate", // action type string
  async (
    { what, val }: { what: "sender" | "receiver"; val: any },
    { dispatch }
  ) => {
    const payload = { isContactValidated: val };
    if (what == "sender") {
      dispatch(bookSetSenderValidate(payload));
    } else {
      dispatch(bookSetReceiverValidate(payload));
    }
  }
);

export const setCustomerContactValidate = (
  what: "sender" | "receiver",
  val: any
) => {
  return (dispatch: any) => {
    const payload = {
      what,
      val,
    };
    dispatch(setCustomerContactValidateFun(payload));
  };
};

export const bookApi = createAsyncThunk(
  "booking/book", // action type string
  async (
    { json, callbacks }: { json: any; callbacks?: Function[] },
    { dispatch, getState }
  ) => {
    try {
      const url = baseURL;
      const state = getState() as RootState;
      await dispatch({ type: "SHOW_LOADER" });
      let response = await axios.post(
        url + "/booking/create",
        jsonToFormdata(json)
      );
      // if (response.data.status != 1) throw "booking failed";
      const { balance, booking, docket, packages } = response.data.response;
      await dispatch(setLR(booking.docketNumber));
      showMessage("Booking Successfull", success, 1000);
      await dispatch(
        setOrangeLR(
          booking.isOrangeBooking ? booking.orangeDetails.lrNumber : null
        )
      );
      const lastDocket = {
        docketNumber: booking?.docketNumber,
        trackingNumber: booking?.trackingNumber,
        createdAt: booking?.createdAt,
        _id: booking?._id,
      };
      await dispatch(setLastBookingDetails(lastDocket));
      if (balance && !isNaN(balance.balance)) {
        try {
          const payload = {
            balance: balance.balance,
            options: {
              balance: balance,
            },
          };
          await dispatch(updateBalance(payload));
        } catch (err: any) {
          // showHttpError(err);
        }
      }
      try {
        printBuilty(docket, true, false, false, false, state.user.opBranch);
        if (state.user.opBranch.qrPrinter) {
          printQRDocket(docket, packages);
        }
      } catch (err: any) {
        showMessage("Builty print failed!", failed);
      }

      // dispatch(resetEdit());
      if (Array.isArray(callbacks) && callbacks.length > 0) {
        callbacks.forEach((cb) => {
          if (typeof cb == "function") {
            cb();
          }
        });
      }
      await dispatch({ type: "HIDE_LOADER" });
    } catch (err: any) {
      showHttpError(err);
      // showMessage(err || "something went wrong", failed, 5000);
      dispatch({ type: "HIDE_LOADER" });
      throw err;
    }
  }
);

export const book = (json: any, callbacks?: Function[]) => {
  return (dispatch: any) => {
    const payload = {
      json,
      callbacks,
    };
    console.log(payload, "payload111111111111");
    dispatch(bookApi(payload));
  };
};

export const setDocketApi = createAsyncThunk(
  "booking/setDocket", // action type string
  async (
    { args, func, payload }: { payload?: any; func?: any; args?: any },
    { dispatch, getState }
  ) => {
    let deliveryFlag = /delivery/.test(args);
    console.log({ args, func, payload }, "payload111111111");
    try {
      let { packages, isDirectUrl } = payload;
      const state = getState() as RootState;
      if (packages.length < 1) {
        // showErrorMessage("this docket has no packages.");
        showMessage("this docket has no packages.", failed);
        return;
      }
      if ((!state.user as any)?.allCities) {
        await dispatch(setCities());
      }
      let { originBranch, destCity } = packages[0];
      if (
        typeof originBranch == "object" &&
        originBranch.hasOwnProperty("_id")
      ) {
        originBranch = originBranch._id;
      }
      if (typeof destCity == "object" && destCity.hasOwnProperty("_id")) {
        destCity = destCity._id;
      }
      let across = await getAcrossService(
        originBranch,
        payload.docket.isFedexBooking
          ? payload.docket.destBranch.address.pincode
          : destCity,
        isDirectUrl
      );
      await dispatch(setAcross(across));
      const { allcities } = state.user;
      console.log({ payload, deliveryFlag, allcities });
      await dispatch(
        setDocketData({
          ...payload,
          // routes,
          delivery: deliveryFlag,
          allcities,
        })
      );
      if (typeof func === "function") {
        func(args);
      }
    } catch (err: any) {
      return err;
    }
  }
);

export const setDocket = (payload?: any, func?: any, args?: any) => {
  return (dispatch: any) => {
    const payloadData = {
      payload,
      func: func ? [func] : undefined,
      args,
    };
    console.log(payloadData, "payloadData");
    dispatch(setDocketApi(payloadData));
  };
};

export const reset = createAsyncThunk(
  "booking/reset", // action type string
  async (data: { exclude: { docketNumber: number } }, { dispatch }) => {
    dispatch(resetEdit(data));
  }
);

const optimizedReset = debounce(reset, 500);

export const bookChangeDocket = createAsyncThunk(
  "booking/bookChangeDocket", // action type string
  async (val: string, { dispatch }) => {
    try {
      await dispatch(bookDocket(val));
      optimizedReset({ exclude: { docketNumber: 1 } }, dispatch);
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const resetPackagesAmount = createAsyncThunk(
  "booking/resetPackagesAmount", // action type string
  async (_, { dispatch, getState }) => {
    const { packages } = (getState() as RootState).booking;
    packages.map((pkg: any) => {
      dispatch(
        bookingEditPackage({
          id: pkg.id,
          name: "amount",
          val: "",
        })
      );
    });
  }
);

export const getLastDocketDetails = createAsyncThunk(
  "booking/getLastDocketDetails", // action type string
  async (_, { dispatch, getState }) => {
    dispatch(showLoader());
    try {
      const state = getState() as RootState;
      const { user } = state;
      const data = await getLastBookedDocket({
        entity: user && user.opBranch && user.opBranch._id,
      });
      dispatch(setLastBookingDetails(data));
    } catch (err) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);
