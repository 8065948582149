import { baseURL } from "../axios";

const url = baseURL;
// const url = 'http://192.168.1.142:3000/api'

export { url };
export * from "./auth";

//overall entities : material packing

export const showLoader = () => ({ type: "SHOW_LOADER" });

export const hideLoader = () => ({ type: "HIDE_LOADER" });
