
import * as Yup from "yup";
import { BookingError } from "../../../validators/err";

export const check = () => {};

export const toRSOption = (value: string) =>
  value ? { label: value, value: value } : undefined;

export function debounce(func: any, wait: number, immediate?: boolean): any {
  let timeout: any;
  return function (this: any) {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const toFirstPage = () => {
  const step3 = document.getElementById("step3");
  const step1 = document.getElementById("step1");
  if (step3) {
    step3.style.display = "none";
  }
  if (step1) {
    step1.style.display = "block";
  }
};

const getOrder: any = {
  "0": "1st",
  "1": "2nd",
  "2": "3rd",
  "3": "4th",
};

export const formatMessage = (err: Error) => {
  const testRegex = /(\S*)\[(\d*)\](\S*)/;
  let result: any = err.message.match(testRegex);
  if (!result) return err.message;
  else
    return (
      "Invalid value at " +
      result[3].substring(1) +
      " in " +
      getOrder[result[2]] +
      " row of " +
      result[1]
    );
};

export const bookingSchema : any = {
  bookdestcity: Yup.string().required("Destination City is required"),
  bookdestbranch: Yup.string().required("Destination Branch is required"),
  // bookroute: Yup.string().required("Destination Route is required"),
  bookService: Yup.string().required("Service is required"),
  purpose: Yup.string().required("Purpose is required"),
  sender: Yup.object({
    name: Yup.object({ value: Yup.string(), label: Yup.string() })
      .nullable()
      .required("Sender name is required"),
    contact: Yup.string()
      .required("Sender contact is required")
      .matches(/\d{10}/, "Invalid contact number"),
    gst: Yup.object({
      label: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
      value: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
    }),
  }),
  receiver: Yup.object({
    name: Yup.object({ value: Yup.string(), label: Yup.string() })
      .nullable()
      .required("Receiver name is required"),
    contact: Yup.string()
      .required("Receiver contact is required")
      .matches(/\d{10}/, "Invalid contact number"),
    gst: Yup.object({
      label: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
      value: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
    }),
  }),
}

export const finalBookingSchema = Yup.object({
  fromBranch: Yup.string()
    .required()
    .matches(/\w{24}/, "Enter a valid destination branch"),
  packages: Yup.array().of(
    Yup.object({
      amount: Yup.number().min(0),
      dimension: Yup.object({
        l: Yup.number()
          .required()
          .min(0),
        b: Yup.number()
          .required()
          .min(0),
        h: Yup.number()
          .required()
          .min(0),
      }),
      frag: Yup.boolean().required(),
      stack: Yup.boolean().required(),
      haz: Yup.boolean().required(),
      rate: Yup.object({
        value: Yup.string()
          .required()
          .oneOf(["Direct", "Per package"]), //, 'Per Kg']),
        label: Yup.string(),
      }),
      materialType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      packingType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      qty: Yup.number()
        .required()
        .min(1),
      unit: Yup.object({
        label: Yup.string().required(),
        value: Yup.string(),
      }),
      weight: Yup.number().required(),
    })
  ),
  risk: Yup.number().oneOf(
    [0, 1, 2],
    "Please select a valid insurance service"
  ),
});

export const biltyGenerationSchema = Yup.object({
  packages: Yup.array().of(
    Yup.object({
      amount: Yup.number().min(0),
      dimension: Yup.object({
        l: Yup.number()
          .typeError("Length should be a number")
          .required("Length is required")
          .min(0),
        b: Yup.number()
          .typeError("Breadth should be a number")
          .required("Breadth is required")
          .min(0),
        h: Yup.number()
          .typeError("length should be a number")
          .required("Height is required")
          .min(0),
      }),
      frag: Yup.boolean().required(),
      stack: Yup.boolean().required(),
      haz: Yup.boolean().required(),
      rate: Yup.object({
        value: Yup.string()
          .required()
          .oneOf(["Direct", "Per package"]), // 'Per Kg']),
        label: Yup.string(),
      }),
      materialType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      packingType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      qty: Yup.number()
        .typeError("Quantity should be number")
        .required()
        .min(1, "Quantity cannot be 0"),
      unit: Yup.object({
        label: Yup.string().required(),
        value: Yup.string(),
      }),
      weight: Yup.number()
        .typeError("Weight should be number")
        .required("Weight is required"),
    })
  ),
  // bf: Yup.number('Bilty cost should be number').required('Bilty cost is invalid'),
  // hf: Yup.number('Handling should be number').required('Handling cost is invalid'),
  sender: Yup.object({
    name: Yup.string()
      .required("Sender name is required")
      .min(5, "Sender name is too short"),
    contact: Yup.string()
      .required("Sender contact is required")
      .matches(/\d{10}/),
    l1: Yup.string().required("sender address l1 required"),
    l2: Yup.string().required("sender address l2 required"),
    pin: Yup.string().required("sender address pincode required"),
    // city: Yup.string().required("sender address city required."),
  }),
  receiver: Yup.object({
    name: Yup.string()
      .required("Receiver name is required")
      .min(5, "Receiver name is too short"),
    contact: Yup.string()
      .required("Receiver contact is required")
      .matches(/\d{10}/),
    l1: Yup.string().required("receiver address l1 required"),
    l2: Yup.string().required("receiver address l2 required"),
    pin: Yup.string().required("receiver address pincode required"),
    city: Yup.string().required("receiver address city required."),
  }),
  paymentMode: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable(),
    })
    .typeError("Invalid payment mode!"),
});

export const packagesSchema = Yup.object({
  packages: 
    Yup.object({
      amount: Yup.number().min(0),
      dimension: Yup.object({
        l: Yup.number()
          .typeError("Length should be a number")
          .required("Length is required")
          .min(0),
        b: Yup.number()
          .typeError("Breadth should be a number")
          .required("Breadth is required")
          .min(0),
        h: Yup.number()
          .typeError("length should be a number")
          .required("Height is required")
          .min(0),
      }),
      // frag: Yup.boolean().required(),
      // stack: Yup.boolean().required(),
      // haz: Yup.boolean().required(),
      // rate:Yup.string()
      //     .required()
      //     .oneOf(["Direct", "Per package"]), // 'Per Kg']),
       
      // materialType: Yup.object({
      //   label: Yup.string(),
      //   value: Yup.string()
      //     .required()
      //     .min(3),
      // }),
      // packingType: Yup.object({
      //   label: Yup.string(),
      //   value: Yup.string()
      //     .required()
      //     .min(3),
      // }),
      // qty: Yup.number()
      //   .typeError("Quantity should be number")
      //   .required()
      //   .min(1, "Quantity cannot be 0"),
      unit: Yup.string().required("unit is required"),
      size: Yup.string().required("size is required"),
      weight: Yup.number()
      .typeError("weight should be a number")
      .required("weight is required")
      .min(1),
      }),
      // id:Yup.number(),
     
    })


export const biltyCss = () => {
  return `
#step3{
    page-break-after: always
  }
.hide_print{
    color: transparent;
    }
    html,body{
    position: relative; 
    width: 100%;
    }
    #root {
    margin: 0.2cm 0 0 0;
    border: 1px solid black;
    width: 20.5cm;
    height: 11.5cm;
    }
    .part0 {
    height: 2.3cm;
    }
    .flex {
    display: flex;
    }
    /* .part1{
    width: 20.5cm
    } */
    .flex-part2 {
    display: flex;
    width: 20.5cm;
    flex: 1.9cm 8.4cm 1.9cm 8.4cm;
    }
    .flex-part2>div {
    /* flex:1.9cm 8.4cm; */
    }
    /* .flex>div {
    width: 5.4cm;
    height: 1.2cm;
    } */
    .part1>.flex>div>p {
    margin-left: 0.1cm;
    margin-top: 0.2cm;
    width: 1.2cm;
    }
    .flex{
    display: flex;
    }
    .flex_part1_sub{
        display:flex;
        width: 4.1cm;
    }
    .part1-label{
    width: 1cm;
    }
    .part1-value {
    width: 4.8cm;
    }
    .flex-column {
    display: flex;
    flex-direction: column;
    }
    .part2 {
    height: 2.4cm;
    }
    /* div.part2 > div.flex-column > div{
    height: 0.6cm;
    } */
    .part2_label {
    width: 2cm;
    }
    .part2_value {
    width: 8.7cm;
    }
    .goods_value{
        width: 3.5cm;
    }
    .invoice_label{
        width: 1.2cm;
    }
    .invoice_value{
        width: 3.5cm;
    }
    .part2_sub_value{
    width: 4.3cm;
    }
    .charge{
        text-align: left;
    }
    .part3_label {
    width: 2cm;
    margin: 0;
    height: 0.7cm;
    }
    .part3_value {
    width: 10.8cm;
    margin: 0;
    height: 0.6cm;
    }
    .part3_label_blank {
    width: 2cm;
    // margin-top: 0cm;
    // margin-left: 0cm;
    height: 1.2cm;
    margin-top: -0.6cm;
    }
    
    .part3_value_blank {
    width: 10.8cm;
    // margin: 0;
    height: 1.2cm;
    margin-top: -0.6cm;
    }
    
    .part4_value_blank {
    width: 12.8cm;
    margin-top: 0cm;
    margin-left: 0cm;
    height: 1.8cm;
    }
    
    .part1_sub_label {
    width:1.6cm
    }
    .part1_sub_value{
    width: 2.9cm;
    }
    
    .part2 p {
    margin: 0
    }
    
    #part2 {
    display: flex;
    // margin-left: 0.1cm;
    // margin-top: 0.1cm;
    height: 0.6cm;
    }
    
    .flex_part3 {
    display: flex;
    margin-left: 0.1cm;
    }
    
    .gap_part3 {
    margin-top: 0.1cm
    }
    
    #part2>p {
    height: 0.6cm;
    margin: 0
    }
    
    div.part3_label>p {
    margin: 0;
    }
    #asd{
    width:20.5cm;
    }
    .padding_packages {
        padding-top: 0.2cm
    }
    .part2 {
        margin-top: 3px;
    }
    .part1 {
        margin-top: 1px 
    }
`;
};

export default async (data: any) => {
  try {
    await finalBookingSchema.validate(data);
    return null;
  } catch (err : any) {
    return new BookingError(err.message, "test");
  }
};