import { createSlice } from "@reduxjs/toolkit";
import { newRouteCreateRouteAction, newRouteSetRouteAction, newRouteUpdateRouteAction } from "./api";
import { newRouteValidation } from "./helper";

const initialState: any = {
    isEditing: false,
    routeName: "",
    distance: "",
    active: true,
    departureTime: {
        h: 0,
        m: 0,
    },
    arrivalTime: {},
    originBranch: {},
    destBranch: {},
    updatedAt: "",
    createdAt: "",
    _id: "",
    updatedBy: {
        _id: ""
    },
    company: {},
    modeOfTransport: {},
    serviceSubType: null,
    stops: [
        {
            id: null,
            company: "",
            city: "",
            branch: "",
            arrivalTime: {
                h: 0,
                m: 0,
            },
            noOfDays: 0,
            departureTime: {
                h: 0,
                m: 0,
            },
            loading: false,
            unloading: false,
        },
    ],
    errors: {
        routeType: null,
        serviceType: null,
        subServiceType: null,
        name: null,
        modeOfTransfer: null,
        TransportSubType: null,
        modeOfPayment: null,
        working: {
            sun: null,
            mon: null,
            tue: null,
            wed: null,
            thu: null,
            fri: null,
            sat: null,
        },
        constraints: {
            perPackageVolume: {
                l: null,
                b: null,
                h: null,
            },
            perPackageWeight: null,
        },
        capacity: {
            weight: null,
            volume: null,
        },
        insurance: null,
        collections: null,
        fragile: null,
        unstackable: null,
        hazardous: null,
        denominator: null,
        minChargeWeight: null,

        destination: [
            {
                id: null,
                company: null,
                city: null,
                branch: null,
                cutOffTime: {
                    h: null,
                    m: null,
                },
                noOfDays: null,
                deliveryTime: {
                    h: null,
                    m: null,
                },
                loading: null,
                unloading: null,
            },
        ],
    },
};

const newRouteSlice = createSlice({
    name: "newRoute",
    initialState,
    reducers: {
        newRouteChangeSimple: (state, action) => {
            const [what, value]: any = action.payload;
            try {
                if (newRouteValidation?.[what?.join?.(".")]) {
                    newRouteValidation[what.join(".")].validateSync(value);
                }

                if (what === "name") {
                    Object.assign(state, { [what]: value, isEditing: false });
                } else {
                    Object.assign(state, {
                        [what]: value,
                        errors: Object.assign({}, state.errors, { [what]: null }),
                    });
                }
            } catch (err: any) {
                Object.assign(state, {
                    [what]: value,
                    errors: Object.assign({}, state.errors, { [what]: err.errors?.[0] || "Invalid input" }),
                });
            }
        },
        newRouteChangeDestination: (state, action) => {
            const [index, what, value] = action.payload;
            const keys = what.split("."); // Split the nested keys

            Object.assign(state, {
                stops: state.stops.map((stop: any, i: any) => {
                    if (i !== index) return stop; // Keep other stops unchanged

                    // Handling first-level and nested keys dynamically
                    let updatedStop = { ...stop };

                    if (keys.length === 2) {
                        const [parentKey, childKey] = keys;
                        updatedStop = {
                            ...stop,
                            [parentKey]: {
                                ...stop[parentKey], // Preserve existing nested object
                                [childKey]: value,
                            },
                        };
                    } else {
                        updatedStop = { ...stop, [what]: value };
                    }

                    return updatedStop;
                }),
            });
        },
        newRouteAddDestination: (state) => {
            Object.assign(state, {
                stops: [
                    ...state.stops,
                    {
                        id: null,
                        company: "",
                        city: "",
                        branch: "",
                        arrivalTime: { h: 0, m: 0 },
                        noOfDays: 0,
                        departureTime: { h: 0, m: 0 },
                        loading: false,
                        unloading: false,
                    },
                ],
            });
        },
        newRouteRemoveDestination: (state, action) => {
            Object.assign(state, {
                stops: state.stops.filter((_: any, i: any) => i !== action.payload.index),
            });
        },
        newRouteClearRoute: (state) => {
            Object.assign(state, initialState);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(newRouteSetRouteAction.fulfilled, (state, action) => {
                Object.assign(state, action.payload, { isEditing: true });
            })
            .addCase(newRouteCreateRouteAction.fulfilled, (state) => {
                Object.assign(state, initialState);
            })
            .addCase(newRouteUpdateRouteAction.fulfilled, (state, action) => {
                Object.assign(state, action.payload);
            })
    },
});

export const { newRouteChangeSimple, newRouteChangeDestination, newRouteAddDestination, newRouteRemoveDestination, newRouteClearRoute } = newRouteSlice.actions;

export default newRouteSlice.reducer;
